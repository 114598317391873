import React, { Component } from "react";
import uiInfo from "../../ui-info";
import PaginaToolbar from "../../components/PaginaToolbar";
import api from "../../service/api";
import { criarPlanoContasDs } from "../../service/DataSources";
import {
  DataGrid,
  Editing,
  Column,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import UserInfo from "../../UserInfo";

export default class PlanoContasBox extends Component {
  constructor(props) {
    super(props);
    this._gridInstance = React.createRef();
    this.onAtualizandoRow = this.onAtualizandoRow.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.state = { datasource: [] };
    this.onSelecionado = props.onSelecionado;
    this.usadoComoPesquisa = props.onSelecionado !== undefined;
  }

  componentDidMount() {
    api
      .get(`/contas_escrituracao/cpbs/${UserInfo.getCpbsId()}`)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ datasource: response.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onAtualizandoRow(e) {
    e.cancel = true;
    this.props.history.push("plano-contas/editar/" + e.data.id);
  }

  onDeleteRow(id) {
    try {
      api.delete(`/contas_escrituracao/${id}`);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          <h2>Plano de Contas</h2>
          <PaginaToolbar titulo="" novoCb={this.novoRegistro.bind(this)}>
            <DataGrid
              ref={(grid) => (this._gridInstance = grid)}
              dataSource={this.state.datasource}
              showBorders={true}
              rowAlternationEnabled={true}
              remoteOperations={true}
              columnAutoWidth={true}
              wordWrapEnabled={window.innerWidth < 800}
              filterSyncEnabled={true}
              onEditingStart={this.onAtualizandoRow}
              onRowRemoved={({ data }) => this.onDeleteRow(data.id)}
            >
              <Editing
                allowDeleting={this.usadoComoPesquisa ? false : true}
                allowUpdating={this.usadoComoPesquisa ? false : true}
                useIcons={true}
              />
              <Column
                type="buttons"
                buttons={[
                  "edit",
                  "delete",
                  {
                    icon: "repeat",
                    visible: this.usadoComoPesquisa ? true : false, 
                    onClick: this.onSelecionado,
                  },
                ]}
              />
              <Column
                dataField={"id"}
                allowEditing={false}
                caption={"#"}
                alignment="center"
                width={80}
              />
              <Column dataField={"nome"} caption={"Nome"} />
              <Column dataField={"codigo"} caption={"Código"} />
              <Column dataField={"contaSuperior"} caption={"Conta Pai"} />
              <Column dataField={"codigoCosif"} caption={"Conta COSIF"} />
              <Column dataField={"servico.lcMunicipal"} caption={"Serviço"} />
              <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
              <Pager
                showInfo={true}
                showNavigationButtons={true}
                showPageSizeSelector={true}
                allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
              />
            </DataGrid>
          </PaginaToolbar>
        </div>
      </React.Fragment>
    );
  }

  novoRegistro() {
    this.props.history.push("plano-contas/nova-conta");
  }
}
