import React, { useState, useEffect } from "react";
import "./cadastrar.scss";

import api from "../../service/api";
import apiSecundaria from "../../service/apiSecundaria";

import Form, {
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
} from "devextreme-react/form";
import FileUploader from "devextreme-react/file-uploader";
import Button from "devextreme-react/button";
import notify from "devextreme/ui/notify";

////////Opções SelectBox's///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const tipo = [
  { id: 1, nome: "Prestador de Serviços" },
  { id: 2, nome: "Tomador de Serviços" },
  { id: 3, nome: "Prestador e Tomador de serviços" },
];
const tipoOptions = {
  items: tipo,
  valueExpr: "id",
  displayExpr: "nome",
  placeholder: "",
};

const origem = [
  { id: 1, nome: "Do Município" },
  { id: 2, nome: "Outro Município" },
];
const origemOptions = {
  items: origem,
  valueExpr: "id",
  displayExpr: "nome",
  placeholder: "",
};

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default () => {
  //const [cad, setCad] = useState({});
  const [info, setInfo] = useState({});
  const [anexo, setAnexo] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const loadMunicipios = async () => {
    const response = await api.get("/municipios");
    setMunicipios(response.data.data);
    /* setCidades(response.data.map((item) => item.nome)); */
  };

  const opcoesMunicipioTomador = {
    dataSource: municipios,
    searchEnabled: true,
    placeholder: " ",
  };

  const onSelectedFilesChanged = (e) => {
    setSelectedFiles([...selectedFiles, ...e.value]);
  };

  useEffect(() => {
    loadMunicipios();
  }, []);

  const cadastrar = async () => {
    const formData = new FormData();
    
    const data = {
     cpfCnpj: info.cpfCnpj,
     razaoSocial: info.razaoSocial,
     tipo: info.tipo,
     origem: info.origem,
     inscricaoMunicipal: info.inscricaoMunicipal,
     inscricaoEstadual: info.inscricaoEstadual,
     endereco: {
       logradouro: info.logradouro,
       numero: info.numero,
       complemento: info.complemento,
       bairro: info.bairro,
       municipio: info.municipio,
       cep: info.cep
     },
     contato: {
       telefone: info.telefone,
       celular: info.celular,
       email: info.email
     },
     representanteLegal: {
       nome: info.nome,
       cpf: info.cpf,
       logradouro: info.logradouroResp,
       numero: info.numeroResp,
       complemento: info.complementoResp,
       bairro: info.bairroResp,
       municipio: info.municipioResp,
       cep: info.cepResp
     }
    }
    formData.append("values", JSON.stringify(data));
    formData.append("prefeituraId", 1);
    formData.append("arquivos", selectedFiles);

    const response = await apiSecundaria.post(
      "/receberarquivos/teste",
      formData
    );
    if (response) {
      notify("Cadastro realizado com sucesso!", "success", 4000);
    } else notify("Erro ao cadastrar!", "error", 4000);
  };

  const removeArquivo = (i) => {
    
    const array = [...selectedFiles];
    
    array.splice(i, 1);
    
    setSelectedFiles(array);
  };

  const cancelar = () => {
    setInfo({});
    setAnexo([]);
  };

  return (
    <div className="cadastrar-container">
      <div className={"content-block"}>
        <h2>Cadastrar</h2>
      </div>

      <div className="dx-card card-cad">
        <div className="form-cad">
          <Form
            id="form-cad"
            formData={info}
            colCount={10}
            labelLocation={"top"}
          >
            <GroupItem colCount={10} colSpan={10}>
              <SimpleItem dataField="cpfCnpj" colSpan={3}>
                <RequiredRule message="Documento é obrigatório" />
                <Label text="CPF/CNPJ" />
              </SimpleItem>

              <SimpleItem dataField="razaoSocial" colSpan={5}>
                <Label text="Razão Social" />
              </SimpleItem>

              <SimpleItem
                dataField="tipo"
                editorType="dxSelectBox"
                editorOptions={tipoOptions}
                colSpan={2}
              >
                <Label text="Tipo" />
              </SimpleItem>

              <SimpleItem
                dataField="origem"
                editorType="dxSelectBox"
                editorOptions={origemOptions}
                colSpan={3}
              >
                <Label text="Origem" />
              </SimpleItem>

              <SimpleItem dataField="inscricaoMunicipal" colSpan={2}>
                <Label text="Inscrição Municipal" />
              </SimpleItem>

              <SimpleItem dataField="inscricaoEstadual" colSpan={2}>
                <Label text="Inscrição Estadual" />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={10} colSpan={10} caption="Endereço">
              <SimpleItem colSpan={6} dataField="logradouro">
                <Label text="Logradouro" />
              </SimpleItem>

              <SimpleItem dataField="numero" colSpan={1}>
                <Label text="Número" />
              </SimpleItem>

              <SimpleItem dataField="complemento" colSpan={3}>
                <Label text="Complemento" />
              </SimpleItem>

              <SimpleItem dataField="bairro" colSpan={3}>
                <Label text="Bairro" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="municipio"
                editorType={"dxLookup"}
                editorOptions={opcoesMunicipioTomador}
              >
                <Label text="Município" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="cep"
                editorOptions={{ mask: "00000-000" }}
              >
                <Label text="CEP" />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={10} colSpan={10} caption="Contato">
              <SimpleItem
                colSpan={3}
                dataField="telefone"
                editorOptions={{ mask: "+55 (00) 0000-0000" }}
              >
                <Label text="Telefone" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="celular"
                editorOptions={{ mask: "+55 (00) 0.0000-0000" }}
              >
                <RequiredRule message="Celular é obrigatório" />
                <Label text="Celular" />
              </SimpleItem>

              <SimpleItem colSpan={4} dataField="email">
                <RequiredRule message="Email é obrigatório" />
                <Label text="Email" />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={10} colSpan={10} caption="Responsável Legal">
              <SimpleItem dataField="nome" colSpan={6}>
                <Label text="Nome" />
              </SimpleItem>

              <SimpleItem
                dataField="cpf"
                colSpan={4}
                editorOptions={{ mask: "000.000.000-00" }}
              >
                <Label text="CPF" />
              </SimpleItem>

              <SimpleItem colSpan={6} dataField="logradouroResp">
                <Label text="Logradouro" />
              </SimpleItem>

              <SimpleItem dataField="numeroResp" colSpan={1}>
                <Label text="Número" />
              </SimpleItem>

              <SimpleItem dataField="complementoResp" colSpan={3}>
                <Label text="Complemento" />
              </SimpleItem>

              <SimpleItem dataField="bairroResp" colSpan={3}>
                <Label text="Bairro" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="municipioResp"
                editorType={"dxLookup"}
                editorOptions={opcoesMunicipioTomador}
              >
                <Label text="Município" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="cepResp"
                editorOptions={{ mask: "00000-000" }}
              >
                <Label text="CEP" />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={10} colSpan={10} caption="Anexo"></GroupItem>
          </Form>

          <div>
            <div className="widget-container">
              <FileUploader
                multiple={true}
                /* uploadUrl="" */
                onValueChanged={onSelectedFilesChanged}
                formData={anexo}
                uploadMode={"useForm"}
                showFileList={false}
              />

              <div
                className="content"
                style={{ display: selectedFiles.length > 0 ? "block" : "none" }}
              >
                <div>
                  <h4>Arquivo(s) Selecionado(s)</h4>
                  {selectedFiles.map((file, i) => {
                    return (
                      <div className="selected-item" key={i}>
                        <span>
                          {`Nome: ${file.name}`}{" "}
                          <Button
                            icon="trash"
                            onClick={() => {
                              removeArquivo(i);
                            }}
                          />
                        </span>
                        <br />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dx-card card-buttons">
        <div>
          <Button
            className="cad-button"
            text="Cadastrar"
            type="success"
            stylingMode="contained"
            onClick={cadastrar}
          />

          <Button
            className="cancel-button"
            text="Cancelar"
            type="danger"
            stylingMode="contained"
            onClick={cancelar}
          />
        </div>
      </div>
    </div>
  );
};
