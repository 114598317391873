import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./confirmar-sem-movimento.scss";
import api from "../../service/api";

import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";

export default () => {
  const [resumo, setResumo] = useState({});

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    setResumo(location.state);
  }, []);


  const confirmar = async () => {
    let response = await api.post("/dms/declarar/sem_movimento/1/ano/mes");

    if (response.data)
      notify("Competência encerrada com sucesso!", "success", 3000);
    else notify("Não foi possível encerrar competência!", "error", 3000);

    history.push("/encerrar-sem-movimento");
  };

  const cancelar = () => {
    history.push("/encerrar-sem-movimento");
  };

  return (
    <div className="confirmacao-container">
      <div>
        <h2>Confirmação de Encerramento</h2>
        
      </div>

      <div>
        <div className="dx-card">
        <h6 className="dx-theme-material-typography sm-mes-ano">
          Competência {resumo.mes}/{resumo.ano}
        </h6>
          <h6 className="dx-theme-material-typography msg">
            A CONFIRMAÇÃO DO ENCERRAMENTO DA ESCRITURAÇÃO IMPLICA, PARA EFEITO
            LEGAL, NA DECLARAÇÃO DE PRESTAÇÃO DE SERVIÇOS JUNTO À FAZENDA
            MUNICIPAL.
          </h6>
          <h5 className="dx-theme-material-typography sm-confirmar">Confirmar encerramento sem movimentação?</h5>
        <div className="buttons-smovimento">
          <Button
            className="confirmar-button"
            text="Confirmar"
            type="success"
            onClick={confirmar}
          />
          <Button 
            className="cancelar-button" 
            text="Cancelar"
            type="danger"
            onClick={cancelar} />
        </div>
        </div>
       
      </div>
    </div>
  );
};
