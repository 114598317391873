import React from 'react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';

function criarSysCodigoDescricoa(fnObterCodigo, fnSetCodigo, fnObterLegenda, fnPesquisa) {


    return function fnRender() {

        return (
            <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <div style={{ width: "18%", minWidth: "130px", maxWidth: "150px" }}>
                    <TextBox value={fnObterCodigo()} onValueChanged={fnSetCodigo}>
                        <TextBoxButton name="btnPesquisa"
                            location="after"
                            options={{ focusStateEnabled: false, icon: 'search', onClick: function () { fnPesquisa(); } }} />
                    </TextBox>
                </div>
                <div style={{ flexGrow: "1" }}>
                    <TextBox value={fnObterLegenda()} readOnly={true} stylingMode={"filled"} focusStateEnabled={false}/>
                </div>
            </div >
        );
    }
}

export default criarSysCodigoDescricoa;

