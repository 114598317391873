import React, { useState, useEffect } from "react";
import "./recuperar-senha.scss";
import { useHistory, useParams } from "react-router-dom";
import api from "../../service/api";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";

export default () => {
  const [recoverPasswordForm] = useState({});
  /* const [cpfCnpj, setCpfCnpj] = useState({});
    const [senha, setSenha] = useState({});
    const [codigoAcesso, setCodigoAcesso] = useState([]); */
  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    async function verificaPrefeitura() {
      const response = await api("/prefeituras");

      let codigoPortal = response.data.data.find(
        (prefeitura) => params.prefeitura === prefeitura.codigoPortal
      );

      if (codigoPortal === undefined) {
        history.push("/");
      }
    }
    verificaPrefeitura();
  }, []);

  const onRecoverPasswordClick = (e) => {
    e.preventDefault();
    alert(recoverPasswordForm.documento);
  };

  const buttonOptions = {
    text: "Enviar",
    type: "success",
    useSubmitBehavior: true,
  };

  return (
    <div className="recuperar-senha-main-div">
      <div className="recuperar-senha-card">
        <h2 style={{marginTop: "-20px"}}>Recuperação senha</h2>
        <p>Por favor entre com seu CPF ou CNPJ que iremos mandar um email para recuperar sua senha!</p>
        <form onSubmit={onRecoverPasswordClick}>
          <Form
            id="form-cad"
            colCount={2}
            labelLocation={"top"}
            formData={recoverPasswordForm}
          >
            <GroupItem colCount={1} colSpan={2}>
              <SimpleItem dataField="documento" colSpan={2}>
                <RequiredRule message="Documento é obrigatório" />
                <Label text="CPF/CNPJ" />
              </SimpleItem>
            </GroupItem>

            <ButtonItem
              horizontalAlignment="center"
              colSpan={2}
              buttonOptions={buttonOptions}
            />
          </Form>
        </form>
        <br />
        <p onClick={() => history.goBack()} className="link-go-back">Voltar para o login</p>
      </div>
    </div>
  );
};
