import React, { useState, useEffect } from "react";
import "./login-form.scss";
import { Link } from "react-router-dom";
import UserInfo from "../../UserInfo";
import { login } from "../../auth";
import notify from "devextreme/ui/notify";
import { useHistory, useParams } from "react-router-dom";
import api from "../../service/api";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  RequiredRule,
  ButtonItem,
} from "devextreme-react/form";
import Logo from "../../images/logotrimap.jpg";

export default () => {

  const passwordOptions = {
    mode: "password",
  };

  const [loginForm, setLoginForm] = useState({});
  const [prefeitura, setPrefeitura] = useState({ nome: "", logo: "" });
  /* const [cpfCnpj, setCpfCnpj] = useState({});
    const [senha, setSenha] = useState({});
    const [codigoAcesso, setCodigoAcesso] = useState([]); */

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    async function verificaPrefeitura() {
      const response = await api("/prefeituras");

      let codigoPortal = response.data.data.find(
        (prefeitura) => params.prefeitura === prefeitura.codigoPortal
      );

      if (codigoPortal === undefined) {
        history.push("/");
      }

      setPrefeitura(codigoPortal);
    }
    verificaPrefeitura();
  }, []);

  const onLoginClick = (e) => {
    e.preventDefault();

    let dados = {
      login: loginForm.login,
      senha: loginForm.senha,
      sistema: "nfse"
    };

    if (loginForm.codigoAcesso !== "" && loginForm.codigoAcesso !== undefined) {
      dados.codigoAcesso = parseInt(loginForm.codigoAcesso);
    }

    api
      .post("login", dados)
      .then((response) => {
        UserInfo.setUsuario(response.data);
        // history.location.pathname === "/home"
        //   ? window.location.reload()
        //   : history.push(`/${params.prefeitura}/home`);
        window.location.reload();
      })
      .catch((error) => notify(error, "error"));

    /* UserInfo.setUsuario(dados);
          login("token");
           
          history.push("/home");
          window.location.reload(); */
  };

  const buttonOptions = {
    text: "Entrar",
    type: "success",
    useSubmitBehavior: true,
    //onClick: onLoginClick
  };

  return (
    <div className="login-main-div">
      <div className="prefeitura-name">{prefeitura.nome}</div>
      <br />
      <div>
        <img src={Logo} width={400} />
      </div>
      <br />
      <div className="login-card">
        <form onSubmit={onLoginClick}>
          <Form
            id="form-cad"
            colCount={2}
            labelLocation={"top"}
            formData={loginForm}
          >
            <GroupItem colCount={1} colSpan={2}>
              <SimpleItem dataField="login" colSpan={2}>
                <RequiredRule message="Documento é obrigatório" />
                <Label text="CPF/CNPJ" />
              </SimpleItem>

              <SimpleItem
                colSpan={2}
                dataField="senha"
                editorType="dxTextBox"
                editorOptions={passwordOptions}
              >
                <RequiredRule message="Senha é obrigatória" />
                <Label text="Senha" />
              </SimpleItem>

              <SimpleItem dataField="codigoAcesso" colSpan={2}>
                <Label text="Código de Acesso" />
              </SimpleItem>
            </GroupItem>

            <ButtonItem
              horizontalAlignment="center"
              colSpan={2}
              buttonOptions={buttonOptions}
            />
          </Form>
        </form>
        <br />
        <Link style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }} to="recuperar-senha">Recuperar senha!</Link>
      </div>
    </div>
  );
};
