import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./confirmar-com-movimento.scss";
import api from "../../service/api";

import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";

export default () => {
  const [resumo, setResumo] = useState({});

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const aux = { ...location.state };
    
      aux.resumo.totalServicosPrestados =
        "R$" + aux.resumo.totalServicosPrestados.toString().replace(".", ",");
      aux.resumo.totalGuiasAvulsas =
        "R$" + aux.resumo.totalGuiasAvulsas.toString().replace(".", ",");
      aux.resumo.totalGuiasNormais =
        "R$" + aux.resumo.totalGuiasNormais.toString().replace(".", ",");
      aux.resumo.totalImpostoServicosPrestados =
        "R$" +
        aux.resumo.totalImpostoServicosPrestados.toString().replace(".", ",");
      aux.resumo.totalImpostoGuiasAvulsas =
        "R$" + aux.resumo.totalImpostoGuiasAvulsas.toString().replace(".", ",");
      aux.resumo.totalImpostoGuiasNormais =
        "R$" + aux.resumo.totalImpostoGuiasNormais.toString().replace(".", ",");
    

    setResumo({ ...aux });
  }, []);

  // const grupoItens = {
  //     notasFiscais: [
  //         'Quantidade Normais', 'Quantidade Canceladas', 'Quantidade Substitutivas',
  //         'Quantidade Substituídas', 'Quantidade Total', 'Total Normais', 'Total Canceladas',
  //         'Total Substitutivas', 'Total Substituídas'
  //     ],
  //     atividades: [
  //         'Serviços Prestados (R$)', 'Guias Avulsas (R$)', 'Guias Normais (R$)',
  //         'Vr Imposto (R$)', 'Vr Imposto (R$)', 'Vr Imposto (R$)'
  //     ]
  // };

  const confirmar = async () => {
    let response = await api.post("/dms/declarar/com_movimento/1/ano/mes");

    if (response.data)
      notify("Competência encerrada com sucesso!", "success", 3000);
    else notify("Não foi possível encerrar competência!", "error", 3000);

    history.push("/encerrar-com-movimento");
  };

  const cancelar = () => {
    history.push("/encerrar-com-movimento");
  };

  return (
    <div className="confirmacao-container">
      <div>
        <h2>Confirmação de Encerramento</h2>
        <h6 className="dx-theme-material-typography cm-mes-ano">
          Competência {resumo.mes}/{resumo.ano}
        </h6>
      </div>
      <div>
        <div className="dx-card">
          <h6 className="dx-theme-material-typography notas">NOTAS FISCAIS XXXX</h6>
          <Form
            id="confirmacao-com-movimento-form"
            formData={resumo.resumo}
            colCount={4}
          >
            <GroupItem colCount={4} colSpan={4}>
              <SimpleItem dataField="qtdeNormais" disabled>
                <Label text="Quantidade Normais" />
              </SimpleItem>
              <SimpleItem dataField="qtdeCanceladas" disabled>
                <Label text="Quantidade Canceladas" />
              </SimpleItem>
              <SimpleItem dataField="qtdeSubstituidas" disabled>
                <Label text="Quantidade Substituidas" />
              </SimpleItem>
              <SimpleItem dataField="totalSubstituidas" disabled>
                <Label text="Total Substituidas" />
              </SimpleItem>
            </GroupItem>
          </Form>
        </div>

        <div className="dx-card">
          <h6 className="dx-theme-material-typography atividades">ATIVIDADES</h6>
          <Form
            id="confirmacao-com-movimento-form"
            formData={resumo.resumo}
            colCount={3}
          >
            <GroupItem colCount={3} colSpan={3}>
              <SimpleItem dataField="totalServicosPrestados" disabled>
                <Label text="Total Serviços Prestados (R$)" />
              </SimpleItem>
              <SimpleItem dataField="totalGuiasAvulsas" disabled>
                <Label text="Total Guias Avulsas (R$)" />
              </SimpleItem>
              <SimpleItem dataField="totalGuiasNormais" disabled>
                <Label text="Total Guias Normais (R$)" />
              </SimpleItem>
              <SimpleItem dataField="totalImpostoServicosPrestados" disabled>
                <Label text="Vr Impostos Serviços Prestados (R$)" />
              </SimpleItem>
              <SimpleItem dataField="totalImpostoGuiasAvulsas" disabled>
                <Label text="Vr Impostos Guias Avulsas (R$)" />
              </SimpleItem>
              <SimpleItem dataField="totalImpostoGuiasNormais" disabled>
                <Label text="Vr Imposto Guias Normais (R$)" />
              </SimpleItem>
            </GroupItem>
          </Form>
        </div>
        <div className="dx-card">
          <h6 className="dx-theme-material-typography msg">
            A CONFIRMAÇÃO DO ENCERRAMENTO DA ESCRITURAÇÃO IMPLICA, PARA EFEITO
            LEGAL, NA DECLARAÇÃO DE PRESTAÇÃO DE SERVIÇOS JUNTO À FAZENDA
            MUNICIPAL.
          </h6>
        </div>
        <h5 className="dx-theme-material-typography conf">Confirmar encerramento sem movimentação?</h5>
        <div className="buttons-cmovimento">
          <Button
            className="confirmar-button"
            text="Confirmar"
            type="success"
            onClick={confirmar}
          />
          <Button
            className="cancelar-button"
            text="Cancelar"
            type="danger"
            onClick={cancelar}
          />
        </div>
      </div>
    </div>
  );
};
