import React, { useState, useEffect, useRef } from "react";
import "./pesquisar-nota.scss";
import api from "../../service/api";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import { Button } from "devextreme-react";
import {
  DataGrid,
  Column,
  Button as DGButton,
  Lookup,
} from "devextreme-react/data-grid";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import UserInfo from "../../UserInfo";
import { anoOptions, mesOptions } from "../../utils/date-utils";
import { useHistory, useParams } from "react-router-dom";
import showReport, { getXmlReport } from "../../service/showReport";
import notify from "devextreme/ui/notify";
import uiInfo from "../../ui-info";
import { createStore } from "devextreme-aspnet-data-nojquery";
import ambiente from "../../ambiente";
import { getToken } from "../../auth";

const prefeitura = UserInfo.getPrefeitura();

const retida = [" ", "SIM", "NÃO"];
const retidaOptions = {
  items: retida,
  placeholder: "Selecione...",
};

const situacao = [
  { id: -1, descricao: " " },
  { id: 0, descricao: "NORMAL" },
  { id: 1, descricao: "CANCELADA" },
  { id: 2, descricao: "SUBSTITUÍDA" },
];

const situacaoOptions = {
  dataSource: situacao,
  displayExpr: "descricao",
  valueExpr: "id",
};

const status = ["ABERTA", "FECHADA", "CANCELADA"];
const statusOptions = {
  items: status,
  placeholder: " ",
};


function onBeforeSend(_, settings) {
  const token = getToken();
  settings.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  };
}

const criarGridDataSource = function (cpbsId) {
  return createStore({
    key: "id",
    loadUrl: ambiente.URL_API + `/nfse/buscar_notas/${cpbsId}`,
    onBeforeSend: (_, settings) => {
      const token = getToken();
      settings.headers = {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      };
    },
    errorHandler: function (error) {
      console.log(error);
    },
  });
};

export default () => {
  const routeParams = useParams();
  const [nfse, setNfse] = useState([]);
  const [filtro, setFiltro] = useState({ situacaoNota: -1, retida: " " });
  const [filtroCorrente, setFiltroCorrente] = useState([]);
  const [cancelingReason, setCancelingReason] = useState({
    showPopup: false,
    data: {},
  });
  const gridDataSource = criarGridDataSource(UserInfo.getCpbsId());
  const _gridInstance = useRef();
  const history = useHistory();

  const loadNfse = async () => {
    // var cpbsId = UserInfo.getCpbsId();
    // const response = await api.get(`/nfse/buscar_notas/${cpbsId}`);
    // setNfse(response.data.data);
  };


  useEffect(() => {
    _gridInstance.current._instance.filter(filtroCorrente.length > 0 ? filtroCorrente : null);
    _gridInstance.current._instance.refresh();
  }, [filtroCorrente]);

  const imprimirNfse = async (e) => {
    try {
      await showReport(
        `/report/nfse/${routeParams.prefeitura}/${e.row.data.id}`
      );
    } catch (error) {
      notify(error, "error");
    }
  };

  const cancelTaxInvoice = async () => {
    try {
      const response = await api.post("/nfse/cancelar_nfse", {
        nfseId: cancelingReason.invoiceId,
        motivo: cancelingReason.data.motivoCancelamento,
      });

      if (response.data.sucesso) {
        loadNfse();
        setCancelingReason({ ...cancelingReason, data: {}, showPopup: false });
      } else {
        notify(response.data.msg, "error");
      }
    } catch (error) {
      notify(error, "error");
    }
  };

  const printXmlFile = async (invoiceData) => {
    console.log(routeParams.prefeitura);
    console.log(invoiceData);
    try {
      await getXmlReport(`/nfse/xml/${invoiceData.row.data.id}`);
    } catch (error) {
      notify(error, "error");
    }
  };

  const copyOrReplace = (type, invoiceData) => {
    if (type === "SUBSTITUIR" && invoiceData.row.data.stEscriturada) {
      notify(
        `Nota ${invoiceData.row.data.numero} já escriturada, substituição não permitida.`
      );
    } else {
      history.push(`/${routeParams.prefeitura}/emitir-nota`, {
        comandoPesquisa: { operacao: type, numeroNf: invoiceData.row.data.numero },
      });
    }
  };

  const sendEmail = async (invoiceId) => {
    const email = "Email teste";
    try {
      const response = await api.post("/nfse/enviar_por_email", {
        nfseId: invoiceId,
        email: email,
      });
      console.log(response);
    } catch (error) {
      notify(error, "error");
    }
  };

  const filtrar = async () => {
    let filtroDataGrid = [];

    if (
      filtro.dataInicial !== null &&
      filtro.dataInicial !== undefined
    ) {

      filtroDataGrid.push(["dataEmissao", ">=", filtro.dataInicial]);
    }

    if (
      filtro.dataInicial !== null &&
      filtro.dataFinal !== null &&
      filtro.dataInicial !== undefined &&
      filtro.dataFinal !== undefined
    ) {
      filtro.dataFinal.setUTCHours(23);
      filtro.dataFinal.setUTCMinutes(59);
      filtro.dataFinal.setUTCSeconds(59);
      filtroDataGrid.push(["dataEmissao", "<=", filtro.dataFinal]);
    }

    if (
      filtro.retida !== undefined &&
      filtro.retida !== null &&
      filtro.retida !== "" &&
      filtro.retida !== " "
    ) {
      filtroDataGrid.push(["issRetido", "=", filtro.retida === "SIM"]);
    }
    if (filtro.numero !== undefined && filtro.numero !== null) {
      filtroDataGrid.push(["numero", "contains", filtro.numero.trim()]);
    }
    if (filtro.numeroRps !== undefined && filtro.numeroRps !== null) {
      filtroDataGrid.push(["numeroRps", "contains", filtro.numeroRps.trim()]);
    }
    if (filtro.statusNota !== undefined && filtro.statusNota !== null) {
      filtroDataGrid.push(["statusNota", "contains", filtro.statusNota.trim()]);
    }
    if (filtro.nome !== undefined && filtro.nome !== null) {
      filtroDataGrid.push(["tomadorNome", "contains", filtro.nome.trim()]);
    }
    if (filtro.cpfCnpj !== undefined && filtro.cpfCnpj !== null) {
      filtroDataGrid.push(["tomadorCpfCnpj", "contains", filtro.cpfCnpj.trim()]);
    }
    if (
      filtro.situacaoTomador !== undefined &&
      filtro.situacaoTomador !== null
    ) {
      filtroDataGrid.push([
        "situacaoTomador",
        "contains",
        filtro.situacaoTomador.trim(),
      ]);
    }
    if (
      filtro.situacaoNota !== undefined &&
      filtro.situacaoNota !== null &&
      filtro.situacaoNota !== -1
    ) {
      filtroDataGrid.push([
        "situacao",
        "=",
        filtro.situacaoNota,
      ]);
    }
    if (filtro.mesCompetencia !== undefined && filtro.mesCompetencia !== null) {
      filtroDataGrid.push([
        "mesCompetencia",
        "=",
        filtro.mesCompetencia,
      ]);
    }
    if (filtro.anoCompetencia !== undefined && filtro.anoCompetencia !== null) {
      filtroDataGrid.push([
        "anoCompetencia",
        "=",
        filtro.anoCompetencia,
      ]);
    }
    setFiltroCorrente(filtroDataGrid);



  };




  const limparFiltro = () => {
    filtrar();
    setFiltro({ situacaoNota: -1, retida: " " });
  };

  const verTodasNotas = () => {
    setFiltroCorrente([]);
  };

  return (
    <div className="pesquisar-nota-container">
      <div className={"content-block"}>
        <h2>Pesquisar NFSe</h2>
      </div>

      <div className={"content-block dx-card responsive-paddings"}>
        <div>
          <h5>Filtros</h5>
        </div>

        <Form
          id="filtro-form"
          formData={filtro}
          colCount={6}
          labelLocation="top"
        >
          <GroupItem colCount={6} colSpan={6}>
            <SimpleItem
              dataField="dataInicial"
              editorType="dxDateBox"
              editorOptions={{ displayFormat: "dd/MM/yyyy", useMaskBehavior: true }}
            >
              <Label text="Data Inicial" />
            </SimpleItem>

            <SimpleItem
              dataField="dataFinal"
              editorType="dxDateBox"
              editorOptions={{ displayFormat: "dd/MM/yyyy", useMaskBehavior: true  }}
            >
              <Label text="Data Final" />
            </SimpleItem>

            <SimpleItem colSpan={2} dataField="nome">
              <Label text="Nome/Razão Social Tomador" />
            </SimpleItem>

            <SimpleItem colSpan={2} dataField="cpfCnpj">
              <Label text="CPF/CNPJ Tomador" />
            </SimpleItem>

            <SimpleItem dataField="numero">
              <Label text="Número" />
            </SimpleItem>

            <SimpleItem dataField="nmeroRps">
              <Label text="Número RPS" />
            </SimpleItem>

            <SimpleItem
              colSpan={1}
              dataField="situacaoNota"
              editorType="dxSelectBox"
              editorOptions={situacaoOptions}
            >
              <Label text="Situação da Nota" />
            </SimpleItem>

            <SimpleItem
              colSpan={1}
              dataField="retida"
              editorType="dxSelectBox"
              editorOptions={retidaOptions}
            >
              <Label text="Retida" />
            </SimpleItem>



            <SimpleItem
              dataField="mesCompetencia"
              editorType="dxSelectBox"
              editorOptions={mesOptions}
            >
              <Label text="Mês Competência" />
            </SimpleItem>

            <SimpleItem
              dataField="anoCompetencia"
              editorType="dxSelectBox"
              editorOptions={anoOptions}
            >
              <Label text="Ano Competência" />
            </SimpleItem>

          </GroupItem>
        </Form>
        <br />
        <br />
        <div
          style={{
            display: "flex",
            marginTop: "25px",
            justifyContent: "center",
            alignContent: "center",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <div
            className="save-create-button-div"
            style={{ display: "inline-block" }}
          >
            <Button
              id="filtrar"
              className={"filter-button"}
              text="Filtrar"
              type="default"
              onClick={filtrar}
              width={200}
            />
          </div>
          <div style={{ display: "inline-block", marginLeft: "10px" }}>
            <Button
              className={"clear-button"}
              text="Limpar Filtro"
              onClick={limparFiltro}
              width={200}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <DataGrid
          ref={_gridInstance}
          dataSource={gridDataSource}
          showBorders={true}
          rowAlternationEnabled={true}
          remoteOperations={true}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          focusedRowEnabled={true}
          wordWrapEnabled={window.innerWidth < 800}
          filterSyncEnabled={true}
          key={"id"}
        >
          <Column
            dataField={"id"}
            allowEditing={false}
            caption={"#"}
            visible={false}
            width={80}
          />
          <Column
            dataField={"numero"}
            visibleIndex={0}
            allowEditing={false}
            caption={"Nº"}
            width={80}
            dataType={"number"}
            format={uiInfo.formatoNumerico.inteiroComPonto}
            sortIndex={0}
            sortOrder={"desc"}
            alignment={"center"}
          />
          <Column dataField={"cv"} caption={"Cód. Verificação"} />
          <Column
            dataField={"dataEmissao"}
            dataType={"date"}
            caption={"Dt. Emissão"}
          />
          <Column dataField={"tomadorCpfCnpj"} caption={"Doc. Tomador"} />
          <Column dataField={"tomadorNome"} caption={"Nome Tomador"} />
          <Column
            dataField={"valorServicos"}
            caption={"Valor dos Serviços"}
            customizeText={(cellInfo) => "R$" + cellInfo.valueText}
            dataType={"number"}
            format={uiInfo.formatoNumerico.n2}
          />
          <Column dataField={"situacao"} caption={"Situação"}>
            <Lookup
              dataSource={situacao}
              displayExpr={"descricao"}
              valueExpr={"id"}
            />
          </Column>
          <Column
            dataField={"valorIss"}
            caption={"Valor ISS"}
            customizeText={(cellInfo) => "R$" + cellInfo.valueText}
            dataType={"number"}
            format={uiInfo.formatoNumerico.n2}
          />
          <Column
            dataField={"issRetido"}
            caption={"Retida"}
            customizeText={(cellInfo) => (cellInfo.value ? "Sim" : "Não")}
            dataType="text"
          />

          <Column type="buttons" caption={"Ações"}>
            <DGButton
              id="imprimir"
              icon="print"
              hint="Imprimir Nota"
              onClick={imprimirNfse}
            />
            <DGButton
              id="substituir"
              icon="refresh"
              hint="Substituir Nota"
              onClick={(invoice) => copyOrReplace("SUBSTITUIR", invoice)}
            />
            <DGButton
              id="copiar"
              icon="copy"
              hint="Copiar Nota"
              onClick={(invoice) => copyOrReplace("COPIAR", invoice)}
            />
            <DGButton
              id="baixar"
              icon="download"
              hint="Baixar XML"
              onClick={printXmlFile}
            />
            <DGButton
              id="cancelar"
              icon="clearsquare"
              hint="Cancelar Nota"
              onClick={({ row }) =>
                setCancelingReason({
                  ...cancelingReason,
                  showPopup: true,
                  invoiceId: row.data.id,
                })
              }
            />
            <DGButton
              id="detalhes"
              icon="info"
              hint="Detalhes"
              onClick={(e) => {
                console.log("dados detalhes", e);
                console.log(routeParams);
                history.push({
                  pathname: `/${routeParams.prefeitura}/nota-emitida`,
                  state: {
                    nfseId: e.row.data.id,
                    numero: e.row.data.numero,
                    cv: e.row.data.cv,
                    dataEmissao: e.row.data.dataEmissao,
                    valorNota: e.row.data.valorServicos,
                    tomador: `${e.row.data.tomadorCpfCnpj} - ${e.row.data.tomadorNome}`,
                    emailTomador: e.row.data.tomadorEmail
                  }
                });
              }}
            />
          </Column>
        </DataGrid>
        <Popup
          visible={cancelingReason.showPopup}
          onHiding={() =>
            setCancelingReason({
              ...cancelingReason,
              data: {},
              showPopup: false,
            })
          }
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          width={"600px"}
          height={"300px"}
          title="Cancelar Nota Fiscal"
        >
          <Form labelLocation={"top"} formData={cancelingReason.data}>
            <SimpleItem dataField="motivoCancelamento">
              <Label text="Motivo do Cancelamento" />
            </SimpleItem>
          </Form>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Fechar",
              type: "normal",
              width: "150px",
              onClick: cancelTaxInvoice,
            }}
          />
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Enviar",
              type: "default",
              width: "150px",
              onClick: cancelTaxInvoice,
            }}
          />
        </Popup>
      </div>
    </div>
  );
};
