// import 'devextreme/dist/css/dx.common.css';
// import './themes/generated/theme.base.css';
// import './themes/generated/theme.additional.css';

import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";

import ptMessages from "devextreme/dist/js/localization/dx.messages.pt";
import { locale, loadMessages } from "devextreme/localization";

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import NotAuthenticatedContent from "./NotAuthenticatedContent";
import { isAuthenticated } from "./auth";
import { useLocation } from "react-router-dom";
import UserInfo from "./UserInfo";

function App() {
  loadMessages(ptMessages);
  locale("pt-BR");

  const { loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (isAuthenticated()) {
    var args = location.pathname.split("/");
    const prefeitura = args[1];

    var info = UserInfo.obterUserInfo();

    if (info != undefined) {
      let codigoPortalUsuario = "";
      if (
        info.prefeitura != undefined &&
        info.prefeitura.codigoPortal != undefined
      ) {
        codigoPortalUsuario = info.prefeitura.codigoPortal;
      } else {
        codigoPortalUsuario = "saobenedito.ce";
      }
    }

    return <Content />;
  } else {
    return <NotAuthenticatedContent />;
  }
}

export default function () {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
