import { createStore } from "devextreme-aspnet-data-nojquery";
import UserInfo from "../UserInfo";
import { getToken } from "../auth";
import ambiente from "../ambiente";

const apiPath = ambiente.URL_API;

function onBeforeSend(_, settings) {
  const token = getToken();
  settings.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  };
}

export const servicosTomadosDs = function () {
  return createStore({
    key: "id",
    loadUrl: `${apiPath}/escrituracao/prefeitura/${UserInfo.getPrefeitura()}`,
    onBeforeSend,
  });
};

export const municipiosDs = function () {
  return createStore({
    key: "id",
    loadUrl: `${apiPath}/municipios`,
    onBeforeSend,
  });
};

export const servicosDs = function () {
  return createStore({
    key: "id",
    loadUrl: `${apiPath}/servicos/prefeitura/${UserInfo.getPrefeitura()}`,
    onBeforeSend,
  });
};

export const notasDs = function () {
  return createStore({
    key: "id",
    loadUrl: `${apiPath}/nfse/${UserInfo.getPrefeitura()}`,
    onBeforeSend,
  });
};

export const criarPlanoContasDs = function () {
  return createStore({
    key: "id",
    loadUrl: `${apiPath}/contas_escrituracao/${UserInfo.getCpbsId()}`,
    insertUrl: `${apiPath}/contas_escrituracao`,
    updateUrl: `${apiPath}/contas_escrituracao`,
    deleteUrl: `${apiPath}/contas_escrituracao`,
    onBeforeSend,
  });
};
