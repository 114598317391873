import React, { useState, useEffect } from "react";
import axios from "axios";
import api from "../../service/api";
import { useHistory } from "react-router-dom";
import "./notfoundpage.scss";
//import Logo from "../../assets/trimap-logo.jpg";

const NotFoundPage = () => {
  const [prefeituras, setPrefeituras] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const source = axios.CancelToken.source();

    api
      .get("prefeituras")
      .then((data) => {
        setPrefeituras(data.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function pushPage(prefeituraPath) {
    history.replace(`/${prefeituraPath}/login`);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings notfound"}>
        <div className="card-content">
         {/*  <img src={Logo} alt="TRIMAP" className="logo" /> */}
          {/* {process.env.REACT_APP_ESTAGIO === "prod" ? null : <h3>{"Ambiente " + process.env.REACT_APP_ESTAGIO.toUpperCase()}</h3>} */}
          <h3>Prefeitura não encontrada</h3>
          Não foi possível localizar uma prefeitura nesse endereço. Abaixo segue
          a lista de nossas prefeituras:
          <select
            className={"select-box"}
            placeholder="Escolha uma Prefeitura"
            onChange={({ target }) => pushPage(target.value)}
          >
            <option value="">Selecione uma prefeitura</option>
            {prefeituras.length > 0 && 
               prefeituras.map((prefeitura, key) => {
                return (
                  <option key={key} value={prefeitura.codigoPortal}>
                    {prefeitura.nome}
                  </option>
                );
              })
             }
          </select>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NotFoundPage;
