import React from "react";
import notify from "devextreme/ui/notify";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import "devextreme-react/text-area";
import { Button } from "devextreme-react";
import api from "../../service/api";
import showReport from "../../service/showReport";
import { LoadIndicator } from 'devextreme-react/load-indicator';

export default class NotaEmitidaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.location.state ?? {};
    this.imprimirNfse = this.imprimirNfse.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.setStateSync = this.setStateSync.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.prefeitura) {
      this.setState({ prefeitura: this.props.match.params.prefeitura });
    }
  }

  render() {

    if (this.state._uiCarregando) {
      return (
        <div className="dx-card card-copiar-nfse content-block responsive-paddings" style={{ textAlign: "center" }}>
          {this.state._uiCarregando ? <LoadIndicator id="large-indicator" height={60} width={60} /> : null}
          <div className="label">{this.state._uiMensagemCarregando}</div>
        </div>
      );
    }


    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          <form>
            <Form formData={this.state} colCount={2} labelLocation="top">
              <GroupItem caption="INFORMAÇÕES DA NOTA" colSpan={2} colCount={4}>
                <SimpleItem
                  dataField={"numero"}
                  editorOptions={{ readOnly: true }}
                >
                  <Label text="Número" />
                </SimpleItem>
                <SimpleItem
                  dataField={"dataEmissao"}
                  editorType={"dxDateBox"}
                  itemType={"time"}
                  colSpan={1}
                  editorOptions={{ readOnly: true, type: "datetime" }}
                >
                  <Label text="Emissão" />
                </SimpleItem>
                <SimpleItem
                  dataField={"cv"}
                  colSpan={1}
                  editorOptions={{ readOnly: true }}
                >
                  <Label text="Código de Verificação" />
                </SimpleItem>
                <SimpleItem
                  dataField={"valorNota"}
                  editorType={"dxNumberBox"}
                  colSpan={1}
                  editorOptions={{
                    readOnly: true,
                    format: { type: "fixedPoint", precision: 2 },
                  }}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Valor da Nota" />
                </SimpleItem>
              </GroupItem>
              <GroupItem
                caption="TOMADOR DOS SERVIÇOS"
                colSpan={2}
                colCount={4}
              >
                <SimpleItem
                  dataField={"tomador"}
                  colSpan={4}
                  editorOptions={{ readOnly: true }}
                >
                  <Label text="Tomador" />
                </SimpleItem>
                <SimpleItem dataField={"emailTomador"} colSpan={4}>
                  <Label text="E-mail" />
                </SimpleItem>
              </GroupItem>
            </Form>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "inline-block" }}>
                <Button
                  width={200}
                  text="Imprimir Nota"
                  icon={"print"}
                  onClick={this.imprimirNfse}
                />
              </div>
              <div style={{ display: "inline-block", marginLeft: "10px" }}>
                <Button
                  text="Enviar por Email"
                  icon={"email"}
                  onClick={this.sendEmail}
                  width={200}
                />
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }

  async imprimirNfse() {
    try {

      await this.setStateSync({ _uiCarregando: true, _uiMensagemCarregando: "Imprimindo NFS-e..." });

      await showReport(
        `/report/nfse/${this.state.prefeitura}/${this.state.nfseId}`
      );
    } catch (error) {
      notify(error, "error");
    }

    await this.setStateSync({ _uiCarregando: false });
  }

  setStateSync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  async sendEmail() {



    if (this.state.nfseId) {

      await this.setStateSync({ _uiCarregando: true, _uiMensagemCarregando: "Enviando e-mail..." });

      try {
        const response = await api.post("/nfse/enviar_por_email", {
          nfseId: this.state.nfseId,
          email: this.state.emailTomador,
        });

        if (response.data.sucesso) {
          notify("E-mail enviado com sucesso.");
        }
        else {
          console.log("resposta envio e-mail", response.data);
          notify(response.data.msgErro, "error");
        }
      }
      catch (error) {
        notify(error, "error");
      }
      await this.setStateSync({ _uiCarregando: false });

    }


  }
}
