import React from "react";
import {
  DataGrid,
  Editing,
  Column,
  Paging,
  Pager,
  FormItem,
  Format,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import Form, { SimpleItem, Label } from "devextreme-react/form";
import uiInfo from "../ui-info";
import { criarServicosIssDs } from "../DataSources";

export default class ServicosIssBox extends React.Component {
  constructor(props) {
    super();
    this._gridInstance = React.createRef();
    this.state = {
      filtroCorrente: [],
      filtro: {},
    };
    this.filtrar = this.filtrar.bind(this);
    this.limparFiltro = this.limparFiltro.bind(this);
    this.onSelecionado = props.onSelecionado;
    this.usadoComoPesquisa = props.onSelecionado !== undefined;
  }

  filtrar(e) {
    let filtro = this.state.filtro;
    let filtroCorrente = [];

    if (filtro.lcFederal !== undefined && filtro.lcFederal !== "") {
      filtroCorrente.push(["lcFederal", "contains", filtro.lcFederal.trim()]);
    }
    if (filtro.lcMunicipal !== undefined && filtro.lcMunicipal !== "") {
      filtroCorrente.push([
        "lcMunicipal",
        "contains",
        filtro.lcMunicipal.trim(),
      ]);
    }
    if (filtro.descricao !== undefined && filtro.descricao !== "") {
      filtroCorrente.push(["descricao", "contains", filtro.descricao.trim()]);
    }

    this._gridInstance.instance.filter(
      filtroCorrente.length > 0 ? filtroCorrente : null
    );
  }

  limparFiltro(e) {
    this.setState({ filtro: {} });
    this.filtrar();
  }

  render() {
    return (
      <div className={"content-block dx-card responsive-paddings"}>
        <form onSubmit={this.filtrar}>
          <Form
            id="frmPesquisa"
            formData={this.state.filtro}
            colCount={2}
            labelLocation={"top"}
          >
            <SimpleItem dataField="lcFederal" colSpan={1}>
              <Label text="Código LC Federal" />
            </SimpleItem>
            <SimpleItem dataField="lcMunicipal" colSpan={1}>
              <Label text="Código LC Municipal" />
            </SimpleItem>
            <SimpleItem dataField="descricao" colSpan={2}>
              <Label text="Descrição" />
            </SimpleItem>
          </Form>
          <br />
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <Button
                width={120}
                text="Filtrar"
                type="success"
                onClick={this.filtrar}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button text="Limpar Filtro" onClick={this.limparFiltro} />
            </div>
          </div>
        </form>
        <br />
        <h3>SERVIÇOS</h3>
        <DataGrid
          ref={(grid) => (this._gridInstance = grid)}
          dataSource={criarServicosIssDs()}
          showBorders={true}
          rowAlternationEnabled={true}
          remoteOperations={true}
          focusedRowEnabled={true}
          wordWrapEnabled={true}
          filterSyncEnabled={true}
          onEditingStart={this.onAtualizandoRow}
        >
          <Editing
            allowAdding={false}
            allowUpdating={false}
            allowDeleting={false}
            useIcons={true}
          />
          <Column
            type="buttons"
            width={60}
            buttons={[
              {
                icon: "repeat",
                visible: this.onSelecionado != undefined,
                onClick: this.onSelecionado,
              },
            ]}
          />
          <Column dataField={"id"} visible={false}>
            <FormItem visible={false}></FormItem>
          </Column>
          <Column dataField={"prefeituraId"} visible={false}>
            <FormItem visible={false}></FormItem>
          </Column>
          <Column
            dataField={"sequencial"}
            caption={"#"}
            allowEditing={false}
            width={80}
            alignment={"center"}
          />

          <Column
            dataField={"lcMunicipal"}
            caption={"Cód. Municipal"}
            width={100}
          />
          <Column dataField={"lcFederal"} caption={"Cód. Federal"} width={90} />
          <Column dataField={"aliquotaIss"} caption={"Alíquota ISS"} width={90}>
            <Format type={"fixedPoint"} precision={2} />
          </Column>
          <Column
            dataField={"aliquotaIrrfPj"}
            caption={"Alíquota IRRF PJ"}
            width={90}
            visible={false}
          >
            <Format type={"fixedPoint"} precision={2} />
          </Column>
          <Column
            dataField={"aliquotaInssPj"}
            caption={"Alíquota INSS PJ"}
            visible={false}
            width={90}
          >
            <Format type={"fixedPoint"} precision={2} />
          </Column>
          <Column
            dataField={"descricao"}
            caption={"Descrição"}
            wordWrapEnabled={true}
          />
          <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
          />
        </DataGrid>
      </div>
    );
  }
}
