import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SingleCard } from "./layouts";
import {
  ChangePasswordForm,
  CreateAccountForm,
} from "./components";
import { LoginForm, NotFoundPage, RecuperarSenha } from "./pages";

export default function () {
  return (
    <Switch>
      <Route exact path="/:prefeitura/login">
        <LoginForm />
      </Route>
      <Route exact path="/create-account">
        <SingleCard title="Sign Up">
          <CreateAccountForm />
        </SingleCard>
      </Route>
      <Route exact path="/:prefeitura/recuperar-senha">
        <RecuperarSenha />
      </Route>
      <Route exact path="/change-password/:recoveryCode">
        <SingleCard title="Change Password">
          <ChangePasswordForm />
        </SingleCard>
      </Route>
      <Route exact path="/">
        <NotFoundPage />
      </Route>
      <Redirect to={"/"} />
    </Switch>
  );
}
