import React from "react";
import api from "../../service/api";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import UserInfo from "../../UserInfo";
import "./servicos-tomados.scss";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { municipiosDs, servicosDs, servicosTomadosDs } from "../../service/DataSources";
import { EmptyItem } from "devextreme-react/tree-list";

const prefeitura = UserInfo.getPrefeitura();

const template = {
  prefeituraId: prefeitura,
  cpbsId: UserInfo.getCpbsId(),
  deducoes: 0,
  valorNota: 0,
  aliquotaExterna: 0,
  issRetido: true,
  prestadorSimplesNacional: false,
  prestadorTipoPessoa: "J",
  isentoImune: false,
  aliquotaBloqueada: true,
  prestadorRegime: 0,
  valorInss: 0,
  valorIrrf: 0,
  valorCsll: 0,
  valorPis: 0,
  valorCofins: 0,
  valorOutrasRetencoes: 0,
  localId: 2309409,
  _uiMunicipioSistema: 2309409
};

export default class ServicosTomadosForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...template };
    this.refForm = React.createRef();

    this.servicosTomadosDs = servicosTomadosDs();
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
    this.saidaProgramada = false;
    this.handlePrestadorSearch = this.handlePrestadorSearch.bind(this);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.onServicoAlterado = this.onServicoAlterado.bind(this);
    this.onSimplesAlterado = this.onSimplesAlterado.bind(this);
    this.onRegimeAlterado = this.onRegimeAlterado.bind(this);

    this.atividadeOptions = {
      editorType: "dxSelectBox",
      searchEnabled: true,
      placeholder: " ",
      dataSource: servicosDs(),
      displayExpr: "codigoDescricao",
      onValueChanged: this.onServicoAlterado
    };

    this.simNaoOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      dataSource: [
        { id: true, descricao: "SIM" },
        { id: false, descricao: "NÃO" }
      ],
      valueExpr: "id",
      displayExpr: "descricao"
    };


    this.simplesOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      dataSource: [
        { id: true, descricao: "SIM" },
        { id: false, descricao: "NÃO" }
      ],
      valueExpr: "id",
      displayExpr: "descricao",
      onValueChanged: this.onSimplesAlterado
    };

    this.localOptions = {
      editorType: "dxSelectBox",
      searchEnabled: true,
      placeholder: " ",
      dataSource: municipiosDs(),
      valueExpr: "id",
      displayExpr: "nome",
      onValueChanged: (e) => this.setState({ prestadorMunicipioId: e.value }),
    };
    this.naturezaOperacaoOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      placeholder: "Selecionar...",
      dataSource: [
        { id: 1, descricao: "TRIBUTADO NO MUNÍCIPIO" },
        { id: 2, descricao: "TRIBUTADO FORA DO MUNÍCIPIO" },
        { id: 3, descricao: "NÃO TRIBUTÁVEL" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
      onValueChanged: (e) => this.setState({ naturezaOperacao: e.value }),
    };
    this.tipoOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      placeholder: "Selecionar...",
      dataSource: [
        { id: "F", descricao: "FÍSICA" },
        { id: "J", descricao: "JURÍDICA" },
        { id: "E", descricao: "ESTRANGEIRO" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
    };

    this.regimeOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      placeholder: "Selecionar...",
      dataSource: [
        { id: 0, descricao: " " },
        { id: 1, descricao: "NORMAL" },
        { id: 2, descricao: "AUTONOMO" },
        { id: 3, descricao: "SOCIEDADE DE PROFISSIONAIS" },
        { id: 4, descricao: "ESTIMATIVA" },
        { id: 5, descricao: "MEI" },
        { id: 6, descricao: "ISENTO" },
        { id: 7, descricao: "IMUNE" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
      onValueChanged: this.onRegimeAlterado
    };

  }

  onSimplesAlterado(e) {
    console.log(e);
    if (e != undefined) {

      if (e.previousValue != e.value) {

        this.setDadosTributacao(undefined, undefined, undefined, undefined, e.value);
      }

    }
  }

  onRegimeAlterado(e) {
    console.log(e);
    if (e != undefined) {

      if (e.previousValue != e.value) {

        this.setDadosTributacao(undefined, undefined, undefined, e.value, undefined)
      }

    }
  }

  onServicoAlterado(e) {

    if (e != undefined) {

      if (e.previousValue == undefined || e.previousValue.id != e.value.id) {

        this.setDadosTributacao(e.value, undefined, undefined);
      }

    }
  }

  setDadosTributacao(servicoContexto, localPrestacaoContexto, isentoImuneContexto, regimeContexto, snContexto) {
    const servico = servicoContexto ?? this.state._uiServico;
    const localPrestacao = localPrestacaoContexto ?? this.state.localId;
    const isentoImune = isentoImuneContexto ?? this.state.isentoImune;
    const regime = regimeContexto ?? this.state.prestadorRegime;
    const simplesNacional = snContexto ?? this.state.prestadorSimplesNacional;

    console.log("servico", servico);

    console.log(
      "tributada no local serviço", servico?.tributacaoNoLocalServico,
      "local prestacao", localPrestacao,
      "isentoImune", isentoImune,
      "regime", regime,
      "simplesNacional", simplesNacional);

    let aliquota = this.state.aliquota;
    let aliquotaBloqueada = false;

    if (regime > 1) {
      aliquotaBloqueada = true;
      aliquota = 0;
    }
    else if (simplesNacional) {
      console.log("sn");
      aliquotaBloqueada = false;
    }
    else if (servico.tributacaoNoLocalServico) {
      console.log("local prestacao", localPrestacao, "municipio sistema", this.state._uiMunicipioSistema);
      if (localPrestacao == this.state._uiMunicipioSistema) {
        aliquota = servico.aliquotaIss;
        aliquotaBloqueada = true;
      }
      else {
        aliquotaBloqueada = false;
      }
    }
    else {
      if (this.state.prestadorMunicipioId == this.state._uiMunicipioSistema) {
        aliquota = servico.aliquotaIss;
        aliquotaBloqueada = true;
      }
      else {
        aliquotaBloqueada = false;
      }
    }

    console.log("setado bloqueio aliquota", aliquota, aliquotaBloqueada);
    this.setState({ aliquota, aliquotaBloqueada });
    this.refForm.current.instance.getEditor("aliquota").option("value", aliquota);
    this.refForm.current.instance.getEditor("aliquota").repaint();
    this.refForm.current.instance.getEditor("aliquota").option("disabled", aliquotaBloqueada);

  }




  async save(e) {
    e.preventDefault();
    if (this.state.id === undefined) {
      try {
        const response = await api.post("/escrituracao", this.state);
        if (response.status === 200) {
          notify("Escrituração cadastrada com sucesso.", "success");
          this.props.history.goBack();
        }
      } catch (err) {
        console.log(err);
        notify(err, "error", 500);
      }
    } else {
      try {
        let formData = new FormData();
        const response = await api.put("/escrituracao", formData);
        if (response.status === 200) {
          notify("Escrituração atualizada com sucesso.", "success");
          this.props.history.goBack();
        }
      } catch (err) {
        console.log(err);
        notify(err, "error", 500);
      }
    }
  }

  async handlePrestadorSearch() {
    if (
      this.state.prestadorDocumento != null &&
      this.state.prestadorDocumento != ""
    ) {
      try {
        const formData = new FormData();
        formData.set("documento", this.state.prestadorDocumento);
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        const response = await api.post(
          "/escrituracao/prestador",
          formData,
          config
        );
        if (response.status === 200 && response.data != null) {
          this.setState({ ...response.data });
        } else {
          let prestadorTemplate = {
            prestadorBairro: undefined,
            prestadorCEP: undefined,
            prestadorComplemento: undefined,
            prestadorIE: undefined,
            prestadorIM: undefined,
            prestadorLogradouro: undefined,
            prestadorMunicipioId: undefined,
            prestadorNome: undefined,
            prestadorNumero: undefined,
            prestadorPaisId: undefined,
            prestadorSimplesNacional: undefined,
            prestadorTipoPessoa: undefined,
            prestadorRegime: 0
          };
          this.setState({ ...prestadorTemplate });
          notify(
            "Prestador não cadastrador, por favor, cadastrar manualmente!",
            "warning",
            4000
          );
        }
      } catch (err) {
        console.log(err);
        notify(err, "error", 500);
      }
    }
  }

  async saveAndContinue() {
    if (this.state.id === undefined) {
      try {
        const response = await api.post("/escrituracao", this.state);
        if (response.status === 200) {
          notify("Escrituração cadastrada com sucesso.", "success");
          this.setState({});
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
        notify(err, "error", 500);
      }
    }
  }

  cancel() {
    notify("Operação cancelada.", "warning");
    this.props.history.goBack();
  }

  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      this.servicosTomadosDs
        .byKey(this.props.match.params.id)
        .then((data) => {
          if (data == undefined) {
            notify("Serviço não encontrado.", "error");
            this.props.history.push("/servicos-tomados");
          }
          this.setState(data);
        })
        .catch((erro) => {
          notify(erro, "error");
          this.props.history.goBack();
        });
    }

    if (this.props.match.params.id === undefined) {
      this.setState({ ...template });
    }
  }

  render() {
    return (
      <div>

        <div className={"content-block "}>
          <h2>Registro de Serviço Tomado</h2>
        </div>
        <div className={"content-block dx-card responsive-paddings"}>
          <form onSubmit={(e) => this.save(e)}>
            <Form formData={this.state} colCount={3} labelLocation={"top"} ref={this.refForm}>
              <GroupItem caption="Prestador" colCount={4} colSpan={3}>

                <SimpleItem
                  dataField="prestadorTipoPessoa"
                  editorType="dxLookup"
                  editorOptions={this.tipoOptions}
                >
                  <Label text="Tipo Pessoa" />
                </SimpleItem>
                <SimpleItem
                  dataField="prestadorDocumento"
                  colSpan={1}
                  editorOptions={{
                    showClearButton: true,
                    buttons: [
                      "clear",
                      {
                        name: "Pesquisar",
                        location: "after",
                        options: {
                          stylingMode: "text",
                          icon: "search",
                          onClick: () => this.handlePrestadorSearch(),
                        },
                      },
                    ],
                  }}
                >
                  <Label text="Documento" />
                </SimpleItem>
                <SimpleItem
                  dataField="prestadorSimplesNacional"
                  editorType={"dxLookup"}
                  editorOptions={this.simplesOptions}
                  colSpan={1}
                >
                  <Label text="Simples Nacional" />
                </SimpleItem>
                <SimpleItem
                  dataField="prestadorRegime"
                  editorType={"dxSelectBox"}
                  editorOptions={this.regimeOptions}
                  colSpan={1}
                >
                  <Label text="Regime" />
                </SimpleItem>

                <SimpleItem dataField="prestadorNome" colSpan={2}>
                  <Label text="Nome/Razão Social" />
                </SimpleItem>

                <SimpleItem dataField="prestadorIM" colSpan={1}>
                  <Label text="Inscrição Municipal" />
                </SimpleItem>
                <SimpleItem dataField="prestadorIE" colSpan={1}>
                  <Label text="Inscrição Estadual" />
                </SimpleItem>
                <SimpleItem dataField="prestadorLogradouro" colSpan={3}>
                  <Label text="Logradouro" />
                </SimpleItem>
                <SimpleItem dataField="prestadorNumero" colSpan={1}>
                  <Label text="N°" />
                </SimpleItem>
                <SimpleItem dataField="prestadorComplemento" colSpan={1}>
                  <Label text="Complemento" />
                </SimpleItem>
                <SimpleItem dataField="prestadorBairro" colSpan={1}>
                  <Label text="Bairro" />
                </SimpleItem>
                <SimpleItem
                  dataField="prestadorMunicipioId"
                  editorType="dxLookup"
                  editorOptions={this.localOptions}
                >
                  <Label text="Cidade" />
                </SimpleItem>
                <SimpleItem
                  dataField="prestadorCEP"
                  colSpan={1}
                  editorType={"dxTextBox"}
                  editorOptions={{ value: null, step: 0, mask: "00000-000" }}
                >
                  <Label text="CEP" />
                </SimpleItem>
                <SimpleItem
                  dataField="prestadorPais"
                  colSpan={1}
                  visible={this.state.cidade === 9999999}
                >
                  <Label text="País Becen" />
                </SimpleItem>
                <SimpleItem dataField="prestadorEmail" colSpan={3}>
                  <Label text="E-mail" />
                </SimpleItem>
                <SimpleItem dataField="prestadorFone" colSpan={1}>
                  <Label text="Fone/Celular" />
                </SimpleItem>
              </GroupItem>
              <GroupItem
                caption={"Serviço Prestado"}
                colSpan={3}
                colCount={4}
              >
                <SimpleItem dataField="numeroDocumento" colSpan={1}>
                  <Label text="Nº da Nota" />
                </SimpleItem>
                <SimpleItem
                  dataField="dataEmissao"
                  colSpan={1}
                  editorType="dxDateBox"
                  editorOptions={{ width: "100%", useMaskBehavior: true, type: "datetime", displayFormat: "dd/MM/yyyy HH:mm:ss" }}
                >
                  <Label text="Data/Hora Emissão" />
                </SimpleItem>
                <SimpleItem dataField="cv" colSpan={1}>
                  <Label text="Código Verificação" />
                </SimpleItem>
                <SimpleItem dataField="issRetido" editorType="dxSelectBox" editorOptions={this.simNaoOptions}>
                  <Label text="ISS Retido" />
                </SimpleItem>
                <SimpleItem
                  dataField="localId"
                  editorType="dxLookup"
                  editorOptions={this.localOptions}
                  colSpan={4}
                >
                  <Label text="Local da Prestação" />
                </SimpleItem>
                <SimpleItem
                  dataField="_uiServico"
                  editorType="dxLookup"
                  editorOptions={this.atividadeOptions}
                  colSpan={3}
                >
                  <Label text="Serviço" />
                </SimpleItem>
                <SimpleItem
                  dataField="aliquota"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "fixedPoint", precision: 2 },
                    disabled: this.state.aliquotaBloqueada
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="Alíquota ISS" />
                </SimpleItem>
                <SimpleItem
                  dataField="valorNota"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="Total dos Serviços" />
                </SimpleItem>

                <SimpleItem
                  dataField="deducoes"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="Deduções (R$)" />
                </SimpleItem>
                <SimpleItem
                  dataField="descontoIncondicionado"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="Desc. Incondicionado (R$)" />
                </SimpleItem>
                <SimpleItem dataField="isentoImune" editorType="dxSelectBox" editorOptions={this.simNaoOptions}>
                  <Label text="Isento/Imune" />
                </SimpleItem>
                <SimpleItem dataField="descricaoServico" editorType={"dxTextArea"} colSpan={4} editorOptions={{ height: 90 }}>
                  <Label text="Servico" />
                </SimpleItem>


              </GroupItem>
              <GroupItem
                caption={"Retenções"}
                colSpan={3}
                colCount={6}
              >
                <SimpleItem
                  dataField="valorInss"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="INSS" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorIrrf"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="IRRF" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorCsll"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="CSLL" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorPis"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="PIS" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorCofins"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="COFINS" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorOutrasRetencoes"
                  colSpan={1}
                  editorType={"dxNumberBox"}
                  editorOptions={{
                    format: { type: "currency", currency: "BRL", precision: 2 },
                    step: 0,
                  }}
                  cssClass={"txtNumerico"}
                >
                  <Label text="Outras Retenções" />
                </SimpleItem>
              </GroupItem>

            </Form>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="save-button-div">
                <Button text="Salvar" width={200} useSubmitBehavior={true} />
              </div>
              <div
                className="save-create-button-div"
                style={{
                  marginLeft: "15px",
                  display: this.state.id === undefined ? "" : "none",
                }}
              >
                <Button
                  text="Salvar e criar novo"
                  type="normal"
                  width={200}
                  onClick={() => this.saveAndContinue()}
                />
              </div>
              <div style={{ marginLeft: "15px" }}>
                <Button
                  text="Cancelar"
                  type="danger"
                  width={200}
                  onClick={() => this.cancel()}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
