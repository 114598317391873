export default {
  tamanhoPaginaPadrao: 20,
  tamanhoPaginaOpcoes: [10, 20, 30],
  formatoNumerico: {
    n2: "#,##0.00",
    moeda: "R$ #,##0.00",
    inteiroComPonto: "#,##0",
    inteiroSemPonto: "###0",
  },
  formatoData: {
    dataCurta: "dd/MM/yyyy",
  },
  listaMeses: [
    { id: 1, mes: "Janeiro" },
    { id: 2, mes: "Fevereiro" },
    { id: 3, mes: "Março" },
    { id: 4, mes: "Abril" },
    { id: 5, mes: "Maio" },
    { id: 6, mes: "Junho" },
    { id: 7, mes: "Julho" },
    { id: 8, mes: "Agosto" },
    { id: 9, mes: "Setembro" },
    { id: 10, mes: "Outubro" },
    { id: 11, mes: "Novembro" },
    { id: 12, mes: "Dezembro" },
  ],
};
