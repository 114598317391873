import React, { useState, useEffect, createRef, useRef } from "react";
import "./emitir-nota.scss";
import api from "../../service/api";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import { LoadIndicator } from 'devextreme-react/load-indicator';
import UserInfo from "../../UserInfo";

import Form, {
  SimpleItem,
  GroupItem,
  Label,
  EmptyItem,
} from "devextreme-react/form";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";

//Propriedades dos Selectbox do form//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const formatoNumerico = {
  defaultValue: 0,
  format: { type: "fixedPoint", precision: 2 },
};

export default class EmitirNota extends React.Component {

  constructor(props) {
    super(props);

    this.frmTomador = React.createRef();
    this.formNumNfse = React.createRef();
    this.emitirNotaPopup = this.emitirNotaPopup.bind(this);
    this.obterAliquotaIss = this.obterAliquotaIss.bind(this);
    this.setMunicipioIss = this.setMunicipioIss.bind(this);
    this.emitirNota = this.emitirNota.bind(this);
    this.setEstrageiro = this.setEstrageiro.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.setIntermediario = this.setIntermediario.bind(this);
    this.obterAliquotaIssBloqueada = this.obterAliquotaIssBloqueada.bind(this);
    this.obterMunicipioIssBloqueado = this.obterMunicipioIssBloqueado.bind(this);
    this.controlarCamposBloqueados = this.controlarCamposBloqueados.bind(this);
    this.defineAliquota = this.defineAliquota.bind(this);
    this.copiarNfse = this.copiarNfse.bind(this);
    this.pesquisarNfse = this.pesquisarNfse.bind(this);
    this.preview = this.preview.bind(this);
    this.hideInfo = this.hideInfo.bind(this);
    this.getDisplayMunicipioExpr = this.getDisplayMunicipioExpr.bind(this);
    this.getDisplayServicosExpr = this.getDisplayServicosExpr.bind(this);
    this.onOperacaoAlterada = this.onOperacaoAlterada.bind(this);
    this.onDocumentoTomadorAlterado = this.onDocumentoTomadorAlterado.bind(this);
    this.renderLoaderTomador = this.renderLoaderTomador.bind(this);
    this.onLocalPrestacaoAlterado = this.onLocalPrestacaoAlterado.bind(this);

    this.state = {
      previewData: {},
      previewDto: {},
      _uiErros: [],
      _uiCarregando: true,
      _uiCarregandoTomador: false,
      _uiMunicipios: [],
      _uiOperacoesOptions: {
        dataSource: [],
        valueExpr: "id",
        displayExpr: "descricao",
        placeholder: "Selecione...",
        onValueChanged: this.onOperacaoAlterada
      },
      _uiServicosOptions: {
        dataSource: [],
        placeholder: " ",
        editorType: "dxLookup",
        valueExpr: "id",
        displayExpr: (item) =>
          item
            ? `${item.codigoCnae} / ${item.codigoServico} - ${item.descricaoCnae}`
            : "",
        onValueChanged: this.defineAliquota,
      },
      _uiCidadesBr: [],
      _uiPopupVisible: false,

      _uiOpcoesPaises: {
        className: "lookup-pais",
        dataSource: [],
        valueExpr: "id",
        displayExpr: "nome",
        searchEnabled: true,
        placeholder: " ",
        disabled: true,
      },

      _uiOpcoesPaisesEstrangeiro: {
        className: "lookup-pais",
        dataSource: [],
        valueExpr: "id",
        displayExpr: "nome",
        searchEnabled: true,
        placeholder: " "
      },

      _uiOpcoesLocalDePrestacao: {
        dataSource: [],
        className: "lookup-local-prestacao",
        valueExpr: "id",
        displayExpr: "nomeCompleto",
        searchEnabled: true,
        placeholder: " ",
        onValueChanged: this.onLocalPrestacaoAlterado
      },

      _uiOpcoesMunicipioIncidenciaIss: {
        dataSource: [],
        className: "lookup-local-prestacao",
        valueExpr: "id",
        displayExpr: "nomeCompleto",
        searchEnabled: true,
        placeholder: " ",
        disabled: true
      },

      _uiOpcoesMunicipioTomador: {
        dataSource: [],
        valueExpr: "id",
        displayExpr: "nomeCompleto",
        searchEnabled: true,
        placeholder: " ",
      },

      infoTomador: { estrangeiro: false, pais: 1058 },
      infoIntermediario: { intermediario: false, responsavelRetencao: 1 },
      infoServico: {
        localDePrestacao: null,
        municipioIssId: null,
        pais: 1058,
        issRetido: false,
        operacao: 1,
        aliquotaBloqueada: true,
        municipioIssBloqueado: true,
        aliquota: 0,
        valorNota: 0,
        valorDeducoes: 0,
        descontoCondicionado: 0,
        descontoIncondicionado: 0,
        _uiOpcoesAliquota: {
          format: { type: "fixedPoint", precision: 2 },
          readOnly: true
        },
      },
      infoHistorico: {},
      infoImpostos: {
        valorInss: 0,
        valorIrrf: 0,
        valorCsll: 0,
        valorPis: 0,
        valorCofins: 0,
        outrasRetencoes: 0,
      },
      infoContribuinte: {
        regimeEspecial: false,
        sn: false,
        mei: false,
        municipioId: 1,
      },
      infoObs: {},
      infoConstCivil: {},
      numNfse: { tipoEmissao: 1, notaRef: null, dataServico: new Date() },
    };


    const userInfo = UserInfo.obterUserInfo();

    const retida = [
      { id: true, descricao: "SIM" },
      { id: false, descricao: "NÃO" },
    ];

    this.retidaOptions = {
      items: retida,
      valueExpr: "id",
      displayExpr: "descricao",
      placeholder: "Selecione...",
    };

    //const estrangeiro = ["Sim", "Não"];

    this.paises = [
      { id: "0", nome: "Argentina" },
      { id: "1", nome: "Bolívia" },
      { id: "2", nome: "Brasil" },
      { id: "3", nome: "Chile" },
      { id: "4", nome: "Colômbia" },
      { id: "5", nome: "Equador" },
      { id: "6", nome: "Ilhas Malvinas" },
      { id: "7", nome: "Guiana Francesa" },
      { id: "8", nome: "Guiana" },
      { id: "9", nome: "Paraguai" },
      { id: "10", nome: "Peru" },
      { id: "11", nome: "Suriname" },
      { id: "12", nome: "Urugai" },
      { id: "13", nome: "Venezuela" },
    ];


    this.refCampoAliquota = React.createRef();

    this.estrangeiroOptions = {
      editorType: "dxSelectBox",
      dataSource: [
        { id: true, descricao: "Sim" },
        { id: false, descricao: "Não" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
      onValueChanged: this.setEstrageiro,
    };

    this.intermediarioOptions = {
      editorType: "dxCheckBox",
      onValueChanged: this.setIntermediario,
    };



    this.tipoEmissaoOptions = {
      editorType: "dxSelectBox",
      dataSource: [
        { id: 1, descricao: "EMISSÃO" },
        { id: 2, descricao: "SUBSTITUIÇÃO" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
    };

    this.notaSubstituidaOptions = {
      editorType: "dxTextBox",
      // onContentReady: (e) => {
      //   if (e.value == 0 || e.value == "0" || e.value == undefined) {
      //     e.component.option("value", null);
      //   }
      // },
      // onValueChanged: (e) => {
      //   if (e.value == 0 || e.value == "0" || e.value == undefined) {
      //     e.component.option("value", null);
      //   }
      // },
      buttons: [
        {
          name: "trash",
          location: "after",
          options: {
            stylingMode: "text",
            icon: "copy",
            onClick: e => this.copiarNfse(e.component.option("value")),
          },
        }
      ]
    };



    this.opcoesResponsavel = {
      dataSource: [
        { id: 0, descricao: " " },
        { id: 1, descricao: "Tomador" },
        { id: 2, descricao: "Intermediário" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
      placeholder: " ",
    };

    this.ocultarPopupErros = this.ocultarPopupErros.bind(this);
    this.ocultarPopupPreview = this.ocultarPopupPreview.bind(this);
    this.setStateSync = this.setStateSync.bind(this);

  }

  async onLocalPrestacaoAlterado(e) {

    console.log("on local prestacao alteradado");

    var aliquotaBloqueada = this.obterAliquotaIssBloqueada(undefined, undefined, undefined, undefined, e.value);


   
    await this.setStateSync({
      infoServico: {
        ...this.state.infoServico,
        _uiOpcoesAliquota: { ...this.state.infoServico_uiOpcoesAliquota, disabled: aliquotaBloqueada },
      },
      _uiOpcoesPaises: {
        ...this.state._uiOpcoesPaises,
        disabled: this.state.infoServico.localDePrestacao != 9999999
      }
    });
    this.controlarCamposBloqueados({ aliquotaBloqueada: aliquotaBloqueada })
  }

  setStateSync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }


  ocultarPopupErros() {
    this.setState({ popupErrosVisivel: false });
  }

  ocultarPopupPreview() {
    this.setState({ popupPreview: false });
  }

  renderLoaderTomador() {
    if (!this.state._uiCarregandoTomador) {
      return null;
    }

    return (
      <div style={{ paddingTop: "50x", marginLeft: "-20px" }}>
        <br />
        <LoadIndicator width={25} height={25} />
      </div>
    )
  }

  onDocumentoTomadorAlterado(e) {
    this.setState({ _uiCarregandoTomador: true });

    let novoEstado = {};

    api.post("/nfse/info_tomador", {
      cpbsId: UserInfo.getCpbsId(),
      estrangeiro: this.state.infoTomador.estrangeiro,
      documento: this.state.infoTomador.documento
    })
      .then(resp => {
        if (resp.data.encontrado) {
          novoEstado = {
            nome: resp.data.nome,
            im: resp.data.im,
            ie: resp.data.ie,
            logradouro: resp.data.logradouro,
            numero: resp.data.numero,
            complemento: resp.data.complemento,
            bairro: resp.data.bairro,
            municipioId: resp.data.municipioId,
            cidade: resp.data.cidade,
            estado: resp.data.estado,
            pais: resp.data.pais,
            cep: resp.data.cep,
            email: resp.data.email
          };
        }
      })
      .catch(() => {
        notify("Falha ao obter dados do tomador", "error");
      })
      .finally(() => {
        this.setState({
          _uiCarregandoTomador: false,
          infoTomador: {
            ...this.state.infoTomador,
            ...novoEstado
          }
        }, () => this.frmTomador.current.instance.getEditor("nome").focus());
      });
  }

  onOperacaoAlterada(e) {

    console.log("operacoes emissor", this.state._uiOperacoesOptions.dataSource, "e value", e.value);
    const operacao = this.state._uiOperacoesOptions.dataSource.find((x) => x.id === e.value);

    if (operacao == undefined) {
      return;
    }


    const municipioPrestador = this.state.infoContribuinte.municipioId;
    const municipioCorrente = this.state.infoServico.localDePrestacao;

    const aliquotaBloqueada = this.obterAliquotaIssBloqueada(operacao);
    const municipioIssBloqueado = this.obterMunicipioIssBloqueado(operacao);
    const aliquota = this.obterAliquotaIss(operacao, null);
    const opcoes = { aliquotaBloqueada, municipioIssBloqueado };

    
    this.setState({
      infoServico: {
        ...this.state.infoServico,
        aliquota,
        operacao: operacao.id,
        municipioIssId: operacao.municipioPrestador
          ? municipioPrestador
          : municipioCorrente,
        aliquotaBloqueada,
        municipioIssBloqueado,
        _uiOpcoesAliquota: {
          ...this.state.infoServico._uiOpcoesAliquota,
          readOnly: aliquotaBloqueada
        }
      },
      _uiOpcoesMunicipioIncidenciaIss: {
        ...this.state._uiOpcoesMunicipioIncidenciaIss,
        disabled: municipioIssBloqueado
      },
      _uiOpcoesPaises: {
        ...this.state._uiOpcoesPaises,
        disabled: operacao.municipioPrestador || municipioCorrente != 9999999 
      }
    });

    console.log("state rn agora 1", this.state);

    // this.controlarCamposBloqueados(opcoes);

    this.refCampoAliquota.current.instance.getEditor("aliquota").option("disabled", aliquotaBloqueada);
  }

  obterAliquotaIss(operacao, servico) {

    let objOperacao = operacao;
    let objServico = servico;

    if (!objOperacao) {
      objOperacao = this.state._uiOperacoesOptions.dataSource.find((x) => x.id === this.state.infoServico.operacao);
    }

    if (!objServico) {
      objServico = this.state._uiServicosOptions.dataSource.find((x) => x.id === this.state.infoServico.servicoId);
    }

    console.log("obterAliquotaIss", "objOperacao", objOperacao, "objServico", objServico, "objContribuinte", this.state.infoContribuinte);

    if (objOperacao.aliquotaZerada) return 0.0;

    if (this.state.infoContribuinte.regimeEspecial) {
      return 0.0;
    }

    if (this.state.infoContribuinte.sn) return this.state.infoContribuinte.aliquotaSn;

    if (objOperacao.aliquotaAberta) return this.state.infoServico.aliquota;

    if (objServico) return objServico.aliquota;

    return undefined;
  };


  async componentDidMount() {

    console.log(this.props);
    const cpbsId = UserInfo.getCpbsId();

    let respStart;
    let requestOk = false;

    try {
      respStart = await api.get(`/cpbs/start_nfse/${cpbsId}`);
      requestOk = true;
    }
    catch (error) {
      console.log("error start nfse", error);
      notify(error, "error");
    }

    if (!requestOk) return;

    const dataRespStart = respStart.data;
    const operacao = dataRespStart.operacoes.find((x) => x.id === this.state.infoServico.operacao);

    const aliquotaBloqueada = this.obterAliquotaIssBloqueada(operacao, dataRespStart.sn, dataRespStart.regimeEspecial);
    const municipioIssBloqueado = this.obterMunicipioIssBloqueado(operacao);

    let comandoPesquisa;

    if (this.props && this.props.location && this.props.location.state && this.props.location.state.comandoPesquisa) {
      comandoPesquisa = this.props.location.state.comandoPesquisa;
    }



    let tipoEmissao = 1;
    let numeroNf = undefined;

    if (comandoPesquisa) {
      console.log("comandoPesquisa", comandoPesquisa);
      tipoEmissao = comandoPesquisa.operacao == "COPIAR" ? 1 : 2;
      numeroNf = comandoPesquisa.operacao == "COPIAR" ? null : comandoPesquisa.numeroNf;
    }


    let aliquotaIss = this.state.infoServico.aliquota;

    if (dataRespStart.mei === true || dataRespStart.regimeEspecial === true) {
      aliquotaIss = 0;
    }
    else if (dataRespStart.sn === true) {
      aliquotaIss = dataRespStart.aliquotaSn;
    }

    const novoEstado = {
      numNfse: { notaRef: numeroNf, tipoEmissao: tipoEmissao, dataServico: new Date() },
      versao: dataRespStart.versao,
      _uiCarregando: false,
      _uiOperacoesOptions: { ...this.state._uiOperacoesOptions, dataSource: dataRespStart.operacoes },
      _uiMunicipios: respStart.data.municipios,
      _uiServicosOptions: { ...this.state._uiServicosOptions, dataSource: respStart.data.servicos },
      _uiOpcoesPaises: { ...this.state._uiOpcoesPaises, dataSource: respStart.data.paises },
      _uiOpcoesPaisesEstrangeiro: { ...this.state._uiOpcoesPaisesEstrangeiro, dataSource: respStart.data.paises },
      _uiOpcoesLocalDePrestacao: { ...this.state._uiOpcoesLocalDePrestacao, dataSource: respStart.data.municipios },
      _uiOpcoesMunicipioIncidenciaIss: { ...this.state._uiOpcoesMunicipioIncidenciaIss, dataSource: respStart.data.municipios },
      _uiOpcoesMunicipioTomador: { ...this.state._uiOpcoesMunicipioTomador, dataSource: respStart.data.municipios },
      infoTomador: { ...this.state.infoTomador, municipioId: dataRespStart.municipioId },
      infoContribuinte: {
        msgBloqueioEmissao: dataRespStart.msgBloqueioEmissao,
        mei: dataRespStart.mei,
        sn: dataRespStart.sn,
        aliquotaSn: dataRespStart.aliquotaSn,
        municipioId: dataRespStart.municipioId,
        permiteDeducoes: dataRespStart.permiteDeducoes,
        regimeEspecial: dataRespStart.regimeEspecial
      },
      infoIntermediario: { ...this.state.infoIntermediario, municipioId: dataRespStart.municipioId },
      infoServico: {
        ...this.state.infoServico,
        _uiOpcoesAliquota: {
          format: { type: "fixedPoint", precision: 2 },
          readOnly: aliquotaBloqueada,
          aliquotaBloqueada: aliquotaBloqueada,
          municipioIssBloqueado: municipioIssBloqueado,
        },
        localDePrestacao: dataRespStart.municipioId,
        municipioIssId: dataRespStart.municipioId,
        pais: 1058,
        aliquota: aliquotaIss
      }
    };

    await this.setStateSync(novoEstado);

    if (comandoPesquisa) {

      await this.copiarNfse(comandoPesquisa.numeroNf);
      window.history.replaceState({}, document.title)

    }
  }

  setMunicipioIss(municipioId) {
    const frm = this.refCampoAliquota.current.instance;
    console.log("set municipio iss");
    this.setInfoServico({ ...this.state.infoServico, localDePrestacao: municipioId });
  }

  async emitirNotaPopup() {

    const codigoPortal = this.props.match.params.prefeitura;

    const userInfo = UserInfo.obterUserInfo();

    var dto = this.state.previewDto;
    dto.preview = false;

    try {
      const response = await api.post("/nfse", dto, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("resposta emissão nfse", response.data);

      console.log(userInfo);

      this.props.history.push({
        pathname: `/${codigoPortal}/nota-emitida`,
        state: response.data
      });

    }
    catch (error) {
      notify(error.message, "error");
    }
  }

  async emitirNota() {
    console.log(this.state);



    if (this.state.infoServico.issRetido === "Sim") this.state.infoServico.issRetido = true;
    else if (this.state.infoServico.issRetido === "Não") this.state.infoServico.issRetido = false;



    const nfseDto = {
      preview: true,
      versao: "2.03",
      prestadorId: UserInfo.getCpbsId(),
      dataServico: this.state.numNfse.dataServico,
      numeroRps: this.state.numNfse.numeroRps,
      dataRps: this.state.numNfse.dataRps,
      tipoEmissao: this.state.numNfse.tipoEmissao,
      notaRef: this.state.numNfse.notaRef,
      tomador: {
        ...this.state.infoTomador
      },
      apuracaoIss: {
        ...this.state.infoServico,
      },
      Intermediario: this.state.infoIntermediario.intermediario ? { ...this.state.infoIntermediario } : undefined,
      Retencoes: { ...this.state.infoImpostos },
      DadosObra: { ...this.state.infoConstCivil },
      ...this.state.infoObs,
      prefeituraId: UserInfo.getPrefeitura(),
      descricaoServico: this.state.infoHistorico.descricaoServico,
    };

    if (!nfseDto.tomador.estrangeiro) {
      nfseDto.tomador.paisId = "1058";
    }
    else {
      nfseDto.tomador.municipioId = 9999999;
    }

    const response = await api.post("/nfse", nfseDto, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.sucesso) {

      console.log("sucesso emitir nfse", response.data);

      this.setState({
        popupPreview: true,
        previewData: response.data.preview,
        previewDto: response.data.dto
      });

    } else {
      console.log("StackTrace do erro", response.data.stackTrace);

      this.setState({
        popupErrosVisivel: true,
        _uiErros: response.data.erros
      });
    }
  }

  setEstrageiro(e) {
    this.setState({
      infoTomador: {
        ...this.state.infoTomador,
        estrangeiro: e.value,
      }
    });
  };

  cancelar() {
    // this.setInfoTomador({});
    // this.setInfoServico({});
    // this.setInfoHistorico({});
    // this.setIntermediario({});
    // this.setConstCivil({});
    // this.setInfoImpostos({});
    // this.setInfoObs({});
    this.props.history.goBack();
  }


  setIntermediario(e) {
    this.setState({ intermediario: e.value });
  };

  obterAliquotaIssBloqueada(operacao, snContexto, regimeEspecialContexto, servicoContexto, localPrestacaoContexto) {


    if (regimeEspecialContexto || this.state.infoContribuinte.regimeEspecial) {
      return true;
    }

    const operacaoSelecionada =
      operacao || this.state._uiOperacoesOptions.dataSource.find((x) => x.id === this.state.infoServico.operacao);

    if (operacaoSelecionada.aliquotaZerada) {
      return true;
    }

    if (operacaoSelecionada.aliquotaAberta) {
      return false;
    }

    if (snContexto || this.state.infoContribuinte.sn) {
      return false;
    }

    const servico = servicoContexto || this.state.infoServico.servico;
    const localDePrestacao = localPrestacaoContexto || this.state.infoServico.localDePrestacao;

    if (servico && localDePrestacao) {
      if (servico.tributacaoNoLocalServico) {
        return localDePrestacao == this.state.infoContribuinte.municipioId;
      }
    }

    return true;
  };

  obterMunicipioIssBloqueado(operacao) {
    const operacaoCorrente = operacao || this.state._uiOperacoesOptions.dataSource.find(this.state.infoServico.operacao);

    if (operacao.municipioPrestador) {
      return true;
    }
    return false;
  };

  controlarCamposBloqueados(opcoes) {
    const frm = this.refCampoAliquota.current.instance;

    if (opcoes.aliquotaBloqueada != undefined) {
      console.log("alterando visibilidade aliquota", opcoes.aliquotaBloqueada);
      const editorAliquota = frm.getEditor("aliquota");
      editorAliquota.option("readOnly", opcoes.aliquotaBloqueada);
      editorAliquota.repaint();
    }

    if (opcoes.municipioIssBloqueado != undefined) {
      const editorLocalPrestacao = frm.getEditor("localDePrestacao");

      editorLocalPrestacao.option("readonly", opcoes.municipioIssBloqueado);
    }
  };

  defineAliquota(e) {
    var servico = this.state._uiServicosOptions.dataSource.find((x) => x.id === e.value);

    console.log("novo serviço", servico);

    var aliquota = this.obterAliquotaIss(undefined, servico);

    console.log("set servico", servico);

    this.setState({
      infoServico: {
        ...this.state.infoServico,
        servicoId: e.value,
        servico,
        aliquota,
      }
    });
  };

  async copiarNfse(numeroNotaContext) {
    try {
      const cpbsId = UserInfo.getCpbsId();
      const numeroNf = numeroNotaContext ?? this.state.numNfse.notaRef;
      const resp = await api.get(`/nfse/${cpbsId}/${numeroNf}`);
      console.log("resposta copiar", resp);

      const data = resp.data;

      const aliquotaBloqueada = this.obterAliquotaIssBloqueada(data.operacao);

      const objServico = this.state._uiServicosOptions.dataSource.find((x) => x.codigoCnae === data.cnae.codigo && x.codigoServico === data.servicoMunicipal.lcMunicipal);

      let novoEstado = {
        infoTomador: {
          estrangeiro: data.tomadorEstrangeiro,
          pais: data.tomadorPaisBacenId,
          documento: data.tomadorCpfCnpj,
          im: data.tomadorIm,
          ie: data.tomadorIe,
          nome: data.tomadorNome,
          logradouro: data.tomadorLogradouro,
          numero: data.tomadorNumero,
          complemento: data.tomadorComplemento,
          bairro: data.tomadorBairro,
          municipioId: data.tomadorMunicipioId,
          cep: data.tomadorCep,
          email: data.tomadorEmail
        },
        infoIntermediario: {
          intermediario: data.servicoIntermediado,
          cpfCnpj: data.intermediarioCpfCnpj,
          im: data.intermediarioIm,
          nome: data.intermediarioNome,
          municipioId: data.intermediarioMunicipioId,
          responsavelRetencao: data.responsavelRetencao,

        },
        infoServico: {
          servicoId: objServico.id,
          operacao: data.operacao,
          aliquota: data.aliquotaIss,
          localDePrestacao: data.municipioLocalServicoId,
          municipioIssId: null,
          pais: 1058,
          issRetido: data.issRetido,
          valorNota: data.valorServicos,
          valorDeducoes: data.valorDeducoes,
          descontoCondicionado: data.descontoCondicionado,
          descontoIncondicionado: data.descontoIncondicionado,
          processo: data.processo,
          _uiOpcoesAliquota: {
            format: { type: "fixedPoint", precision: 2 },
            readOnly: aliquotaBloqueada
          }
        },
        infoHistorico: {
          descricaoServico: data.descricaoServico
        },
        infoImpostos: {
          valorInss: data.valorInss,
          valorIrrf: data.valorIrrf,
          valorCsll: data.valorCsll,
          valorPis: data.valorPis,
          valorCofins: data.valorCofins,
          outrasRetencoes: data.outrasRetencoes,
        },
        infoObs: {

        },
        infoConstCivil: {
          codigo: data.codigoObra,
          art: data.art
        }
      };




      await this.setStateSync(novoEstado);

      this.defineAliquota({ value: objServico.id });
    }
    catch (error) {
      notify(error, "error");
    }
  };

  pesquisarNfse() {
    notify("Pesquisando nota", "info", 4000);
  };

  preview() {
    this.setPopupVisible(true);
  };

  hideInfo() {
    this.setPopupVisible(false);
  };

  getDisplayMunicipioExpr(item) {
    return item ? item.nome : "";
  };

  getDisplayServicosExpr(item) {
    return item ? item.cnae : "";
  };

  render() {

    if (this.state._uiCarregando || this.state.infoContribuinte.msgBloqueioEmissao != "") {
      return (
        <div className="dx-card card-copiar-nfse content-block responsive-paddings" style={{ textAlign: "center" }}>
          {this.state._uiCarregando ? <LoadIndicator id="large-indicator" height={60} width={60} /> : null}
          <div className="label">{this.state._uiCarregando ? "Carregando" : this.state.infoContribuinte.msgBloqueioEmissao}</div>
        </div>
      );
    }


    return (
      <div className="">
        <div className={"content-block"}>
          <h2>Emissão de NFS-e</h2>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Informações Gerais</h5>
        </div>

        <div className="dx-card card-copiar-nfse content-block responsive-paddings">
          {/* <div>
            <h5 className="dx-theme-material-typography info">Copiar NFSe</h5>
          </div> */}
          <div className="form-copiar-nfse">
            <Form
              id="form-copiar-nfse"
              formData={this.state.numNfse}
              colCount={4}
              labelLocation={"top"}
              ref={this.formNumNfse}
            >
              <GroupItem colCount={5} colSpan={4}>
                <SimpleItem dataField="dataServico" editorType="dxDateBox" editorOptions={{ useMaskBehavior: true, readOnly: true }}>
                  <Label text="Competência" />
                </SimpleItem>

                <SimpleItem dataField="numeroRps" editorOptions={{ disabled: true }}>
                  <Label text="Número RPS" />
                </SimpleItem>

                <SimpleItem dataField="dataRps" editorType="dxDateBox" editorOptions={{ disabled: true }}>
                  <Label text="Data RPS" />
                </SimpleItem>

                <SimpleItem dataField="tipoEmissao" editorType={"dxSelectBox"} editorOptions={this.tipoEmissaoOptions}>
                  <Label text="Tipo Emissão" />
                </SimpleItem>

                <SimpleItem dataField="notaRef" editorType="dxNumberBox" editorOptions={this.notaSubstituidaOptions}>
                  <Label text="NFS-e Ref." />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Tomador</h5>
        </div>

        <div className="dx-card card-info-tomador content-block responsive-paddings">
          <div className="form-info-tomador">
            <Form
              id="form-info-tomador"
              formData={this.state.infoTomador}
              colCount={10}
              labelLocation={"top"}
              ref={this.frmTomador}
            >
              <SimpleItem
                colSpan={2}
                dataField="estrangeiro"
                editorType={"dxSelectBox"}
                editorOptions={this.estrangeiroOptions}
              >
                <Label text="Estrangeiro" />
              </SimpleItem>

              <SimpleItem colSpan={4} dataField="documento" editorType={"dxTextBox"} editorOptions={{ onValueChanged: this.onDocumentoTomadorAlterado }}>
                <Label text={this.state.infoTomador.estrangeiro ? "Doc. Estrangeiro" : "CPF/CNPJ"} />
              </SimpleItem>

              <SimpleItem render={this.renderLoaderTomador} />

              <EmptyItem colSpan={3} />

              <SimpleItem colSpan={6} dataField="nome">
                <Label text="Nome/RazãoSocial" />
              </SimpleItem>

              <SimpleItem dataField="im" colSpan={2}>
                <Label text="Inscrição Municipal" />
              </SimpleItem>

              <SimpleItem dataField="ie" colSpan={2}>
                <Label text="Inscrição Estadual" />
              </SimpleItem>

              <SimpleItem
                colSpan={this.state.infoTomador.estrangeiro ? 6 : 8}
                dataField="logradouro"
              >
                <Label text="Logradouro" />
              </SimpleItem>

              <SimpleItem dataField="numero" colSpan={2}>
                <Label text="Número" />
              </SimpleItem>

              <SimpleItem dataField="complemento" colSpan={2}>
                <Label text="Complemento" />
              </SimpleItem>

              <SimpleItem dataField="bairro" colSpan={3}>
                <Label text="Bairro" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="municipioId"
                editorType={"dxLookup"}
                editorOptions={{ ...this.state._uiOpcoesMunicipioTomador }}
                visible={this.state.infoTomador.estrangeiro === false ? true : false}
              >
                <Label text="Município" />
              </SimpleItem>

              <SimpleItem
                colSpan={3}
                dataField="estrangeiroCidade"
                visible={this.state.infoTomador.estrangeiro === true ? true : false}
              >
                <Label text="Cidade" />
              </SimpleItem>

              {
                <SimpleItem
                  colSpan={1}
                  dataField="estrangeiroUf"
                  visible={this.state.infoTomador.estrangeiro === true ? true : false}
                >
                  <Label text="UF" />
                </SimpleItem>
              }

              <SimpleItem
                colSpan={3}
                editorType="dxLookup"
                dataField="paisId"
                visible={this.state.infoTomador.estrangeiro === true ? true : false}
                editorOptions={this.state._uiOpcoesPaisesEstrangeiro}
              >
                <Label text="País" />
              </SimpleItem>

              <SimpleItem
                colSpan={this.state.infoTomador.estrangeiro ? 3 : 2}
                dataField="cep"
                editorType="dxTextBox"
                editorOptions={{
                  mask: this.state.infoTomador.estrangeiro ? "" : "00000-000",
                }}
              >
                <Label text="CEP" />
              </SimpleItem>

              <SimpleItem
                colSpan={this.state.infoTomador.estrangeiro ? 7 : 10}
                dataField="email"
              >
                <Label text="E-mail" />
              </SimpleItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Descrição do Serviço</h5>
        </div>

        <div className="dx-card card-info-historico content-block responsive-paddings">
          <div className="info-historico">
            <Form
              id="form-info-historico"
              formData={this.state.infoHistorico}
              colCount={6}
              labelLocation={"top"}
            >
              <GroupItem colSpan={6} colCount={1}>
                <SimpleItem
                  dataField="descricaoServico"
                  editorType="dxTextArea"
                  editorOptions={{ height: 130 }}
                >
                  <Label text="Descrição do Serviço" />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Apuração do ISS</h5>
        </div>

        <div className="dx-card card-info-servico content-block responsive-paddings">
          <div className="info-servico">
            <Form
              id="form-info-servico"
              formData={this.state.infoServico}
              colCount={4}
              labelLocation="top"
              ref={this.refCampoAliquota}
            >
              <GroupItem colSpan={4} colCount={4}>

                <SimpleItem
                  colSpan={4}
                  dataField="servicoId"
                  editorType="dxLookup"
                  editorOptions={this.state._uiServicosOptions}
                >
                  <Label text="Serviço" />
                </SimpleItem>

                <SimpleItem
                  colSpan={2}
                  dataField="operacao"
                  editorType="dxSelectBox"
                  editorOptions={this.state._uiOperacoesOptions}
                >
                  <Label text="Operação" />
                </SimpleItem>

                <SimpleItem
                  colSpan={2}
                  editorType="dxLookup"
                  dataField="localDePrestacao"
                  editorOptions={this.state._uiOpcoesLocalDePrestacao} >
                  <Label text="Local do Serviço" />
                </SimpleItem>

                <SimpleItem
                  colSpan={1}
                  editorType="dxLookup"
                  dataField="pais"
                  editorOptions={this.state._uiOpcoesPaises}
                >
                  <Label text="País" />
                </SimpleItem>

                <SimpleItem
                  colSpan={1}
                  dataField="aliquota"
                  editorType="dxNumberBox"
                  editorOptions={this.state.infoServico._uiOpcoesAliquota}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Alíquota (%)" />
                </SimpleItem>


                <SimpleItem
                  dataField="valorNota"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Valor da Nota (R$)" />
                </SimpleItem>

                <SimpleItem
                  colSpan={1}
                  dataField="issRetido"
                  editorType="dxSelectBox"
                  editorOptions={this.retidaOptions}
                >
                  <Label text="ISS Retido?" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorDeducoes"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Deduções (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="descontoCondicionado"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Desc. Condic. (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="descontoIncondicionado"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Desc. Incondic. (R$)" />
                </SimpleItem>

                <SimpleItem dataField="processo" colSpan={2}>
                  <Label text="Processo" />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Outros Impostos e Retenções</h5>
        </div>

        <div className="dx-card card-impostos content-block responsive-paddings">
          <div className="form-impostos">
            <Form
              id="form-info-impostos"
              formData={this.state.infoImpostos}
              colCount={6}
              labelLocation={"top"}
            >
              <GroupItem colSpan={6} colCount={6}>
                <SimpleItem
                  dataField="valorInss"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="INSS (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorIrrf"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="IRFF (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorCsll"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="CSLL (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorPis"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="PIS (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="valorCofins"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="COFINS (R$)" />
                </SimpleItem>

                <SimpleItem
                  dataField="outrasRetencoes"
                  editorType="dxNumberBox"
                  editorOptions={formatoNumerico}
                  cssClass={"txtEmissaoNotaNumerico"}
                >
                  <Label text="Outras (R$)" />
                </SimpleItem>
              </GroupItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Intermediário dos Serviços</h5>
        </div>

        <div className="dx-card card-info-intermediario content-block responsive-paddings">
          <div className="info-intermediario">
            <Form
              id="form-info-intermediario"
              formData={this.state.infoIntermediario}
              colCount={10}
              labelLocation={"top"}
            >
              <SimpleItem
                colSpan={10}
                dataField="intermediario"
                editorType={"dxCheckBox"}
                editorOptions={this.intermediarioOptions}
                item
              >
                <Label text="Serviços foram intermediados" location={"left"} />
              </SimpleItem>

              <SimpleItem
                colSpan={2}
                dataField="cpfCnpj"
                visible={this.state.infoIntermediario.intermediario}
              >
                <Label text="CPF/CNPJ" />
              </SimpleItem>

              <SimpleItem
                colSpan={6}
                dataField="nome"
                visible={this.state.infoIntermediario.intermediario}
              >
                <Label text="Nome" />
              </SimpleItem>

              <SimpleItem
                dataField="im"
                colSpan={2}
                visible={this.state.infoIntermediario.intermediario}
              >
                <Label text="Inscrição Municipal" />
              </SimpleItem>

              <SimpleItem
                colSpan={5}
                dataField="municipioId"
                editorType={"dxLookup"}
                editorOptions={this.state._uiOpcoesMunicipioTomador}
                visible={this.state.infoIntermediario.intermediario}
              >
                <Label text="Código Município" />
              </SimpleItem>

              <SimpleItem
                colSpan={5}
                dataField="responsavelRetencao"
                editorType={"dxSelectBox"}
                editorOptions={this.opcoesResponsavel}
                visible={this.state.infoIntermediario.intermediario === true ? true : false}
              >
                <Label text="Responsável Retenção" />
              </SimpleItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Construção Civil</h5>
        </div>

        <div className="dx-card card-info-const-civil content-block responsive-paddings">
          <div className="info-const-civil">
            <Form
              id="form-info-const-civil"
              formData={this.state.infoConstCivil}
              colCount={2}
              labelLocation={"top"}
            >
              <SimpleItem colSpan={1} dataField="codigo">
                <Label text="Código Obra" />
              </SimpleItem>

              <SimpleItem colSpan={1} dataField="art">
                <Label text="ART" />
              </SimpleItem>
            </Form>
          </div>
        </div>

        <div
          style={{ marginLeft: "40px", marginTop: "30px", marginBottom: "-20px" }}
        >
          <h5>Observação</h5>
        </div>

        <div className="dx-card card-obs content-block responsive-paddings">
          <div className="form-obs">
            <Form
              id="form-info-obs"
              formData={this.state.infoObs}
              colCount={6}
              labelLocation={"top"}
            >
              <GroupItem colCount={1} colSpan={6}>
                <GroupItem colSpan={6} colCount={1}>
                  <SimpleItem
                    dataField="obs"
                    editorType="dxTextArea"
                    editorOptions={{ height: 100 }}
                  >
                    <Label text="Observação" />
                  </SimpleItem>
                </GroupItem>
              </GroupItem>
            </Form>
          </div>
        </div>

        <div className="dx-card content-block responsive-paddings" style={{ textAlign: "left" }}>
          <div>
            <Button
              width={150}
              className="btn-emissao-nf "
              text="Emitir Nota"
              stylingMode="contained"
              onClick={this.emitirNota}
            />

            <Button
              className="btn-emissao-nf"
              text="Cancelar"
              type="danger"
              stylingMode="contained"
              onClick={this.cancelar}
            />
          </div>
        </div>

        <Popup
          visible={this.state.popupPreview}
          onHiding={this.ocultarPopupPreview}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Confirmar emissão da NFS-e?"
          width={"70%"}
          height={550}
        >
          <ScrollView>
            <Form
              formData={this.state.previewData}
              colCount={5}
              labelLocation={"top"}
            >

              <SimpleItem dataField="operacao"
                editorOptions={{ readOnly: true }}>
                <Label text="OPERAÇÃO" />
              </SimpleItem>

              <EmptyItem colSpan={3} />

              <SimpleItem dataField="dataServico"
                editorType="dxDateBox" editorOptions={{ useMaskBehavior: true, readOnly: true }}>
                <Label text="COMPETÊNCIA" />
              </SimpleItem>

              <SimpleItem colSpan={5} dataField="tomador" editorOptions={{ readOnly: true }}>
                <Label text="TOMADOR" />
              </SimpleItem>

              <SimpleItem colSpan={5} dataField="tomadorEndereco" editorOptions={{ readOnly: true }}>
                <Label text="ENDEREÇO DO TOMADOR" />
              </SimpleItem>

              <SimpleItem colSpan={5} dataField="servico" editorOptions={{ readOnly: true }}>
                <Label text="SERVIÇO" />
              </SimpleItem>

              <GroupItem colSpan={5} colCount={2}>

                <SimpleItem dataField="localServico" editorOptions={{ readOnly: true }} style={{ borderStyle: "none" }}>
                  <Label text="LOCAL DE PRESTAÇÃO" />
                </SimpleItem>

                <SimpleItem dataField="municipioIss" editorOptions={{ readOnly: true }}>
                  <Label text="MUNICÍPIO ISS" />
                </SimpleItem>

              </GroupItem>

              <SimpleItem dataField="issRetido" editorOptions={{ readOnly: true }}>
                <Label text="ISS RETIDO" />
              </SimpleItem>

              <SimpleItem dataField="valorNota"
                editorType={"dxNumberBox"}
                editorOptions={{ readOnly: true, format: "#,##0.00" }}
                cssClass={"txtEmissaoNotaNumerico"}>
                <Label text="VR. NOTA (R$)" />
              </SimpleItem>

              <SimpleItem dataField="baseCalculo"
                editorType={"dxNumberBox"}
                editorOptions={{ readOnly: true, format: "#,##0.00" }}
                cssClass={"txtEmissaoNotaNumerico"}>
                <Label text="BASE CÁLCULO (R$)" />
              </SimpleItem>

              <SimpleItem dataField="aliquotaIss"
                editorType={"dxNumberBox"}
                editorOptions={{ readOnly: true, format: "#,##0.00" }}
                cssClass={"txtEmissaoNotaNumerico"}>
                <Label text="ALIQUOTA ISS (%)" />
              </SimpleItem>

              <SimpleItem dataField="valorIss"
                editorType={"dxNumberBox"}
                editorOptions={{ readOnly: true, format: "#,##0.00" }}
                cssClass={"txtEmissaoNotaNumerico"}>
                <Label text="VALOR ISS (%)" />
              </SimpleItem>
            </Form>
          </ScrollView>

          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="after"
            options={{ text: "Confirmar", type: "success", width: 400, onClick: this.emitirNotaPopup }}>
          </ToolbarItem>

          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="after"
            options={{ text: "Cancelar", width: 150, onClick: this.ocultarPopupPreview }}>
          </ToolbarItem>
        </Popup>


        <Popup
          visible={this.state.popupErrosVisivel}
          onHiding={this.ocultarPopupErros}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          title="Erros"
        >
          <ScrollView>
            <ul>
              {this.state._uiErros.map(y => <li>{y.descricao}</li>)}
            </ul>
          </ScrollView>

          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{ text: "Fechar", width: 150, onClick: () => this.setState({ popupErrosVisivel: false }) }} />

        </Popup>
      </div >
    );
  };
}