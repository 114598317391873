import React from "react";
import notify from "devextreme/ui/notify";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import "devextreme-react/text-area";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import sysCodigoDescricao from "../../components/sysCodigoDescricao";
import ServicosIssBox from "../ServicosIssBox";
import { Button, ScrollView } from "devextreme-react";
import UserInfo from "../../UserInfo";
import apiAxios from "../../service/api";
import { criarServicosIssDs } from "../../DataSources";

const template = {
  id: undefined,
  prefeituraId: UserInfo.getPrefeitura(),
  cpbsId: UserInfo.getCpbsId(),
  nome: undefined,
  codigo: undefined,
  codigoCosif: undefined,
  contaSuperior: undefined,
  servicoId: undefined,
  stContaCosif: undefined,
  popupVisivel: false,
  start_plano: undefined,
  mostrarBoleanoContaCosif: false,
  mostrarCodigoCosif: false,
  mostrarServico: false,
  tipoEscrituracao: "NENHUMA",
  popupVisivel: false,
};

export default class PlanoContasForm extends React.Component {
  constructor(props) {
    super(props);
    this.ok = this.ok.bind(this);
    this.cancel = this.cancel.bind(this);
    this.state = { ...template };
    this.servicosIssDs = criarServicosIssDs();
    this.setServicoId = this.setServicoId.bind(this);
    this.atualizarNomeServico = this.atualizarNomeServico.bind(this);
    this.onServicoSelecionado = this.onServicoSelecionado.bind(this);
    this.setNomeServicosLCPErro = this.setNomeServicosErro.bind(this);
    this.pesquisarServico = this.pesquisarServico.bind(this);
    this.servicosIssRef = React.createRef();
    this.renderServicos = sysCodigoDescricao(
      () => this.state._uiSequencialServico,
      this.setServicoId,
      () => this.state._uiNomeServico,
      this.pesquisarServico
    );

    this.renderFields = this.renderFields.bind(this);
  }

  async componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      apiAxios
        .get(`/contas_escrituracao/${this.props.match.params.id}`)
        .then((response) => {
          if (response == undefined) {
            notify("Registro não encontrado.", "error");
            this.props.history.push("/plano-contas");
          }
          this.setState({
            ...response.data,
            _uiSequencialServico: response.data.servico.lcMunicipal,
            _uiNomeServico: response.data.servico.descricao
          });
        })
        .catch((erro) => {
          notify(erro, "error");
          this.props.history.goBack();
        });
    } else {
      try {
        const response = await apiAxios.post(
          `/contas_escrituracao/start_plano_contas/${UserInfo.getCpbsId()}`
        );
        if (response.status === 200) {
          this.setState({ ...template, ...response.data, start_plano: true });
        } else {
          this.setState({ ...template });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  setServicoId(data) {
    this.setState({ _uiSequencialServico: data.value });
    this.atualizarNomeServico();
  }

  pesquisarServico() {
    this.setState({ popupVisivel: true });
  }

  atualizarNomeServico() {
    this.servicosIssDs
      .load({ filter: [["lcMunicipal", "=", this.state._uiSequencialServico]] })
      .then((result) => {
        if (result.length !== 0) {
          this.setState({
            _uiNomeServico: result[0].descricao,
            servicoId: result[0].id,
          });
        } else {
          this.setState({ _uiNomeServico: "" });
        }
      })
      .catch((_) => this.setNomeServicosErro());
  }

  setNomeServicosErro() {
    this.setState({ _uiNomeServico: "<<ERRO>>" });
  }

  onServicoSelecionado(e) {
    this.setState({
      _uiSequencialServico: e.row.data.lcMunicipal,
      popupVisivel: false,
    });
    this.atualizarNomeServico();
  }

  renderFields() {
    if (
      this.state.start_plano !== undefined ||
      this.props.match.params.id !== undefined
    ) {
      return (
        <form>
          <Form formData={this.state} colCount={2} labelLocation="top">
            <GroupItem caption="NOVO PLANO DE CONTAS" colSpan={2} colCount={4}>
              <SimpleItem dataField={"nome"} colSpan={3}>
                <Label text="Nome" />
              </SimpleItem>
              <SimpleItem dataField={"codigo"} colSpan={1}>
                <Label text="Código" />
              </SimpleItem>
              <SimpleItem
                colSpan={4}
                component={this.renderServicos}
                visible={
                  this.state.mostrarServico ||
                  (this.state.servicoId !== undefined &&
                    this.state.servicoId !== null)
                    ? true
                    : false
                }
              >
                <Label text="Serviço" />
              </SimpleItem>
              <SimpleItem dataField={"contaSuperior"} colSpan={1}>
                <Label text="Conta Pai" />
              </SimpleItem>
              <SimpleItem
                dataField={"codigoCosif"}
                colSpan={1}
                visible={
                  this.state.mostrarCodigoCosif ||
                  (this.state.codigoCosif !== undefined &&
                    this.state.codigoCosif !== null)
                    ? true
                    : false
                }
              >
                <Label text="Código COSIF" />
              </SimpleItem>
              <SimpleItem
                dataField={"stContaCosif"}
                editorType="dxCheckBox"
                visible={
                  this.state.mostrarBoleanoContaCosif ||
                  (this.state.stContaCosif !== undefined &&
                    this.state.stContaCosif !== null)
                    ? true
                    : false
                }
                colSpan={1}
              >
                <Label text="Conta COSIF?" />
              </SimpleItem>
            </GroupItem>
          </Form>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <Button
                style={{ backgroundColor: "#419641", color: "#FFFFFF" }}
                width={150}
                text="Salvar"
                onClick={this.ok}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button text="Cancelar" onClick={this.cancel} width={150} />
            </div>
          </div>
        </form>
      );
    } else {
      return <h3>Carregando...</h3>;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          {this.renderFields()}
        </div>
        <Popup
          visible={this.state.popupVisivel}
          onHiding={() => this.setState({ popupVisivel: false })}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          width={"90%"}
          height={"90%"}
          title="Pesquisar Serviços"
        >
          <ScrollView showScrollbar={"always"}>
            <ServicosIssBox
              onSelecionado={this.onServicoSelecionado}
              ref={this.servicosIssRef}
            />
          </ScrollView>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Fechar",
              type: "default",
              width: 150,
              onClick: () => this.setState({ popupVisivel: false }),
            }}
          ></ToolbarItem>
        </Popup>
      </React.Fragment>
    );
  }

  ok() {
    const entidade = this.state;
    if (this.props.match.params.id === undefined) {
      apiAxios
        .post("/contas_escrituracao", entidade)
        .then((response) => {
          if (response.status === 200) {
            notify("Plano de conta criada com sucesso.", "success");
            this.props.history.goBack();
          }
        })
        .catch(function (error) {
          console.log(error);
          notify(error, "error", 500);
        });
    } else {
      apiAxios
        .put("/contas_escrituracao", entidade)
        .then((response) => {
          if (response.status === 200) {
            notify("Plano de contas editado com sucesso.", "success");
            this.props.history.goBack();
          }
        })
        .catch(function (error) {
          console.log(error);
          notify(error, "error", 500);
        });
    }
  }

  cancel() {
    notify("Operação cancelada", "warning");
    this.props.history.goBack();
  }
}
