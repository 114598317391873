import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage, DisplayDataPage, ProfilePage, PesquisarNotaPage, EmitirNotaPage,
  EncerrarSemMovimentoPage, EncerrarComMovimentoPage,
  ConfirmarSemMovimentoPage, ConfirmarComMovimentoPage, CadastrarPage, ServicosTomadosBox, ServicosTomadosForm,
  PlanoContasBox, PlanoContasForm
} from './pages';
import NotaEmitidaForm from './pages/nota-emitida/nota-emitida';
import DadosEscrituracaoBox from './pages/dados-escrituracao/dados-escrituracao-box';
import DadosEscrituracaoForm from './pages/dados-escrituracao/dados-escrituracao-form';
import ConsultarEscrituracaoBox from './pages/consultar-escrituracao/ConsultarEscrituracaoBox';

const routes = [
  {
    path: '/:prefeitura/display-data',
    component: DisplayDataPage
  },
  {
    path: '/:prefeitura/profile',
    component: ProfilePage
  },
  {
    path: '/:prefeitura/home',
    component: HomePage
  },
  {
    path: '/:prefeitura/pesquisar-nota',
    component: PesquisarNotaPage
  },
  {
    path: '/:prefeitura/emitir-nota',
    component: EmitirNotaPage
  },
  {
    path: '/:prefeitura/nota-emitida',
    component: NotaEmitidaForm
  },
  {
    path: '/:prefeitura/cadastrar',
    component: CadastrarPage
  },
  {
    path: '/:prefeitura/prestador-servico/encerrar-com-movimento',
    component: EncerrarComMovimentoPage
  },
  {
    path: '/:prefeitura/prestador-servico/encerrar-sem-movimento',
    component: EncerrarSemMovimentoPage
  },
  {
    path: '/:prefeitura/servicos-tomados/encerrar-com-movimento',
    component: EncerrarComMovimentoPage
  },
  {
    path: '/:prefeitura/servicos-tomados/encerrar-sem-movimento',
    component: EncerrarSemMovimentoPage
  },
  {
    path: '/:prefeitura/confirmar-sem-movimento',
    component: ConfirmarSemMovimentoPage
  },
  {
    path: '/:prefeitura/confirmar-com-movimento',
    component: ConfirmarComMovimentoPage
  },
  {
    path: '/:prefeitura/servicos-tomados',
    component: ServicosTomadosBox
  },
  {
    path: '/:prefeitura/servicos-tomados/novo',
    component: ServicosTomadosForm
  },
  {
    path: '/:prefeitura/servicos-tomados/editar/:id',
    component: ServicosTomadosForm
  },
  {
    path: '/:prefeitura/plano-contas/',
    component: PlanoContasBox
  },
  {
    path: "/:prefeitura/plano-contas/nova-conta",
    component: PlanoContasForm,
  },
  {
    path: "/:prefeitura/plano-contas/editar/:id",
    component: PlanoContasForm,
  },
  {
    path: "/:prefeitura/dados-escrituracao/",
    component: DadosEscrituracaoBox,
  },
  {
    path: "/:prefeitura/dados-escrituracao/novo",
    component: DadosEscrituracaoForm,
  },
  {
    path: "/:prefeitura/dados-escrituracao/editar/:id",
    component: DadosEscrituracaoForm,
  },
  {
    path: "/:prefeitura/consultar-escrituracao/",
    component: ConsultarEscrituracaoBox,
  },
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
