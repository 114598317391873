import { userInfo } from "os";
import api from "./service/api";

const KEY_USER_INFO = "User_Info_Nfse";
const KEY_TOKEN_NFSE = "Token_Nfse";
const KEY_CODIGO_PORTAL = "codigoPortal";

let _userInfo =
  localStorage.getItem(KEY_USER_INFO) !== null
    ? JSON.parse(localStorage.getItem(KEY_USER_INFO))
    : {
        login: undefined,
        nome: undefined,
        perfil: "",
        prefeituraId: undefined,
        usuarioId: undefined,
        prefeituraSelecionadaId: undefined,
        municipioId: undefined,
        cpbsId: undefined,
        contribuinteId: undefined,
      };

export default class UserInfo {
  static estaAutenticado() {
    /* this.controlarValidadeToken();

    const usuarioId = _userInfo == undefined ? undefined : _userInfo.usuarioId;
    return usuarioId != undefined; */

    return _userInfo.login != undefined;
  }

  static gerenciaPrefeitura() {
    this.controlarValidadeToken();

    if (_userInfo == undefined) {
      return false;
    }

    return _userInfo.prefeituraId == undefined;
  }

  static obterUserInfo() {
    return localStorage.getItem(KEY_USER_INFO) !== null
      ? JSON.parse(localStorage.getItem(KEY_USER_INFO))
      : null;
  }

  static setUsuario(e) {
    localStorage.setItem(KEY_USER_INFO, JSON.stringify(e));
    localStorage.setItem(KEY_TOKEN_NFSE, e.tokenStr);
    localStorage.setItem(KEY_CODIGO_PORTAL, e.codigoPortal);

    forcarUpdate();
  }

  static SelecionarPrefeitura(e) {
    this.controlarValidadeToken();

    if (this.gerenciaPrefeitura()) {
      _userInfo.prefeituraSelecionadaId = e;
      this.setUsuario(_userInfo);
    }
  }

  static controlarValidadeToken() {
    const dataCorrente = new Date();

    if (typeof _userInfo.validadeToken == "string") {
      _userInfo.validadeToken = new Date(_userInfo.validadeToken);
    }

    if (_userInfo.validadeToken < dataCorrente) {
      localStorage.removeItem(KEY_USER_INFO);
      localStorage.removeItem(KEY_TOKEN_NFSE);
      // window.location.reload();
    }
  }
  static getPrefeitura() {
    this.controlarValidadeToken();

    if (!UserInfo.gerenciaPrefeitura()) {
      return _userInfo.prefeituraId;
    }

    if (_userInfo.prefeituraSelecionadaId == undefined) {
      return 0;
    }

    return _userInfo.prefeituraSelecionadaId;
  }

  static async getPrefeituraCodigoPortal() {
    this.controlarValidadeToken();

    return _userInfo.codigoPortal;
  }

  static getMunicipioId() {
    this.controlarValidadeToken();

    return _userInfo.municipioId;
  }

  static possuiPerfil(perfis) {
    this.controlarValidadeToken();

    return perfis.indexOf(_userInfo.perfil) > -1;
  }

  static nomeUsuarioLogado() {
    this.controlarValidadeToken();
    return _userInfo.nome;
  }

  static getUsuarioId() {
    this.controlarValidadeToken();

    if (_userInfo.usuarioId === undefined) {
      return 0;
    }

    return _userInfo.usuarioId;
  }

  static sair() {
    localStorage.removeItem(KEY_TOKEN_NFSE);
    localStorage.removeItem(KEY_USER_INFO);
    localStorage.clear();
  }

  static obterToken() {
    return localStorage.getItem(KEY_TOKEN_NFSE);
  }

  static setToken(token) {
    localStorage.setItem(KEY_TOKEN_NFSE, token);
  }

  static getCpbsId() {
    this.controlarValidadeToken();

    var info = this.obterUserInfo();

    if (info == undefined) {
      return null;
    }

    return info.cpbsId;
  }
}

let _fnForceAppUpdateView;

export function setForcarUpdate(fnForceAppUpdateView) {
  _fnForceAppUpdateView = fnForceAppUpdateView;
}

export function forcarUpdate() {
  if (_fnForceAppUpdateView) {
    _fnForceAppUpdateView();
    console.log("Update forçado.");
  }
}
