import { createStore } from "devextreme-aspnet-data-nojquery";
import apiInfo from "./api-info";
import { DataSource } from "devextreme/data/data_source/data_source";
import UserInfo from "./UserInfo";
import ShowError from "./AppServices";
import { getToken } from "./auth";
//import { useHistory } from "react-router-dom";

//const history = useHistory();

function onBeforeSend(_, settings) {
  const token = getToken();
  settings.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  };
}

const criarPrefeituraDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.PREFEITURAS,
    insertUrl: apiInfo.API_URL.PREFEITURAS,
    updateUrl: apiInfo.API_URL.PREFEITURAS,
    deleteUrl: apiInfo.API_URL.PREFEITURAS,
    onBeforeSend,
    errorHandler: function (error) {
      console.log(error);
    },
  });
};

const criarAliquotaIssDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.ALIQUOTA_ISS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ALIQUOTA_ISS,
    updateUrl: apiInfo.API_URL.ALIQUOTA_ISS,
    deleteUrl: apiInfo.API_URL.ALIQUOTA_ISS,
    onBeforeSend,
  });
};

const criarCategoriaCpbsDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CATEGORIA_CPBS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CATEGORIA_CPBS,
    updateUrl: apiInfo.API_URL.CATEGORIA_CPBS,
    deleteUrl: apiInfo.API_URL.CATEGORIA_CPBS,
    onBeforeSend,
  });
};

const criarContadorDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CONTADOR + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CONTADOR,
    updateUrl: apiInfo.API_URL.CONTADOR,
    deleteUrl: apiInfo.API_URL.CONTADOR,
    onBeforeSend,
  });
};

const criarJurosDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.JUROS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.JUROS,
    updateUrl: apiInfo.API_URL.JUROS,
    deleteUrl: apiInfo.API_URL.JUROS,
    onBeforeSend,
  });
};

var criarTipoPessoaDs = function () {
  return new DataSource({
    store: {
      type: "array",
      key: "id",
      data: [
        { Id: "PF", Nome: "Pessoa Física" },
        { Id: "PJ", Nome: "Pessoa Jurídica" },
      ],
    },
  });
};

const criarMunicipioDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.MUNICIPIO,
    insertUrl: apiInfo.API_URL.MUNICIPIO,
    updateUrl: apiInfo.API_URL.MUNICIPIO,
    deleteUrl: apiInfo.API_URL.MUNICIPIO,
    onBeforeSend,
  });
};

function inserirOuAtualizar(dataSource, objOuId, obj) {
  let id = objOuId;
  let entidade = obj;
  if (obj == undefined) {
    id = objOuId.id;
    entidade = objOuId;
  }

  if (id === undefined || id === 0) {
    return dataSource.insert(entidade);
  }

  return dataSource.update(id, entidade);
}

const criarMultaDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.MULTAS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.MULTAS,
    updateUrl: apiInfo.API_URL.MULTAS,
    deleteUrl: apiInfo.API_URL.MULTAS,
    onBeforeSend,
  });
};

const criarCategoriaServicoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CATEGORIAS_SERVICOS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CATEGORIAS_SERVICOS,
    updateUrl: apiInfo.API_URL.CATEGORIAS_SERVICOS,
    deleteUrl: apiInfo.API_URL.CATEGORIAS_SERVICOS,
    onBeforeSend,
  });
};

const criarSituacaoCadastralDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.SITUACOES_CADASTRAIS_CPBS + "/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.SITUACOES_CADASTRAIS_CPBS,
    updateUrl: apiInfo.API_URL.SITUACOES_CADASTRAIS_CPBS,
    deleteUrl: apiInfo.API_URL.SITUACOES_CADASTRAIS_CPBS,
    onBeforeSend,
  });
};

const criarTipoRecolhimentoDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.TIPOS_RECOLHIMENTO_CPBS,
    insertUrl: apiInfo.API_URL.TIPOS_RECOLHIMENTO_CPBS,
    updateUrl: apiInfo.API_URL.TIPOS_RECOLHIMENTO_CPBS,
    deleteUrl: apiInfo.API_URL.TIPOS_RECOLHIMENTO_CPBS,
    onBeforeSend,
  });
};

const criarGrauInstrucaoCpbsDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.GRAUS_INSTRUCAO_CPBS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.GRAUS_INSTRUCAO_CPBS,
    updateUrl: apiInfo.API_URL.GRAUS_INSTRUCAO_CPBS,
    deleteUrl: apiInfo.API_URL.GRAUS_INSTRUCAO_CPBS,
    onBeforeSend,
  });
};

const criarNaturezaJuridicaDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.natu,
    insertUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_CPBS,
    updateUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_CPBS,
    deleteUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_CPBS,
    onBeforeSend,
  });
};

export const criarNaturezaJuridicaReceitaDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_RECEITA,
    insertUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_RECEITA,
    updateUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_RECEITA,
    deleteUrl: apiInfo.API_URL.NATUREZAS_JURIDICAS_RECEITA,
    onBeforeSend,
  });
};

const criarCnaeDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ATIVIDADES_ECONOMICAS,
    insertUrl: apiInfo.API_URL.ATIVIDADES_ECONOMICAS,
    updateUrl: apiInfo.API_URL.ATIVIDADES_ECONOMICAS,
    deleteUrl: apiInfo.API_URL.ATIVIDADES_ECONOMICAS,
    onBeforeSend,
  });
};

const criarBairroDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.BAIRROS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.BAIRROS,
    updateUrl: apiInfo.API_URL.BAIRROS,
    deleteUrl: apiInfo.API_URL.BAIRROS,
    onBeforeSend,
  });
};

const criarDistritoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.DISTRITOS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.DISTRITOS,
    updateUrl: apiInfo.API_URL.DISTRITOS,
    deleteUrl: apiInfo.API_URL.DISTRITOS,
    onBeforeSend,
  });
};

const criarFatorCorretivoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.FATORES_CORRETIVOS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.FATORES_CORRETIVOS,
    updateUrl: apiInfo.API_URL.FATORES_CORRETIVOS,
    deleteUrl: apiInfo.API_URL.FATORES_CORRETIVOS,

    onBeforeSend,
  });
};

const criarEscoreDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.ESCORES + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ESCORES,
    updateUrl: apiInfo.API_URL.ESCORES,
    deleteUrl: apiInfo.API_URL.ESCORES,
    onBeforeSend,
  });
};

const criarAliquotaIPTUDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.ALIQUOTAS_IPTU +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ALIQUOTAS_IPTU,
    updateUrl: apiInfo.API_URL.ALIQUOTAS_IPTU,
    deleteUrl: apiInfo.API_URL.ALIQUOTAS_IPTU,
    onBeforeSend,
  });
};

const criarLogradouroDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LOGRADOUROS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LOGRADOUROS,
    updateUrl: apiInfo.API_URL.LOGRADOUROS,
    deleteUrl: apiInfo.API_URL.LOGRADOUROS,
    onBeforeSend,
  });
};

const criarLoteamentoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LOTEAMENTOS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LOTEAMENTOS,
    updateUrl: apiInfo.API_URL.LOTEAMENTOS,
    deleteUrl: apiInfo.API_URL.LOTEAMENTOS,
    onBeforeSend,
  });
};

const criarColaboradorDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.COLABORADORES + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.COLABORADORES,
    updateUrl: apiInfo.API_URL.COLABORADORES,
    deleteUrl: apiInfo.API_URL.COLABORADORES,
    onBeforeSend,
  });
};

const criarRegimeTributarioDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.REGIMES_CPBS,
    insertUrl: apiInfo.API_URL.REGIMES_CPBS,
    updateUrl: apiInfo.API_URL.REGIMES_CPBS,
    deleteUrl: apiInfo.API_URL.REGIMES_CPBS,
    onBeforeSend,
  });
};

const criarFeriadosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.FERIADOS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.FERIADOS,
    updateUrl: apiInfo.API_URL.FERIADOS,
    deleteUrl: apiInfo.API_URL.FERIADOS,
    onBeforeSend,
  });
};

const criarServicosLCPDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.SERVICOSLCP,
    insertUrl: apiInfo.API_URL.SERVICOSLCP,
    updateUrl: apiInfo.API_URL.SERVICOSLCP,
    deleteUrl: apiInfo.API_URL.SERVICOSLCP,
    onBeforeSend,
  });
};

const criarCategoriasLCPDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.CATEGORIASLCP,
    insertUrl: apiInfo.API_URL.CATEGORIASLCP,
    updateUrl: apiInfo.API_URL.CATEGORIASLCP,
    deleteUrl: apiInfo.API_URL.CATEGORIASLCP,
    onBeforeSend,
  });
};

const criarNaturezaOperacaoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.NATUREZA_OPERACAO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.NATUREZA_OPERACAO,
    updateUrl: apiInfo.API_URL.NATUREZA_OPERACAO,
    deleteUrl: apiInfo.API_URL.NATUREZA_OPERACAO,
    onBeforeSend,
  });
};

const criarSerieNotaFiscalDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.SERIE_NOTA_FISCAL +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.SERIE_NOTA_FISCAL,
    updateUrl: apiInfo.API_URL.SERIE_NOTA_FISCAL,
    deleteUrl: apiInfo.API_URL.SERIE_NOTA_FISCAL,
    onBeforeSend,
  });
};

const criarRetencaoExternaDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.RETENCAO_EXTERNA +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.RETENCAO_EXTERNA,
    updateUrl: apiInfo.API_URL.RETENCAO_EXTERNA,
    deleteUrl: apiInfo.API_URL.RETENCAO_EXTERNA,
    onBeforeSend,
  });
};

const criarCadastrarLeisDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CADASTRAR_LEIS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CADASTRAR_LEIS,
    updateUrl: apiInfo.API_URL.CADASTRAR_LEIS,
    deleteUrl: apiInfo.API_URL.CADASTRAR_LEIS,
    onBeforeSend,
  });
};

const criarDocumentosRetencaoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.DOCUMENTOS_RETENCAO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.DOCUMENTOS_RETENCAO,
    updateUrl: apiInfo.API_URL.DOCUMENTOS_RETENCAO,
    deleteUrl: apiInfo.API_URL.DOCUMENTOS_RETENCAO,
    onBeforeSend,
  });
};

const criarCpbsDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.CPBS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CPBS,
    updateUrl: apiInfo.API_URL.CPBS,
    deleteUrl: apiInfo.API_URL.CPBS,
    onBeforeSend,
  });
};

const criarAgentesRecebedoresDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.AGENTES_RECEBEDORES +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.AGENTES_RECEBEDORES,
    updateUrl: apiInfo.API_URL.AGENTES_RECEBEDORES,
    deleteUrl: apiInfo.API_URL.AGENTES_RECEBEDORES,
    onBeforeSend,
  });
};

const criarTextoCdaDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TEXTO_CDA + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.TEXTO_CDA,
    updateUrl: apiInfo.API_URL.TEXTO_CDA,
    deleteUrl: apiInfo.API_URL.TEXTO_CDA,
    onBeforeSend,
  });
};

const criarReceitasDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.RECEITAS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.RECEITAS,
    updateUrl: apiInfo.API_URL.RECEITAS,
    deleteUrl: apiInfo.API_URL.RECEITAS,
    onBeforeSend,
  });
};

const criarTiposVeiculosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TIPOS_VEICULOS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.TIPOS_VEICULOS,
    updateUrl: apiInfo.API_URL.TIPOS_VEICULOS,
    deleteUrl: apiInfo.API_URL.TIPOS_VEICULOS,
    onBeforeSend,
  });
};

const criarUnidadeFiscal = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.UNIDADE_FISCAL +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.UNIDADE_FISCAL,
    updateUrl: apiInfo.API_URL.UNIDADE_FISCAL,
    deleteUrl: apiInfo.API_URL.UNIDADE_FISCAL,
    onBeforeSend,
  });
};

const criarVeiculosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.VEICULOS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.VEICULOS,
    updateUrl: apiInfo.API_URL.VEICULOS,
    deleteUrl: apiInfo.API_URL.VEICULOS,
    onBeforeSend,
  });
};

const criarSituacaoDividaDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.SITUACAO_DIVIDA +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.SITUACAO_DIVIDA,
    updateUrl: apiInfo.API_URL.SITUACAO_DIVIDA,
    deleteUrl: apiInfo.API_URL.SITUACAO_DIVIDA,
    onBeforeSend,
  });
};

const criarTipoNotificacaoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TIPO_NOTIFICACAO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.TIPO_NOTIFICACAO,
    updateUrl: apiInfo.API_URL.TIPO_NOTIFICACAO,
    deleteUrl: apiInfo.API_URL.TIPO_NOTIFICACAO,
    onBeforeSend,
  });
};

const criarContasBancariasDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CONTAS_BANCARIAS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CONTAS_BANCARIAS,
    updateUrl: apiInfo.API_URL.CONTAS_BANCARIAS,
    deleteUrl: apiInfo.API_URL.CONTAS_BANCARIAS,
    onBeforeSend,
  });
};

const criarContribuinteDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CONTRIBUINTE + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CONTRIBUINTE,
    updateUrl: apiInfo.API_URL.CONTRIBUINTE,
    deleteUrl: apiInfo.API_URL.CONTRIBUINTE,
    onBeforeSend,
  });
};

const criarContaCosifDs = function () {
  
};

const criarParametrosRefisDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.PARAMETROS_REFIS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.PARAMETROS_REFIS,
    updateUrl: apiInfo.API_URL.PARAMETROS_REFIS,
    deleteUrl: apiInfo.API_URL.PARAMETROS_REFIS,
    onBeforeSend,
  });
};

const criarIndicePrecosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.INDICE_PRECOS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.INDICE_PRECOS,
    updateUrl: apiInfo.API_URL.INDICE_PRECOS,
    deleteUrl: apiInfo.API_URL.INDICE_PRECOS,
    onBeforeSend,
  });
};

const criarSegmentosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.SEGMENTOS + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.SEGMENTOS,
    updateUrl: apiInfo.API_URL.SEGMENTOS,
    deleteUrl: apiInfo.API_URL.SEGMENTOS,
    onBeforeSend,
  });
};

const criarTaxasDs = function (tipo) {
  const sufixoUrl = tipo == undefined ? "" : `/${tipo}`;

  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TAXAS +
      "/prefeitura/" +
      UserInfo.getPrefeitura() +
      sufixoUrl,
    insertUrl: apiInfo.API_URL.TAXAS,
    updateUrl: apiInfo.API_URL.TAXAS,
    deleteUrl: apiInfo.API_URL.TAXAS,
    onBeforeSend,
  });
};

const criarConfiguracaoCndDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CONFIGURACAO_CND +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CONFIGURACAO_CND,
    updateUrl: apiInfo.API_URL.CONFIGURACAO_CND,
    deleteUrl: apiInfo.API_URL.CONFIGURACAO_CND,
    onBeforeSend,
  });
};

const criarMotivoIsencaoIptuDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ISENCAO_IPTU + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ISENCAO_IPTU,
    updateUrl: apiInfo.API_URL.ISENCAO_IPTU,
    deleteUrl: apiInfo.API_URL.ISENCAO_IPTU,
    onBeforeSend,
  });
};

const criarImovelDs = function () {
  return createStore({
    key: "id",
    // loadUrl: `http://45.35.104.141:6060/api/imoveis/prefeitura/${UserInfo.getPrefeitura()}`,
    loadUrl: apiInfo.API_URL.IMOVEL + "prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.IMOVEL,
    updateUrl: apiInfo.API_URL.IMOVEL,
    deleteUrl: apiInfo.API_URL.IMOVEL,
    onInserted: (corpo) => {
      return corpo;
    },
    onBeforeSend,
  });
};

const criarImovelFatoresDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.IMOVEL_FATORES +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.IMOVEL_FATORES,
    updateUrl: apiInfo.API_URL.IMOVEL_FATORES,
    deleteUrl: apiInfo.API_URL.IMOVEL_FATORES,
    onBeforeSend,
  });
};

const criarImovelCalculoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.IMOVEL_CALCULO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.IMOVEL_CALCULO,
    updateUrl: apiInfo.API_URL.IMOVEL_CALCULO,
    deleteUrl: apiInfo.API_URL.IMOVEL_CALCULO,
    onBeforeSend,
  });
};

const criarDiatDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.DIAT + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.DIAT,
    updateUrl: apiInfo.API_URL.DIAT,
    deleteUrl: apiInfo.API_URL.DIAT,
    onBeforeSend,
  });
};

const criarNotificacaoDiatDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.NOTIFICACAO_DIAT +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.NOTIFICACAO_DIAT,
    updateUrl: apiInfo.API_URL.NOTIFICACAO_DIAT,
    deleteUrl: apiInfo.API_URL.NOTIFICACAO_DIAT,
    onBeforeSend,
  });
};

const criarEntregaNotificacaoDiatDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.ENTERGA_NOTIFICACAO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ENTERGA_NOTIFICACAO,
    updateUrl: apiInfo.API_URL.ENTERGA_NOTIFICACAO,
    deleteUrl: apiInfo.API_URL.ENTERGA_NOTIFICACAO,
    onBeforeSend,
  });
};

const criarCuboImoveisDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.CUBO_IMOVEIS + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CUBO_IMOVEIS,
    updateUrl: apiInfo.API_URL.CUBO_IMOVEIS,
    deleteUrl: apiInfo.API_URL.CUBO_IMOVEIS,
    onBeforeSend,
  });
};

const criarAlvaraVeiculoDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ALVARA_VEICULOS + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ALVARA_VEICULOS,
    updateUrl: apiInfo.API_URL.ALVARA_VEICULOS,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarLancamentoAlvaraConstrucaoDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ALVARA_CONSTRUCAO + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ALVARA_CONSTRUCAO,
    updateUrl: apiInfo.API_URL.ALVARA_CONSTRUCAO,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarAlvaraFuncionamentoESanitarioDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.ALVARA_FUNCIONAMENTO_E_SANITARIO +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.ALVARA_FUNCIONAMENTO_E_SANITARIO,
    updateUrl: apiInfo.API_URL.ALVARA_FUNCIONAMENTO_E_SANITARIO,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      console.log("error", response);
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

const criarDamDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.DAMS + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.DAMS,
    updateUrl: apiInfo.API_URL.DAMS,
    deleteUrl: apiInfo.API_URL.DAMS,
    onBeforeSend,
  });
};

const criarLancamentoDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTOS,
    updateUrl: apiInfo.API_URL.LANCAMENTOS,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

export const criarAlvaraDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ALVARAS + UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

export const criarProcessamentosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.PROCESSAMENTOS + "prefeitura/" + UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

const criarTipoUsoLogradouroPublicoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TIPO_USO_LOGRADOURO_PUBLICO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.TIPO_USO_LOGRADOURO_PUBLICO,
    updateUrl: apiInfo.API_URL.TIPO_USO_LOGRADOURO_PUBLICO,
    deleteUrl: apiInfo.API_URL.TIPO_USO_LOGRADOURO_PUBLICO,
    onBeforeSend,
  });
};

const criarParametroIptuDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.PARAMETROS_IPTU +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.PARAMETROS_IPTU,
    updateUrl: apiInfo.API_URL.PARAMETROS_IPTU,
    deleteUrl: apiInfo.API_URL.PARAMETROS_IPTU,
    onBeforeSend,
  });
};

const criarLancamentoIptuDs = function () {
  return createStore({
    key: "exercicio",
    insertUrl: apiInfo.API_URL.LANCAMENTO_IPTU,
    onBeforeSend,
  });
};

const criarCnaePrefeituraDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CNAE_PREFEITURA +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CNAE_PREFEITURA,
    updateUrl: apiInfo.API_URL.CNAE_PREFEITURA,
    deleteUrl: apiInfo.API_URL.CNAE_PREFEITURA,
    onBeforeSend,
  });
};
const criarLcp116Prefeitura = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LCP116_PREFEITURA +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LCP116_PREFEITURA,
    updateUrl: apiInfo.API_URL.LCP116_PREFEITURA,
    deleteUrl: apiInfo.API_URL.LCP116_PREFEITURA,
    onBeforeSend,
  });
};
const criarCategoriaLcp116Prefeitura = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CATEGORIA_LCP116_PREFEITURA +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CATEGORIA_LCP116_PREFEITURA,
    updateUrl: apiInfo.API_URL.CATEGORIA_LCP116_PREFEITURA,
    deleteUrl: apiInfo.API_URL.CATEGORIA_LCP116_PREFEITURA,
    onBeforeSend,
  });
};

const criarQualificacaoSociosDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.QUALIFICACAO_SOCIOS,
    insertUrl: apiInfo.API_URL.QUALIFICACAO_SOCIOS,
    updateUrl: apiInfo.API_URL.QUALIFICACAO_SOCIOS,
    deleteUrl: apiInfo.API_URL.QUALIFICACAO_SOCIOS,
    onBeforeSend,
  });
};

const criarPrecoPublicoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.PRECO_PUBLICO + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.PRECO_PUBLICO,
    updateUrl: apiInfo.API_URL.PRECO_PUBLICO,
    deleteUrl: apiInfo.API_URL.PRECO_PUBLICO,
    onBeforeSend,
  });
};

const criarPermissionariosDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.PERMISSIONARIOS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.PERMISSIONARIOS,
    updateUrl: apiInfo.API_URL.PERMISSIONARIOS,
    deleteUrl: apiInfo.API_URL.PERMISSIONARIOS,
    onBeforeSend,
  });
};

const criarResponsavelTecnicoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.RESPONSAVEL_TECNICO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.RESPONSAVEL_TECNICO,
    updateUrl: apiInfo.API_URL.RESPONSAVEL_TECNICO,
    deleteUrl: apiInfo.API_URL.RESPONSAVEL_TECNICO,
    onBeforeSend,
  });
};

const criarCndDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.CND + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CND,
    updateUrl: apiInfo.API_URL.CND,
    deleteUrl: apiInfo.API_URL.CND,
    onBeforeSend,
  });
};

const loadOrigens = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ORIGENS + UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

const criarCndAutomaticaDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.CND_AUTOMATICA +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.CND_AUTOMATICA,
    updateUrl: apiInfo.API_URL.CND_AUTOMATICA,
    deleteUrl: apiInfo.API_URL.CND_AUTOMATICA,
    onBeforeSend,
  });
};

const criarValorEdificadoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.VALOR_EDIFICADO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.VALOR_EDIFICADO,
    updateUrl: apiInfo.API_URL.VALOR_EDIFICADO,
    deleteUrl: apiInfo.API_URL.VALOR_EDIFICADO,

    onBeforeSend,
  });
};

const criarServicosIssDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.SERVICOS_ISS + "prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.SERVICOS_ISS,
    updateUrl: apiInfo.API_URL.SERVICOS_ISS,
    deleteUrl: apiInfo.API_URL.SERVICOS_ISS,
    onBeforeSend,
  });
};

export const opcoesFatoresDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.FATORES_CORRETIVOS +
      "/opcoes/" +
      UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

export const criarEntregadorDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.ENTREGADORES + UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

export const criarItbiDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.LANCAMENTO_ITBI + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTO_ITBI,
    updateUrl: apiInfo.API_URL.LANCAMENTO_ITBI,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};
export const criarUsuariosDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.USUARIOS,
    insertUrl: apiInfo.API_URL.USUARIOS,
    updateUrl: apiInfo.API_URL.USUARIOS,
    onBeforeSend,
  });
};

export const criarLancamentoHorarioEspecialDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LANCAMENTO_HORARIO_ESPECIAL + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTO_HORARIO_ESPECIAL,
    updateUrl: apiInfo.API_URL.LANCAMENTO_HORARIO_ESPECIAL,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarLancamentoPublicidadeDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LANCAMENTO_ALVARA_PUBLICIDADE + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTO_ALVARA_PUBLICIDADE,
    updateUrl: apiInfo.API_URL.LANCAMENTO_ALVARA_PUBLICIDADE,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarLancamentoTaxasDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.LANCAMENTO_TAXAS + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTO_TAXAS,
    updateUrl: apiInfo.API_URL.LANCAMENTO_TAXAS,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarLancamentoLogradouroPublicoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LANCAMENTO_USO_LOGRADOURO_PUBLICO +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTO_USO_LOGRADOURO_PUBLICO,
    updateUrl: apiInfo.API_URL.LANCAMENTO_USO_LOGRADOURO_PUBLICO,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarLancamentoAlvaraDiversoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.LANCAMENTO_ALVARA_DIVERSO + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.LANCAMENTO_ALVARA_DIVERSO,
    updateUrl: apiInfo.API_URL.LANCAMENTO_ALVARA_DIVERSO,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarTipoAlvaraConstrucaoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TIPO_ALVARA_CONSTRUCAO +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.TIPO_ALVARA_CONSTRUCAO,
    updateUrl: apiInfo.API_URL.TIPO_ALVARA_CONSTRUCAO,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
  });
};

export const criarFormulasIptuDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.FORMULAS_IPTU + "/prefeitura/" + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.FORMULAS_IPTU,
    updateUrl: apiInfo.API_URL.FORMULAS_IPTU,
    deleteUrl: apiInfo.API_URL.FORMULAS_IPTU,
    onBeforeSend,
  });
};

export const criarTipoAlvaraDiversoDs = function () {
  return createStore({
    key: "id",
    loadUrl:
      apiInfo.API_URL.TIPOS_ALVARAS_DIVERSOS +
      "/prefeitura/" +
      UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.TIPOS_ALVARAS_DIVERSOS,
    updateUrl: apiInfo.API_URL.TIPOS_ALVARAS_DIVERSOS,
    deleteUrl: apiInfo.API_URL.TIPOS_ALVARAS_DIVERSOS,
    onBeforeSend,
  });
};

export const criarLancamentoHabiteseDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.HABITESE + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.HABITESE,
    updateUrl: apiInfo.API_URL.HABITESE,
    deleteUrl: apiInfo.API_URL.LANCAMENTOS + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarNotaFiscalAvulsaDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.NOTA_FISCAL_AVULSA + UserInfo.getPrefeitura(),
    insertUrl: apiInfo.API_URL.NOTA_FISCAL_AVULSA,
    updateUrl: apiInfo.API_URL.NOTA_FISCAL_AVULSA,
    deleteUrl: apiInfo.API_URL.NOTA_FISCAL_AVULSA + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarPaisBacenDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.PAISES_BACEN,
    insertUrl: apiInfo.API_URL.PAISES_BACEN,
    updateUrl: apiInfo.API_URL.PAISES_BACEN,
    deleteUrl: apiInfo.API_URL.PAISES_BACEN,
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export const criarCdaDs = function () {
  return createStore({
    key: "id",
    loadUrl: apiInfo.API_URL.CDA + UserInfo.getPrefeitura(),
    deleteUrl: apiInfo.API_URL.CDA + UserInfo.getPrefeitura(),
    onBeforeSend,
    onAjaxError: function (response) {
      const result = response.xhr.response;
      const error = JSON.parse(result);
      if (error !== undefined) {
        ShowError(error);
      } else {
        ShowError(response.error);
      }
    },
  });
};

export {
  inserirOuAtualizar,
  criarPrefeituraDs,
  criarAliquotaIssDs,
  criarCategoriaCpbsDs,
  criarJurosDs,
  criarContadorDs,
  criarTipoPessoaDs,
  criarMunicipioDs,
  criarMultaDs,
  criarCategoriaServicoDs,
  criarSituacaoCadastralDs,
  criarTipoRecolhimentoDs,
  criarGrauInstrucaoCpbsDs,
  criarNaturezaJuridicaDs,
  criarCnaeDs,
  criarBairroDs,
  criarDistritoDs,
  criarFatorCorretivoDs,
  criarEscoreDs,
  criarAliquotaIPTUDs,
  criarLogradouroDs,
  criarLoteamentoDs,
  criarColaboradorDs,
  criarRegimeTributarioDs,
  criarFeriadosDs,
  criarServicosLCPDs,
  criarCategoriasLCPDs,
  criarNaturezaOperacaoDs,
  criarSerieNotaFiscalDs,
  criarRetencaoExternaDs,
  criarCadastrarLeisDs,
  criarDocumentosRetencaoDs,
  criarCpbsDs,
  criarAgentesRecebedoresDs,
  criarTextoCdaDs,
  criarReceitasDs,
  criarTiposVeiculosDs,
  criarSituacaoDividaDs,
  criarTipoNotificacaoDs,
  criarVeiculosDs,
  criarContasBancariasDs,
  criarUnidadeFiscal,
  criarContribuinteDs,
  criarParametrosRefisDs,
  criarIndicePrecosDs,
  criarSegmentosDs,
  criarTaxasDs,
  criarConfiguracaoCndDs,
  criarMotivoIsencaoIptuDs,
  criarImovelDs,
  criarDiatDs,
  criarNotificacaoDiatDs,
  criarEntregaNotificacaoDiatDs,
  criarCuboImoveisDs,
  criarAlvaraVeiculoDs,
  criarDamDs,
  criarLancamentoDs,
  criarTipoUsoLogradouroPublicoDs,
  criarParametroIptuDs,
  criarLancamentoIptuDs,
  criarCnaePrefeituraDs,
  criarLcp116Prefeitura,
  criarCategoriaLcp116Prefeitura,
  criarQualificacaoSociosDs,
  criarPrecoPublicoDs,
  criarPermissionariosDs,
  criarResponsavelTecnicoDs,
  criarCndDs,
  criarCndAutomaticaDs,
  criarValorEdificadoDs,
  loadOrigens,
  //criarHabiteseDs,
  criarImovelFatoresDs,
  criarServicosIssDs,
  criarContaCosifDs
};
