import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./home.scss";
import Logo from "../../images/logotrimap-short.png";
import api from "../../service/api";

export default (props) => {
  const [prefeitura, setPrefeitura] = useState("");
  const params = useParams();

  useEffect(() => {
    async function verificaPrefeitura() {
      const response = await api("/prefeituras");

      const prefeituraPortal = response.data.data.find(
        (prefeitura) => params.prefeitura === prefeitura.codigoPortal
      );

      if (prefeituraPortal) {
        setPrefeitura(prefeituraPortal.cidadeComUF);
      }
    }
    verificaPrefeitura();
  }, [params]);

  return (
    <React.Fragment>
      <div className={"content-block dx-card responsive-paddings"}>
        <div className={"main-div-home"}>
          <img
            src={Logo}
            alt="TRIMAP"
            width={"100%"}
            style={{ maxWidth: "500px" }}
          />
          <h3>Portal de Emissão de Notas Fiscais Eletrônicas</h3>
          <br />
          <br />
          <div className="div-contatos">
            <p>
              Setor de Fiscalização e Arrecadação do Município de {prefeitura}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
