import apiAxios from "./api";
import notify from "devextreme/ui/notify";

// function base64toBlob(base64Data, contentType) {
//     contentType = contentType || '';
//     var sliceSize = 1024;
//     var byteCharacters = atob(base64Data);
//     var bytesLength = byteCharacters.length;
//     var slicesCount = Math.ceil(bytesLength / sliceSize);
//     var byteArrays = new Array(slicesCount);

//     for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
//         var begin = sliceIndex * sliceSize;
//         var end = Math.min(begin + sliceSize, bytesLength);

//         var bytes = new Array(end - begin);
//         for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
//             bytes[i] = byteCharacters[offset].charCodeAt(0);
//         }
//         byteArrays[sliceIndex] = new Uint8Array(bytes);
//     }
//     return new Blob(byteArrays, { type: contentType });
// }

export default (url, requestBody) => {
  return apiAxios
    .post(url, {
      ...requestBody,
      responseType: "blob",
    })
    .then((response) => {
      if (response.data.sucesso) {
        const bin = atob(response.data.report);
        const length = bin.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < length; i++) {
          uintArray[i] = bin.charCodeAt(i);
        }

        const fileBlob = new Blob([uintArray], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(fileBlob);

        window.open(fileURL, "_blank");
      } else {
        notify("Relatório não encontrado.", "error");
      }

      return response;
    })
    .catch((error) => {
      notify(error, "error");
      return Promise.reject(error);
    });
};

export const getReport = (url) => {
  return apiAxios
    .get(url)
    .then((response) => {
      if (response.data.sucesso) {
        const bin = atob(response.data.report);
        const length = bin.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < length; i++) {
          uintArray[i] = bin.charCodeAt(i);
        }

        const fileBlob = new Blob([uintArray], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(fileBlob);

        window.open(fileURL, "_blank");
      } else {
        notify("Relatório não encontrado.", "error");
        notify(response.data.erros[0], "error");
      }

      return response;
    })
    .catch((error) => {
      notify(error, "error");
      return Promise.reject(error);
    });
};

export const getXmlReport = (url) => {
  return apiAxios
    .get(url, {
      headers: {
        Accept: "application/xml",
      },
    })
    .then((response) => {
      if (response.data.sucesso) {
        const bin = atob(response.data.report);
        const length = bin.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < length; i++) {
          uintArray[i] = bin.charCodeAt(i);
        }

        const fileBlob = new Blob([uintArray], { type: "application/xml" });
        const fileURL = URL.createObjectURL(fileBlob);

        const element = document.createElement("a");
        element.href = fileURL;
        element.download = response.data.nomeArquivo;
        element.click();

      } else {
        notify("Relatório não encontrado.", "error");
        notify(response.data.erros[0], "error");
      }

      return response;
    })
    .catch((error) => {
      notify(error, "error");
      return Promise.reject(error);
    });
};

export const showReportDirect = (reportData) => {
  const bin = atob(reportData);
  const length = bin.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i++) {
    uintArray[i] = bin.charCodeAt(i);
  }

  const fileBlob = new Blob([uintArray], { type: "application/pdf" });
  const fileURL = URL.createObjectURL(fileBlob);

  window.open(fileURL, "_blank");
};

export const downloadArquivoBase64 = (nome, arquivoData) => {
  const bin = atob(arquivoData);
  const length = bin.length;
  const arrayBuffer = new ArrayBuffer(length);
  const uintArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < length; i++) {
    uintArray[i] = bin.charCodeAt(i);
  }

  const fileBlob = new File([uintArray], nome, { type: "application/zip" });
  const fileURL = URL.createObjectURL(fileBlob);

  window.open(fileURL, "_blank");
};
