import React from "react";
import notify from "devextreme/ui/notify";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  EmptyItem,
  ButtonItem,
} from "devextreme-react/form";
import "devextreme-react/text-area";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import sysCodigoDescricao from "../../components/sysCodigoDescricao";
import PlanoContasBox from "../plano-contas/plano-contas-box";
import { Button, ScrollView } from "devextreme-react";
import UserInfo from "../../UserInfo";
import apiAxios from "../../service/api";
import { criarPlanoContasDs } from "../../service/DataSources";
import { mesOptions } from "../../utils/date-utils";
import { DataGrid, Column, Paging, Pager } from "devextreme-react/data-grid";
import uiInfo from "../../ui-info";

const template = {
  id: undefined,
  prefeituraId: UserInfo.getPrefeitura(),
  cpbsId: UserInfo.getCpbsId(),
  mes: undefined,
  exercicio: undefined,
  contas: [],
  popupVisivel: false,
  carregando: false,
  popupNovaConta: false,
  popupMudarHeader: false,
  contaId: undefined,
  saldoInicial: 0,
  credito: 0,
  debito: 0,
  saldoFinal: 0,
  deducoes: 0,
  balancoId: undefined,
};

export default class DadosEscrituracaoForm extends React.Component {
  constructor(props) {
    super(props);
    this.ok = this.ok.bind(this);
    this.cancel = this.cancel.bind(this);
    this.state = { ...template };

    this.novaEscrituracao = this.novaEscrituracao.bind(this);
    this.novaConta = this.novaConta.bind(this);
    this.alterarHeader = this.alterarHeader.bind(this);
    this.editAccount = this.editAccount.bind(this);
    this.updateBalancete = this.updateBalancete.bind(this);

    this.contasDs = criarPlanoContasDs();
    this.setContaId = this.setContaId.bind(this);
    this.atualizarNomeConta = this.atualizarNomeConta.bind(this);
    this.onContaSelecionado = this.onContaSelecionado.bind(this);
    this.setNomeContasLCPErro = this.setNomeContasErro.bind(this);
    this.pesquisarConta = this.pesquisarConta.bind(this);
    this.contaRef = React.createRef();
    this.renderContas = sysCodigoDescricao(
      () => this.state.contaId,
      this.setContaId,
      () => this.state._uiNomeConta,
      this.pesquisarConta
    );
  }

  async componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      try {
        const responseContas = await apiAxios.get(
          `/contas_escrituracao/cpbs/${UserInfo.getCpbsId()}`
        );
        if (responseContas.status === 200) {
          this.setState({ contasDs: responseContas.data });
        }

        const responseEscrituracao = await apiAxios.get(
          `/iss_ifs/${this.props.match.params.id}`
        );
        if (
          responseEscrituracao.status === 200 &&
          responseEscrituracao.data !== undefined &&
          responseEscrituracao.data.data !== undefined &&
          responseEscrituracao.data.data[0] !== undefined
        ) {
          this.setState({
            ...responseEscrituracao.data.data[0],
          });
        }
      } catch (erro) {
        notify(erro, "error");
        notify("Registro não encontrado.", "error");
        this.props.history.goBack();
      }
    }
  }

  setContaId(data) {
    this.setState({ contaId: data.value.toString() });
    this.atualizarNomeConta();
  }

  pesquisarConta() {
    this.setState({ popupVisivel: true });
  }

  async atualizarNomeConta() {
    apiAxios
      .get(`/contas_escrituracao/${this.state.contaId}`)
      .then((result) => {
        if (result.status === 200 && result.data !== undefined) {
          this.setState({
            _uiNomeConta: "Conta n°: " + result.data.codigo,
          });
        } else {
          this.setState({ _uiNomeConta: "" });
        }
      })
      .catch((_) => this.setNomeContasErro());
  }

  setNomeContasErro() {
    this.setState({ _uiNomeConta: "<<ERRO>>" });
  }

  onContaSelecionado(e) {
    this.setState({
      contaId: e.row.data.id.toString(),
      popupVisivel: false,
    });
    this.atualizarNomeConta();
  }

  render() {
    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          <form
            style={{
              display: this.props.match.params.id !== undefined ? "none" : "",
            }}
          >
            <Form formData={this.state} colCount={2} labelLocation="top">
              <GroupItem caption="BALANÇO DE CONTAS" colSpan={2} colCount={4}>
                <SimpleItem dataField={"exercicio"} colSpan={1}>
                  <Label text="Exercício" />
                </SimpleItem>
                <SimpleItem
                  dataField={"mes"}
                  colSpan={1}
                  editorType="dxSelectBox"
                  editorOptions={mesOptions}
                >
                  <Label text="Mês" />
                </SimpleItem>
                <EmptyItem colSpan={1} />
                <ButtonItem
                  colSpan={1}
                  verticalAlignment={"bottom"}
                  cssClass={"success-button"}
                  buttonOptions={{
                    text: this.state.carregando
                      ? "Carregando"
                      : "Gerar escrituração",
                    disabled: this.state.carregando ? true : false,
                    width: "100%",
                    onClick: this.novaEscrituracao,
                  }}
                />
              </GroupItem>
            </Form>
            <br />
          </form>
          <form
            style={{
              display: this.props.match.params.id !== undefined ? "" : "none",
            }}
          >
            <Form formData={this.state} colCount={2} labelLocation="top">
              <GroupItem caption="ESCRITURAÇÃO" colSpan={2} colCount={4}>
                <SimpleItem
                  dataField={"exercicio"}
                  editorOptions={{ disabled: true }}
                  colSpan={1}
                >
                  <Label text="Exercício" />
                </SimpleItem>
                <SimpleItem
                  dataField={"mes"}
                  colSpan={1}
                  editorType="dxSelectBox"
                  editorOptions={{ ...mesOptions, disabled: true }}
                >
                  <Label text="Mês" />
                </SimpleItem>
                <EmptyItem colSpan={1} />
                <ButtonItem
                  colSpan={1}
                  verticalAlignment={"bottom"}
                  cssClass={"normal-button"}
                  buttonOptions={{
                    text: "Mudar o período",
                    width: "100%",
                    onClick: () => this.setState({ popupMudarHeader: true }),
                  }}
                />
                <EmptyItem colSpan={4} />
                <EmptyItem colSpan={4} />
                <EmptyItem colSpan={3} />
                <ButtonItem
                  colSpan={1}
                  verticalAlignment={"bottom"}
                  cssClass={"success-button"}
                  buttonOptions={{
                    text: "Adicionar nova conta",
                    disabled: this.state.carregando ? true : false,
                    width: "100%",
                    onClick: () => this.setState({ popupNovaConta: true }),
                  }}
                />
              </GroupItem>
            </Form>
            <DataGrid
              dataSource={this.state.contas}
              showBorders={true}
              rowAlternationEnabled={true}
              remoteOperations={true}
              columnAutoWidth={true}
              visible={this.props.match.params.id !== undefined ? true : false}
              wordWrapEnabled={window.innerWidth < 800}
              filterSyncEnabled={true}
            >
              <Column
                dataField={"id"}
                allowEditing={false}
                caption={"#"}
                visible={false}
              />
              <Column
                dataField={"prefeituraId"}
                allowEditing={false}
                caption={"Prefeitura"}
                visible={false}
              />
              <Column
                dataField={"escrituracaoId"}
                allowEditing={false}
                caption={"Escrituração"}
                visible={false}
              />
              <Column
                dataField={"cpbsId"}
                allowEditing={false}
                caption={"CPBS"}
                visible={false}
              />
              <Column dataField={"contaId"} caption={"Cod. Conta"} />
              <Column
                dataField={"saldoInicial"}
                caption={"Saldo Inicial"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"credito"}
                caption={"Crédito"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"debito"}
                caption={"Débito"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"saldoFinal"}
                caption={"Saldo Final"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"deducoes"}
                caption={"Deduções"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column dataField={"aliquota"} caption={"Alíquota"} />
              <Column
                dataField={"iss"}
                caption={"ISS"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                type="buttons"
                fixed={true}
                fixedPosition={"right"}
                buttons={[
                  {
                    icon: "edit",
                    onClick: this.editAccount,
                  },
                  {
                    icon: "trash",
                    onClick: (e) => console.log(e),
                  },
                ]}
              />
              <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
              <Pager
                showInfo={true}
                showNavigationButtons={true}
                showPageSizeSelector={true}
                allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
              />
            </DataGrid>
          </form>
          <div
            style={{
              marginTop: "100px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button text="Voltar" onClick={this.cancel} width={150} />
            </div>
          </div>
        </div>
        <Popup
          visible={this.state.popupVisivel}
          onHiding={() => this.setState({ popupVisivel: false })}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          width={"90%"}
          height={"90%"}
          title="Pesquisar Serviços"
        >
          <ScrollView showScrollbar={"always"}>
            <PlanoContasBox
              onSelecionado={this.onContaSelecionado}
              ref={this.contaRef}
            />
          </ScrollView>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Fechar",
              type: "default",
              width: 150,
              onClick: () => this.setState({ popupVisivel: false }),
            }}
          ></ToolbarItem>
        </Popup>
        <Popup
          visible={this.state.popupNovaConta}
          onHiding={() => this.setState({ popupNovaConta: false })}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          width={"80%"}
          height={"50%"}
          title={
            this.state.balancoId === undefined
              ? "NOVO BALANCETE"
              : "EDITAR BALANCETE"
          }
        >
          <ScrollView showScrollbar={"always"}>
            <Form
              style={{ marginTop: "6%" }}
              formData={this.state}
              colCount={2}
              labelLocation="top"
            >
              <GroupItem colSpan={2} colCount={5}>
                <SimpleItem render={this.renderContas} colSpan={5}>
                  <Label text="Conta" />
                </SimpleItem>
                <SimpleItem
                  dataField="saldoInicial"
                  editorType={"dxNumberBox"}
                  editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
                  cssClass={"txtNumerico"}
                  colSpan={1}
                >
                  <Label text="Saldo Inicial" />
                </SimpleItem>
                <SimpleItem
                  dataField="credito"
                  editorType={"dxNumberBox"}
                  editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
                  cssClass={"txtNumerico"}
                  colSpan={1}
                >
                  <Label text="Crédito" />
                </SimpleItem>
                <SimpleItem
                  dataField="debito"
                  editorType={"dxNumberBox"}
                  editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
                  cssClass={"txtNumerico"}
                  colSpan={1}
                >
                  <Label text="Débito" />
                </SimpleItem>
                <SimpleItem
                  dataField="saldoFinal"
                  editorType={"dxNumberBox"}
                  editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
                  cssClass={"txtNumerico"}
                  colSpan={1}
                >
                  <Label text="Saldo Final" />
                </SimpleItem>
                <SimpleItem
                  dataField="deducoes"
                  editorType={"dxNumberBox"}
                  editorOptions={{ format: uiInfo.formatoNumerico.n2 }}
                  cssClass={"txtNumerico"}
                  colSpan={1}
                >
                  <Label text="Deduções" />
                </SimpleItem>
              </GroupItem>
            </Form>
          </ScrollView>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Fechar",
              type: "danger",
              disabled: this.state.carregando ? true : false,
              width: 150,
              onClick: () => {
                if (this.state.balancoId === undefined) {
                  this.setState({ popupNovaConta: false });
                } else {
                  this.setState({
                    popupNovaConta: false,
                    contaId: undefined,
                    saldoInicial: 0,
                    credito: 0,
                    debito: 0,
                    saldoFinal: 0,
                    deducoes: 0,
                    _uiNomeConta: "",
                  });
                }
              },
            }}
          />
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text:
                this.state.balancoId === undefined ? "Confirmar" : "Atualizar",
              type: "default",
              disabled: this.state.carregando ? true : false,
              width: 150,
              onClick:
                this.state.balancoId === undefined
                  ? this.novaConta
                  : this.updateBalancete,
            }}
          />
        </Popup>
        <Popup
          visible={this.state.popupMudarHeader}
          onHiding={() => this.setState({ popupMudarHeader: false })}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          width={"40%"}
          height={"40%"}
          title="MUDAR PERÍODO"
        >
          <ScrollView showScrollbar={"always"}>
            <Form formData={this.state} colCount={2} labelLocation="top">
              <GroupItem colSpan={2} colCount={4}>
                <SimpleItem dataField={"exercicio"} colSpan={1}>
                  <Label text="Exercício" />
                </SimpleItem>
                <SimpleItem
                  dataField={"mes"}
                  colSpan={1}
                  editorType="dxSelectBox"
                  editorOptions={mesOptions}
                >
                  <Label text="Mês" />
                </SimpleItem>
              </GroupItem>
            </Form>
          </ScrollView>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Fechar",
              type: "danger",
              disabled: this.state.carregando ? true : false,
              width: 150,
              onClick: () => this.setState({ popupMudarHeader: false }),
            }}
          />
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Confirmar",
              type: "default",
              disabled: this.state.carregando ? true : false,
              width: 150,
              onClick: this.alterarHeader,
            }}
          />
        </Popup>
      </React.Fragment>
    );
  }

  async novaEscrituracao() {
    this.setState({ carregando: true });
    let data = {
      prefeituraId: this.state.prefeituraId,
      cpbsId: this.state.cpbsId,
      exercicio: this.state.exercicio,
      mes: this.state.mes,
    };
    let formData = new FormData();
    formData.append("values", JSON.stringify(data));
    apiAxios
      .post("/iss_ifs/nova_escrituracao", formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          let pathname = this.props.location.pathname || "";
          this.props.history.push(
            pathname.slice(0, pathname.lastIndexOf("novo")) +
              `editar/${response.data.id}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        notify("Não foi possível realizar a escrituração!", "error", 2000);
        this.setState({ carregando: false });
      });
  }

  async novaConta() {
    this.setState({ carregando: true });
    let data = {
      prefeituraId: this.state.prefeituraId,
      cpbsId: this.state.cpbsId,
      escrituracaoId: this.props.match.params.id,
      contaId: this.state.contaId,
      saldoInicial: this.state.saldoInicial,
      credito: this.state.credito,
      debito: this.state.debito,
      saldoFinal: this.state.saldoFinal,
      deducoes: this.state.deducoes,
    };
    let formData = new FormData();
    formData.append("values", JSON.stringify(data));
    apiAxios
      .post("/iss_ifs/adicionar_balanco", formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          notify("Conta adicionada com sucesso!", "success", 2000);

          const responseEscrituracao = await apiAxios.get(
            `/iss_ifs/${this.props.match.params.id}`
          );
          let contas = [];
          if (
            responseEscrituracao.status === 200 &&
            responseEscrituracao.data !== undefined &&
            responseEscrituracao.data.data !== undefined &&
            responseEscrituracao.data.data[0] !== undefined
          ) {
            contas = responseEscrituracao.data.data[0].contas;
          }

          this.setState({
            popupNovaConta: false,
            contaId: undefined,
            saldoInicial: 0,
            credito: 0,
            debito: 0,
            saldoFinal: 0,
            deducoes: 0,
            contas: contas,
            _uiNomeConta: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notify("Não foi possível cadastrar a conta!", "error", 2000);
      })
      .finally(() => {
        this.setState({ carregando: false });
      });
  }

  async alterarHeader() {
    this.setState({ carregando: true });
    let data = {
      prefeituraId: this.state.prefeituraId,
      cpbsId: this.state.cpbsId,
      exercicio: this.state.exercicio,
      mes: this.state.mes,
    };
    let formData = new FormData();
    formData.append("values", JSON.stringify(data));
    apiAxios
      .put(`/iss_ifs/alterar_header/${this.props.match.params.id}`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          notify("Período alterado com sucesso!", "success", 2000);
          this.setState({ popupMudarHeader: false });
        }
      })
      .catch((err) => {
        console.log(err);
        notify("Não foi possível modificar o período!", "error", 2000);
      })
      .finally(() => {
        this.setState({ carregando: false });
      });
  }

  editAccount(e) {
    let data = {
      contaId: e.row.data.contaId,
      saldoInicial: e.row.data.saldoInicial,
      credito: e.row.data.credito,
      debito: e.row.data.debito,
      saldoFinal: e.row.data.saldoFinal,
      deducoes: e.row.data.deducoes,
      balancoId: e.row.data.id,
    };
    this.setState({ ...data, popupNovaConta: true });
  }

  async updateBalancete() {
    this.setState({ carregando: true });
    let data = {
      contaId: this.state.contaId,
      saldoInicial: this.state.saldoInicial,
      credito: this.state.credito,
      debito: this.state.debito,
      saldoFinal: this.state.saldoFinal,
      deducoes: this.state.deducoes,
    };
    let formData = new FormData();
    formData.append("values", JSON.stringify(data));
    apiAxios
      .put(`/iss_ifs/editar_balanco/${this.state.balancoId}`, formData, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then(async (response) => {
        if (response.status === 200) {
          notify("Conta atualizada com sucesso!", "success", 2000);
          const responseEscrituracao = await apiAxios.get(
            `/iss_ifs/${this.props.match.params.id}`
          );
          let contas = [];
          if (
            responseEscrituracao.status === 200 &&
            responseEscrituracao.data !== undefined &&
            responseEscrituracao.data.data !== undefined &&
            responseEscrituracao.data.data[0] !== undefined
          ) {
            contas = responseEscrituracao.data.data[0].contas;
          }
          this.setState({
            popupNovaConta: false,
            contaId: undefined,
            saldoInicial: 0,
            credito: 0,
            debito: 0,
            contas: contas,
            saldoFinal: 0,
            deducoes: 0,
            _uiNomeConta: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        notify("Não foi possível atualizar a conta!", "error", 2000);
      })
      .finally(() => {
        this.setState({ carregando: false });
      });
  }

  ok() {
    const entidade = this.state;
    if (this.props.match.params.id === undefined) {
      apiAxios
        .post("/contas_escrituracao", entidade)
        .then((response) => {
          if (response.status === 200) {
            notify("Plano de conta criada com sucesso.", "success");
            this.props.history.goBack();
          }
        })
        .catch(function (error) {
          console.log(error);
          notify(error, "error", 500);
        });
    } else {
      apiAxios
        .put("/contas_escrituracao", entidade)
        .then((response) => {
          if (response.status === 200) {
            notify("Plano de contas editado com sucesso.", "success");
            this.props.history.goBack();
          }
        })
        .catch(function (error) {
          console.log(error);
          notify(error, "error", 500);
        });
    }
  }

  cancel() {
    notify("Operação cancelada", "warning");
    this.props.history.goBack();
  }
}
