import React, { Component } from "react";
import uiInfo from "../../ui-info";
import PaginaToolbar from "../../components/PaginaToolbar";
import api from "../../service/api";
import {
  DataGrid,
  Editing,
  Column,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import UserInfo from "../../UserInfo";

export default class DadosEscrituracaoBox extends Component {
  constructor(props) {
    super(props);
    this._gridInstance = React.createRef();
    this.onAtualizandoRow = this.onAtualizandoRow.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.state = { datasource: [] };
  }

  componentDidMount() {
    api
      .get(`/iss_ifs/${UserInfo.getPrefeitura()}/${UserInfo.getCpbsId()}`)
      .then((response) => {
        if (response.status === 200 && response.data !== undefined) {
          this.setState({ datasource: response.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onAtualizandoRow(e) {
    e.cancel = true;
    this.props.history.push("dados-escrituracao/editar/" + e.data.id);
  }

  onDeleteRow(id) {
    try {
      api.delete(`/contas_escrituracao/${id}`);
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={"content-block dx-card responsive-paddings"}>
          <h2>Escrituração de Contas</h2>
          <PaginaToolbar titulo="" novoCb={this.novoRegistro.bind(this)}>
            <DataGrid
              ref={(grid) => (this._gridInstance = grid)}
              dataSource={this.state.datasource}
              showBorders={true}
              rowAlternationEnabled={true}
              remoteOperations={true}
              columnAutoWidth={true}
              columnHidingEnabled={true}
              wordWrapEnabled={window.innerWidth < 800}
              filterSyncEnabled={true}
              onEditingStart={this.onAtualizandoRow}
              onRowRemoved={({ data }) => this.onDeleteRow(data.id)}
            >
              <Editing
                allowDeleting={true}
                allowUpdating={true}
                useIcons={true}
              />
              <Column
                dataField={"id"}
                allowEditing={false}
                caption={"#"}
                alignment="center"
                width={80}
              />
              <Column dataField={"exercicio"} caption={"Exercício"} />
              <Column dataField={"mes"} caption={"Mês"} />
              <Column dataField={"dataCadastro"} caption={"Cadastro"} />
              <Column dataField={"status"} caption={"Status"} />
              <Column dataField={"tipo"} caption={"Tipo"} />
              <Column dataField={"stVigente"} caption={"Vigente"} />
              <Column
                dataField={"totalSaldoInicial"}
                caption={"Saldo Inicial Total"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"totalCredito"}
                caption={"Crédito Total"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"totalDebito"}
                caption={"Débito Total"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"totalSaldoFinal"}
                caption={"Saldo Final Total"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"totalBC"}
                caption={"BC Total"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Column
                dataField={"totalIss"}
                caption={"ISS Total"}
                format={uiInfo.formatoNumerico.n2}
              />
              <Paging defaultPageSize={uiInfo.tamanhoPaginaPadrao} />
              <Pager
                showInfo={true}
                showNavigationButtons={true}
                showPageSizeSelector={true}
                allowedPageSizes={uiInfo.tamanhoPaginaOpcoes}
              />
            </DataGrid>
          </PaginaToolbar>
        </div>
      </React.Fragment>
    );
  }

  novoRegistro() {
    this.props.history.push("dados-escrituracao/novo");
  }
}
