import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./encerrar-com-movimento.scss";
import api from "../../service/api";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
} from "devextreme-react/form";
import { ScrollView } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { mesOptions, anoOptions } from "../../utils/date-utils";
import UserInfo from "../../UserInfo";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { Button } from "devextreme-react";

export default () => {

  var data = new Date();
  let anoCompetenciaDefault = data.getFullYear();
  let mesCompetenciaDefault = data.getMonth() - 1;

  if (mesCompetenciaDefault == -1) {
    mesCompetenciaDefault = 11;
    anoCompetenciaDefault = anoCompetenciaDefault - 1;
  }



  const location = useLocation();
  const params = useParams();
  console.log(params);
  const history = useHistory();
  const [estagioTela, setEstagioTela] = useState(1);
  const [encerramento, setEncerramento] = useState({ mesCompetencia: mesCompetenciaDefault + 1, anoCompetencia: anoCompetenciaDefault });
  const [modalidadeEMovimento] = useState(setDados());
  const [showPopup, setShowPopup] = useState(false);
  const [preview, setPreview] = useState({});
  const cpbsId = UserInfo.getCpbsId();
  const prefeitura = UserInfo.getPrefeitura();

  function setDados() {
    const splitedPathname = location.pathname.split("/");
    if (splitedPathname.length === 3) {
      let nomeTela = "";
      if (splitedPathname[1] === "servicos-tomados") {
        nomeTela = "Tomador";
      } else {
        nomeTela = "Prestador";
      }
      return {
        modalidade: splitedPathname[1],
        movimento: splitedPathname[2],
        tela: nomeTela,
      };
    }
    if (splitedPathname.length === 4) {
      let nomeTela = "";
      if (splitedPathname[2] === "servicos-tomados") {
        nomeTela = "Tomador";
      } else {
        nomeTela = "Prestador";
      }
      return {
        modalidade: splitedPathname[2],
        movimento: splitedPathname[3],
        tela: nomeTela,
      };
    }
  }

  async function showPreview() {

    let modalidade = -1;

    if (modalidadeEMovimento.modalidade == "servicos-tomados") {
      modalidade = 1;
    }
    else if (modalidadeEMovimento.modalidade == "prestador-servico") {
      modalidade = 0;
    }

    const request = {
      mes: encerramento.mesCompetencia,
      ano: encerramento.anoCompetencia,
      cpbsId: cpbsId,
      prefeituraId: prefeitura,
      modalidade: modalidade,
      semMovimento: false,
    };

    try {

      console.log("encerrar request", request);

      const response = await api.post("/escrituracao/encerramento_preview", request);

      console.log("response encerrar", response);

      if (response.status === 200) {

        setEstagioTela(2);
        setPreview(response.data);
        // setShowPopup(true);
      }
    } catch (err) {
      console.log("response error", err);
      notify(err, "error", 2000);
    }
  }

  async function confirmPopup() {
    try {
      const response = await api.post(
        "/escrituracao/encerramento_confirmacao",
        preview
      );
      if (response.status === 200) {
        notify("Escrituração encerrada com sucesso!", "success", 2000);
        history.push(`/${params.prefeitura}/certificados`)
      } else {
        notify("Não foi possível encerrar a escrituração!", "error", 2000);
      }
    } catch (err) {
      notify(err.response.data.msg, "error", 2000);
    }
  }

  const buttonOptions = {
    text: "Encerrar",
    type: "default",
    onClick: showPreview,
  };

  console.log("preview", preview);

  if (estagioTela == 1) {
    return (
      <div className="encerrar-escrituracao-container">
        <div className={"content-block"}>
          <h2>
            Escrituração de Notas
          </h2>
        </div>

        <div className={"content-block dx-card responsive-paddings"}>
          <Form
            id="encerrar-form"
            formData={encerramento}
            colCount={3}
            labelLocation="top"
          >
            <GroupItem colCount={3} colSpan={3}>
              <SimpleItem
                dataField="mesCompetencia"
                editorType="dxSelectBox"
                editorOptions={mesOptions}
              >
                <Label text="Mês Competência" />
              </SimpleItem>
              <SimpleItem
                dataField="anoCompetencia"
                editorType="dxSelectBox"
                editorOptions={anoOptions}
              >
                <Label text="Ano Competência" />
              </SimpleItem>
              <ButtonItem
                horizontalAlignment="left"
                verticalAlignment="bottom"
                buttonOptions={buttonOptions}
              />
            </GroupItem>
          </Form>
        </div>
      </div>
    );
  }


  if (estagioTela == 2) {
    return (
      <div className="encerrar-escrituracao-container">
        <div className={"content-block"}>
          <h2>
            Escrituração de Notas
          </h2>
        </div>
        <div className={"content-block dx-card responsive-paddings"}>

          <div style={{ textAlign: "center" }}>
            <h3 style={{ margin: 0, padding: 0 }}>CONFIRMAÇÃO DE ENCERRAMENTO</h3>
            <h4 style={{ margin: 0, padding: 0 }}> COMPETÊNCIA: {preview.competenciaStr}</h4>
            <h5 style={{ margin: 0, padding: 0 }}>MODALIDADE: {preview.modalidadeStr}</h5>
            <h5 style={{ margin: 0, padding: 0 }}>TIPO: {preview.semMovimento ? "SEM MOVIMENTO" : "COM MOVIMENTO"}</h5>


          </div>

          <Form
            formData={preview}
            colCount={2}
            labelLocation={"top"}
            readOnly={true}
          >

            <GroupItem colCount={2} colSpan={2} caption="ATIVIDADES">

              <SimpleItem dataField="qtdeNotasNormais"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 0 } }}>
                <Label text={"Qtde. Notas Normais"} />
              </SimpleItem>

              <SimpleItem dataField="totalNotasNormais"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"Total Notas Normais (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="qtdeNotasCanceladas"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 0 } }}>
                <Label text={"Qtde. Notas Canceladas (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="totalNotasCanceladas"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"Total Notas Canceladas (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="qtdeNotasSubstituidas"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 0 } }}>
                <Label text={"Qtde. Notas Substituídas"} />
              </SimpleItem>

              <SimpleItem dataField="totalNotasSubstituidas"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"Total Notas Substituídas (R$)"} />
              </SimpleItem>
            </GroupItem>

            <GroupItem colCount={4} colSpan={2} caption="APURAÇÃO DO ISS">

              <SimpleItem dataField="totalIss"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"Total ISS (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="totalIssPoprio"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"ISS Próprio (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="totalIssProprioRetido"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"ISS Próprio Retido (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="totalIssProprioNaoRetido"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"ISS Próprio Não Retido (R$)"} />
              </SimpleItem>

            </GroupItem>

            <GroupItem colCount={2} colSpan={2} caption="RECOLHIMENTO">

              <SimpleItem dataField="totalIssProprioRecolhidoAvulso"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"Recolhimento Avulso (R$)"} />
              </SimpleItem>

              <SimpleItem dataField="totalIssProprioARecolher"
                editorType={"dxNumberBox"}
                cssClass={"txtNumerico"}
                editorOptions={{ format: { type: "fixedPoint", precision: 2 } }}>
                <Label text={"ISS A Recolher (R$)"} />
              </SimpleItem>
            </GroupItem>
          </Form>
          <div style={{ color: "red", textAlign: "center", backgroundColor: "#F5DEB3", padding: 10, borderRadius: 5, fontWeight: "bold" }}>{preview.mensagemFinalizacao}</div>
          <div
            style={{
              display: "flex",
              marginTop: "25px",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <Button
                icon={"back"}
                text="Voltar"
                onClick={() => setEstagioTela(1)}
                width={150}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button
                text="Confirmar"
                type={"success"}
                onClick={confirmPopup}
              />
            </div>

          </div>
        </div>
      </div >
    );
  }
};
