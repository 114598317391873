import React from "react";
import Form, { SimpleItem, GroupItem, Label } from "devextreme-react/form";
import { DataGrid, Column, Lookup, Editing } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import "./servicos-tomados.scss";
import { anoOptions, mesOptions } from "../../utils/date-utils";
import { servicosTomadosDs, servicosDs } from "../../service/DataSources";

export default class ServicosTomadosForm extends React.Component {
  constructor(props) {
    super(props);
    this._gridInstance = React.createRef();
    this.servicosTomadosDs = servicosTomadosDs();
    this.servicosDs = servicosDs();
    this.state = {
      filtroCorrente: [],
      filtro: {},
    };

    this.limparFiltro = this.limparFiltro.bind(this);
    this.onAtualizandoRow = this.onAtualizandoRow.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handlePesquisa = this.handlePesquisa.bind(this);

    this.situacaoOptions = {
      editorType: "dxSelectBox",
      searchEnabled: false,
      placeholder: "Selecionar...",
      dataSource: [
        { id: 0, descricao: "Normal" },
        { id: 1, descricao: "Cancelado" },
      ],
      valueExpr: "id",
      displayExpr: "descricao",
    };
  }

  onAtualizandoRow(e) {
    e.cancel = true;
    this.props.history.push(`${this.props.location.pathname}/editar/` + e.data.id);
  }

  handleAdd() {
    this.props.history.push(`${this.props.location.pathname}/novo`);
  }

  limparFiltro(e) {
    this.setState({ filtro: {} });
    this.handlePesquisa();
  }

  handlePesquisa(e) {
    let filtro = this.state.filtro;

    let filtroCorrente = [];

    if (filtro.mesCompetencia !== undefined) {
      filtroCorrente.push([
        "mesCompetencia",
        "=",
        filtro.mesCompetencia,
      ]);
    }
    if (filtro.anoCompetencia !== undefined) {
      filtroCorrente.push(["anoCompetencia", "=", filtro.anoCompetencia]);
    }
    if (filtro.situacaoEscrituracao !== undefined) {
      filtroCorrente.push([
        "situacaoEscrituracao",
        "=",
        filtro.situacaoEscrituracao,
      ]);
    }

    this._gridInstance.instance.filter(filtroCorrente);
  }
  render() {
    return (
      <div className="pesquisar-nota-container">
        <div className={"content-block"}>
          <h2>Pesquisa de Serviços Tomados</h2>
        </div>

        <div className={"content-block dx-card responsive-paddings"}>
          <Form
            id="filtro-form"
            formData={this.state.filtro}
            colCount={6}
            labelLocation="top"
          >
            <GroupItem colCount={6} colSpan={6}>
              <SimpleItem
                dataField="mesCompetencia"
                editorType="dxLookup"
                editorOptions={mesOptions}
                colSpan={2}
              >
                <Label text="Mês" />
              </SimpleItem>

              <SimpleItem
                dataField="anoCompetencia"
                editorType="dxLookup"
                editorOptions={anoOptions}
                colSpan={2}
              >
                <Label text="Ano" />
              </SimpleItem>
              <SimpleItem
                dataField="situacaoEscrituracao"
                editorType="dxLookup"
                editorOptions={this.situacaoOptions}
                colSpan={2}
              >
                <Label text="Situação" />
              </SimpleItem>
            </GroupItem>
          </Form>
          <br />
          <div
            style={{
              display: "flex",
              marginTop: "25px",
              justifyContent: "center",
              alignContent: "center",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            <div className="save-create-button-div" style={{ display: "inline-block" }}>
              <Button
                width={150}
                text="Pesquisar"
                type="success"
                onClick={this.handlePesquisa}
              />
            </div>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button text="Limpar Filtro" onClick={this.limparFiltro} />
            </div>
            <div className="save-button-div" style={{ display: "inline-block", marginLeft: "10px" }}>
              <Button
                width={150}
                text="Adicionar"
                type="success"
                onClick={this.handleAdd}
              />
            </div>
          </div>
        </div>

        <div className="dx-card">
          <DataGrid
            ref={(grid) => (this._gridInstance = grid)}
            dataSource={this.servicosTomadosDs}
            showBorders={true}
            rowAlternationEnabled={true}
            remoteOperations={true}
            columnAutoWidth={true}
            columnHidingEnabled={true}
            wordWrapEnabled={window.innerWidth < 800}
            onEditingStart={this.onAtualizandoRow}
          >
            <Editing
              allowDeleting={false}
              allowUpdating={true}
              useIcons={true}
            />
            <Column
              type="buttons"
              fixed={true}
              fixedPosition={"left"}
              buttons={[
                "edit",
                {
                  icon: "remove",
                  hint: "Cancelar",
                  onClick: (e) => alert("Cancelar"),
                },
                {
                  hint: "Imprimir Relatório",
                  icon: "doc",
                  onClick: (e) => alert("Imprimir"),
                },
              ]}
            />
            <Column
              dataField={"serie"}
              visibleIndex={0}
              allowEditing={false}
              caption={"Série"}
              width={120}
            />
            <Column
              dataField={"numeroDocumento"}
              visibleIndex={0}
              allowEditing={false}
              caption={"Nº"}
              width={80}
            />
            <Column dataField={"servicoId"} caption={"Serviço"}>
              <Lookup
                dataSource={this.servicosDs}
                valueExpr={"id"}
                displayExpr={"descricao"}
              />
            </Column>
            <Column dataField={"prestadorNome"} caption={"Prestador"} />
            <Column
              dataField={"prestadorDocumentoFormatado"}
              caption={"Documento"}
            />
            <Column dataField={"situacaoEscrituracao"} caption={"Situação"}>
              <Lookup
                dataSource={[
                  { id: 0, descricao: "Aberta" },
                  { id: 1, descricao: "Fechada" },
                ]}
                valueExpr={"id"}
                displayExpr={"descricao"}
              />
            </Column>
            <Column
              dataField={"dataEmissao"}
              dataType={"date"}
              caption={"Data de Emissão"}
            />
            <Column
              dataField={"dataEscrituracao"}
              dataType={"date"}
              caption={"Data de Escrituração"}
            />
            <Column
              dataField={"dataServico"}
              dataType={"date"}
              caption={"Data do Serviço"}
            />
            <Column dataField={"bc"} caption={"BC (R$)"} />
            <Column dataField={"aliquota"} caption={"Alíquota (%)"} />
            <Column dataField={"valorISS"} caption={"ISS (R$)"} />
            <Column dataField={"issRetido"} caption={"Retido"} />
            <Column dataField={"valorISSAPagar"} caption={"ISS a Pagar (R$)"} />
          </DataGrid>
        </div>
      </div>
    );
  }
}
