import UserInfo from "./UserInfo";

export const isAuthenticated = () => UserInfo.estaAutenticado();
export const getToken = () => UserInfo.obterToken();
export const login = (token) => {
  UserInfo.setToken(token);
};
export const logout = () => {
  UserInfo.sair();
};
