export const mesOptions = {
  editorType: "dxSelectBox",
  searchEnabled: false,
  placeholder: " ",
  dataSource: [
    { id: 1, descricao: "JANEIRO" },
    { id: 2, descricao: "FEVEREIRO" },
    { id: 3, descricao: "MARÇO" },
    { id: 4, descricao: "ABRIL" },
    { id: 5, descricao: "MAIO" },
    { id: 6, descricao: "JUNHO" },
    { id: 7, descricao: "JULHO" },
    { id: 8, descricao: "AGOSTO" },
    { id: 9, descricao: "SETEMBRO" },
    { id: 10, descricao: "OUTUBRO" },
    { id: 11, descricao: "NOVEMBRO" },
    { id: 12, descricao: "DEZEMBRO" },
  ],
  valueExpr: "id",
  displayExpr: "descricao",
};

export const anoOptions = {
  items: [
    2024,
    2023,
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006,
    2005,
    2004,
    2003,
    2002,
    2001,
    2000,
    1999,
    1998,
    1997,
    1996,
    1995,
    1994,
    1993,
    1992,
    1991,
    1990,
  ],
  placeholder: " ",
};
