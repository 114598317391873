
import ambiente from "./ambiente";

let API_BASE_URL = ambiente.URL_API;

export default {
  API_URL: {
    PREFEITURAS: `${API_BASE_URL}/prefeituras`,
    JUROS: `${API_BASE_URL}/juros`,
    CONTADOR: `${API_BASE_URL}/contadores`,
    CPBS: `${API_BASE_URL}/cpbs`,
    MUNICIPIO: `${API_BASE_URL}/municipios`,
    MULTAS: `${API_BASE_URL}/multas`,
    CATEGORIAS_SERVICOS: `${API_BASE_URL}/categorias_aliquotas_iss`,
    CATEGORIA_CPBS: `${API_BASE_URL}/categoriascpbs`,
    SITUACOES_CADASTRAIS_CPBS: `${API_BASE_URL}/situacoes_cpbs`,
    REGIMES_CPBS: `${API_BASE_URL}/regimes_tributarios_cpbs`,
    TIPOS_RECOLHIMENTO_CPBS: `${API_BASE_URL}/tipos_recolhimento_cpbs`,
    GRAUS_INSTRUCAO_CPBS: `${API_BASE_URL}/graus_instrucao`,
    NATUREZAS_JURIDICAS_CPBS: `${API_BASE_URL}/naturezas_juridicas`,
    NATUREZAS_JURIDICAS_RECEITA: `${API_BASE_URL}/naturezas_juridicas/receita`,
    ATIVIDADES_ECONOMICAS: `${API_BASE_URL}/cnaes`,
    ALIQUOTA_ISS: `${API_BASE_URL}/aliquotasiss`,
    BAIRROS: `${API_BASE_URL}/bairros`,
    DISTRITOS: `${API_BASE_URL}/distritos`,
    FATORES_CORRETIVOS: `${API_BASE_URL}/fatorescorretivos`,
    ESCORES: `${API_BASE_URL}/escores`,
    ALIQUOTAS_IPTU: `${API_BASE_URL}/aliquotas_iptu`,
    LOGRADOUROS: `${API_BASE_URL}/logradouros`,
    LOTEAMENTOS: `${API_BASE_URL}/loteamentos`,
    COLABORADORES: `${API_BASE_URL}/colaboradores`,
    FERIADOS: `${API_BASE_URL}/feriados`,
    SERVICOSLCP: `${API_BASE_URL}/servicoslcp`,
    CATEGORIASLCP: `${API_BASE_URL}/categoriaslcp`,
    NATUREZA_OPERACAO: `${API_BASE_URL}/naturezaoperacao`,
    SERIE_NOTA_FISCAL: `${API_BASE_URL}/seriesnf`,
    RETENCAO_EXTERNA: `${API_BASE_URL}/retencoesexternas`,
    CADASTRAR_LEIS: `${API_BASE_URL}/leis`,
    DOCUMENTOS_RETENCAO: `${API_BASE_URL}/documentosretencoes`,
    AGENTES_RECEBEDORES: `${API_BASE_URL}/agentesrecebedores`,
    TEXTO_CDA: `${API_BASE_URL}/textoscda`,
    RECEITAS: `${API_BASE_URL}/receitas`,
    TIPOS_VEICULOS: `${API_BASE_URL}/tipos_veiculos`,
    SITUACAO_DIVIDA: `${API_BASE_URL}/situacoesdiat`,
    TIPO_NOTIFICACAO: `${API_BASE_URL}/tiposdenotificacoes`,
    UNIDADE_FISCAL: `${API_BASE_URL}/unidadesfiscais`,
    VEICULOS: `${API_BASE_URL}/veiculos`,
    CONTAS_BANCARIAS: `${API_BASE_URL}/contasbancarias`,
    PARAMETROS_REFIS: `${API_BASE_URL}/refis`,
    INDICE_PRECOS: `${API_BASE_URL}/indiceprecos`,
    SEGMENTOS: `${API_BASE_URL}/segmentos`,
    CONTRIBUINTE: `${API_BASE_URL}/contribuintes`,
    TAXAS: `${API_BASE_URL}/taxas`,
    CONFIGURACAO_CND: `${API_BASE_URL}/tipocertidao`,
    ISENCAO_IPTU: `${API_BASE_URL}/motivosisencaoiptu`,
    IMOVEL: `${API_BASE_URL}/imoveis/`,
    IMOVEL_FATORES: `${API_BASE_URL}/imoveis/inserirfatores`,
    IMOVEL_CALCULO: `${API_BASE_URL}/imoveis/opcoescalculo`,
    DIAT: `${API_BASE_URL}/diat`,
    NOTIFICACAO_DIAT: `${API_BASE_URL}/notificacaodiat`,
    ENTERGA_NOTIFICACAO: `${API_BASE_URL}/entreganotificaodiat`,
    CUBO_IMOVEIS: `${API_BASE_URL}/imoveis/cubo/`,
    ALVARA_VEICULOS: `${API_BASE_URL}/lancamentos/tlta/`,
    ALVARA_CONSTRUCAO: `${API_BASE_URL}/lancamentos/alvara_construcao/`,
    ALVARA_FUNCIONAMENTO_E_SANITARIO: `${API_BASE_URL}/lancamentos/funcionamento/`,
    DAMS: `${API_BASE_URL}/dam/`,
    LANCAMENTOS: `${API_BASE_URL}/lancamentos/`,
    TIPO_USO_LOGRADOURO_PUBLICO: `${API_BASE_URL}/tipos_uso_logradouro_publico`,
    PARAMETROS_IPTU: `${API_BASE_URL}/parametros_iptu`,
    LANCAMENTO_IPTU: `${API_BASE_URL}/lancamento_iptu`,
    LANCAMENTO_ITBI: `${API_BASE_URL}/lancamentos/itbi/`,
    ALVARAS: `${API_BASE_URL}/lancamentos/alvaras/`,
    LANCAMENTO_HORARIO_ESPECIAL: `${API_BASE_URL}/lancamentos/horario_especial/`,
    CNAE_PREFEITURA: `${API_BASE_URL}/cnaeprefeitura`,
    LCP116_PREFEITURA: `${API_BASE_URL}/lcp116prefeitura`,
    CATEGORIA_LCP116_PREFEITURA: `${API_BASE_URL}/categorialcpprefeitura`,
    TIPO_ALVARA_CONSTRUCAO: `${API_BASE_URL}/tiposalvaraconstrucao`,
    QUALIFICACAO_SOCIOS: `${API_BASE_URL}/QualificacaoSocios`,
    TIPO_ALVARA_CONSTRUCAO: `${API_BASE_URL}/tiposalvaraconstrucao`,
    FORMULAS_IPTU: `${API_BASE_URL}/formulasiptu`,
    PRECO_PUBLICO: `${API_BASE_URL}/precopublico`,
    PERMISSIONARIOS: `${API_BASE_URL}/permissionarios`,
    RESPONSAVEL_TECNICO: `${API_BASE_URL}/responsaveistecnicos`,
    LANCAMENTO_ALVARA_PUBLICIDADE: `${API_BASE_URL}/lancamentos/publicidade/`,
    CND: `${API_BASE_URL}/cnds/`,
    CND_AUTOMATICA: `${API_BASE_URL}/cnds/cnd_automatica`,
    LANCAMENTO_TAXAS: `${API_BASE_URL}/lancamentos/taxas/`,
    LANCAMENTO_USO_LOGRADOURO_PUBLICO: `${API_BASE_URL}/lancamentos/logradouro_publico/`,
    TIPOS_ALVARAS_DIVERSOS: `${API_BASE_URL}/tipos_alvaras_diversos`,
    LANCAMENTO_ALVARA_DIVERSO: `${API_BASE_URL}/lancamentos/alvara_diverso/`,
    VALOR_EDIFICADO: `${API_BASE_URL}/valoredificado`,
    ORIGENS: `${API_BASE_URL}/diat/origens/`,
    HABITESE: `${API_BASE_URL}/lancamentos/habitese/`,
    PROCESSAMENTOS: `${API_BASE_URL}/processamentos/`,
    NOTA_FISCAL_AVULSA: `${API_BASE_URL}/nota_fiscal_avulsa/`,
    PAISES_BACEN: `${API_BASE_URL}/paises_bacen/`,
    SERVICOS_ISS: `${API_BASE_URL}/servicos/`,
    IMPRESSAO_NOTIFICACAO: `${API_BASE_URL}/conferencia_acordos/imprimir_notificacoes/`,
    CDA: `${API_BASE_URL}/cda/`,
    ENTREGADORES: `${API_BASE_URL}/colaboradores/entregadores/`,
    USUARIOS: `${API_BASE_URL}/usuarios/`,
  }
}
