import React, { useMemo } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { logout } from '../../auth.js'
import UserInfo from "../../UserInfo.js";
import { useHistory } from "react-router-dom";


import './user-panel.scss';

export default function ({ menuMode }) {


  const history = useHistory();
  const prefeitura = localStorage.getItem("codigoPortal");


  const menuItems = useMemo(() => ([
    {
      text: 'Perfil',
      icon: 'user'
    },
    {
      text: 'Logout',
      icon: 'runner',
      onClick: () => {
        logout();
        history.push(`${prefeitura}/login`);
        window.location.reload();

      }
    }
  ]), []);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'user-name'}>{UserInfo.nomeUsuarioLogado()}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
}
