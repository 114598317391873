import axios from "axios";
import { getToken } from "../auth";
import ambiente from "../ambiente";

const api = axios.create({
  baseURL: ambiente.URL_API
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers= {
      "Authorization" : `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*"
    };
  }
  return config;
});

export default api;
