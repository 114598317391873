import "./dx-styles.scss";


const prefeitura = localStorage.getItem("codigoPortal");
//const prefeitura = "saobenedito.ce";

export const navigation = [
  {
    text: "Home",
    path: `/${prefeitura}/home`,
    icon: "home",
  },

  {
    text: "Emitir de NFSe",
    path: `/${prefeitura}/emitir-nota`,
    icon: "file",
  },
  {
    text: "Pesquisar NFSe",
    path: `/${prefeitura}/pesquisar-nota`,
    icon: "search",
  },
  {
    text: "Cadastrar",
    path: `/${prefeitura}/cadastrar`,
    icon: "plus",
    visible: false
  },
  {
    text: "Escrituração",
    visible: true,
    icon: "folder",
    items: [
      {
        text: "Consultar Escrituração",
        path: `/${prefeitura}/consultar-escrituracao`,
        icon: "search",
      },
      {
        text: "Escriturar Notas",
        path: `/${prefeitura}/prestador-servico/encerrar-com-movimento`,
        icon: "chevronnext"
      },
      {
        text: "Tomador de Serviços",
        visible: false,
        icon: "folder",
        items: [
          {
            text: "Consultar",
            path: `/${prefeitura}/servicos-tomados`,
            icon: "search"
          },
          {
            text: "Encerrar Com Movimento",
            path: `/${prefeitura}/servicos-tomados/encerrar-com-movimento`,
            icon: "chevronnext"
          },
          {
            text: "Encerrar Sem Movimento",
            path: `/${prefeitura}/servicos-tomados/encerrar-sem-movimento`,
            icon: "chevronnext"
          },
        ]
      },
    ],
  },
  {
    text: "Plano de Contas",
    path: `/${prefeitura}/plano-contas`,
    icon: "folder",
    visible: false
  },
  {
    text: "Escrituração de Contas",
    path: `/${prefeitura}/dados-escrituracao`,
    icon: "folder",
    visible: false
  }
];
