import React from "react";
import Toolbar from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Form, {
    SimpleItem,
    GroupItem as GrupoForm,
} from "devextreme-react/form";
import { Label } from "devextreme-react/data-grid";


export default class PaginaToolbar extends React.Component {

    constructor(props) {
        super(props);

        this.refFrmXlsx = React.createRef();

        this.state = {
            titulo: props.titulo,
            exibirFilterPanel: false,
            popupExportarXlsxVisivel: false
        };

        this.exportarXlsx = this.exportarXlsx.bind(this);
        this.confirmarExportarXlsx = this.confirmarExportarXlsx.bind(this);
    }

    onInitialized(e) {
        this.grid = e.component;
    }

    exportarXlsx() {
        this.setState({ popupExportarXlsxVisivel: true, arquivoXlsx: "" });
    }

    confirmarExportarXlsx() {
        this.grid.option("export", { enabled: false, fileName: this.state.arquivoXlsx });
        this.setState({ popupExportarXlsxVisivel: false });
        this.grid.exportToExcel();
    }


    render() {

        var gridChild = React.Children.only(this.props.children);

        let atualizarCb = this.props.atualizarCb;
        let novoCb = this.props.novoCb;

        if (atualizarCb === undefined && gridChild !== undefined) {
            atualizarCb = () => {
                this.grid.refresh();
            }
        }

        if (novoCb === undefined && gridChild !== undefined && !this.props.ocultarNovo) {
            novoCb = () => {
                this.grid.addRow();
            }
        }

        let items = [];

        items.push({
            location: 'left',
            className: "top-form-titulo",
            locateInMenu: 'never',
            template: () => {
                return `<div class=\'frm-titulo\'>${this.state.titulo}</div>`;
            }
        });

        if (atualizarCb !== undefined) {
            items.push({
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                    icon: 'refresh',
                    onClick: atualizarCb
                }
            });
        }

        if (novoCb !== undefined && !this.props.ocultarNovo) {
            items.push({
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'auto',
                options: {
                    icon: "plus",
                    onClick: novoCb
                }
            });
        }

        if (gridChild !== undefined && !this.props.ocultarExibicaoFiltro) {
            items.push({
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: "filter",
                    text: this.state.exibirFilterPanel
                        ? "Ocultar filtro avançado"
                        : "Mostrar filtro avançado",
                    onClick: function () {
                        let valorCorrente = this.grid.option("filterPanel.visible");
                        let novoValor = !valorCorrente;
                        this.grid.option({
                            filterPanel: {
                                visible: novoValor,
                                enabled: novoValor
                            }
                        });
                        this.setState({ exibirFilterPanel: novoValor });
                    }.bind(this)
                }
            });
        }

        if (gridChild !== undefined && !this.props.ocultarEscolhaColunas) {
            items.push({
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: "columnchooser",
                    text: "Colunas",
                    onClick: function () {
                        this.grid.option("columnChooser", { mode: "select" });
                        this.grid.showColumnChooser()
                    }.bind(this)
                }
            });
        }

        if (gridChild !== undefined && !this.props.ocultarExportacao) {
            items.push({
                location: 'after',
                widget: 'dxButton',
                locateInMenu: 'always',
                options: {
                    icon: "exportxlsx",
                    text: "Exportar (Excel)",
                    onClick: this.exportarXlsx
                }
            });
        }

        var clone = React.cloneElement(gridChild, { onInitialized: this.onInitialized.bind(this) })

        return (
            <React.Fragment>
                <Toolbar items={items} />
                <Popup
                    visible={this.state.popupExportarXlsxVisivel}
                    title="Nome do arquivo"
                    width={450}
                    height={200}
                    onHiding={(e) => this.setState({ popupExportarXlsxVisivel: false })}
                    onShown={(e) => this.refFrmXlsx.current.instance.getEditor("arquivoXlsx").focus()}>
                    <Form
                        id="frmPesquisa"
                        formData={this.state}
                        ref={this.refFrmXlsx}
                    >
                        <SimpleItem
                            dataField="arquivoXlsx"
                        >
                            <Label visible={false} />
                        </SimpleItem>
                    </Form>
                    <ToolbarItem
                        toolbar={"bottom"}
                        widget="dxButton"
                        location="after"
                        options={{
                            text: "Fechar",
                            width: 150,
                            onClick: () => this.setState({ popupExportarXlsxVisivel: false }),
                        }}
                    />
                    <ToolbarItem
                        toolbar={"bottom"}
                        widget="dxButton"
                        location="after"
                        options={{
                            text: "Salvar",
                            type: "success",
                            width: 150,
                            onClick: () => this.confirmarExportarXlsx(),
                        }}
                    />
                </Popup>
                {clone}
            </React.Fragment>

        );
    }
}