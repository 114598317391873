import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./encerrar-sem-movimento.scss";
import api from "../../service/api";
import Form, {
  SimpleItem,
  GroupItem,
  Label,
  ButtonItem,
} from "devextreme-react/form";
import { ScrollView } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { mesOptions, anoOptions } from "../../utils/date-utils";
import UserInfo from "../../UserInfo";
import { Popup, ToolbarItem } from "devextreme-react/popup";

export default () => {
  const location = useLocation();
  const [encerramento, setEncerramento] = useState({});
  const [modalidadeEMovimento] = useState(setDados());
  const [showPopup, setShowPopup] = useState(false);
  const [preview, setPreview] = useState({});
  const cpbsId = UserInfo.getCpbsId();
  const prefeitura = UserInfo.getPrefeitura();

  function setDados() {
    const splitedPathname = location.pathname.split("/");
    if (splitedPathname.length === 3) {
      let nomeTela = "";
      if (splitedPathname[1] === "servicos-tomados") {
        nomeTela = "Tomador";
      } else {
        nomeTela = "Prestador";
      }
      return {
        modalidade: splitedPathname[1],
        movimento: splitedPathname[2],
        tela: nomeTela,
      };
    }
    if (splitedPathname.length === 4) {
      let nomeTela = "";
      if (splitedPathname[2] === "servicos-tomados") {
        nomeTela = "Tomador";
      } else {
        nomeTela = "Prestador";
      }
      return {
        modalidade: splitedPathname[2],
        movimento: splitedPathname[3],
        tela: nomeTela,
      };
    }
  }

  async function showPreview() {
    if (
      modalidadeEMovimento.modalidade === "servicos-tomados" &&
      modalidadeEMovimento.movimento === "encerrar-sem-movimento" &&
      encerramento.mesCompetencia !== undefined &&
      encerramento.anoCompetencia !== undefined
    ) {
      const request = {
        mes: encerramento.mesCompetencia,
        ano: encerramento.anoCompetencia,
        cpbsId: cpbsId,
        PrefeituraId: prefeitura,
        Modalidade: 1,
        SemMovimento: true,
      };

      try {
        const response = await api.post(
          "/escrituracao/encerramento_preview",
          request
        );
        if (response.status === 200) {
          setPreview(response.data);
          setShowPopup(true);
        }
      } catch (err) {
        console.log(err);
        notify(err, "error", 2000);
      }
    }
    if (
      modalidadeEMovimento.modalidade === "prestador-servico" &&
      modalidadeEMovimento.movimento === "encerrar-sem-movimento" &&
      encerramento.mesCompetencia !== undefined &&
      encerramento.anoCompetencia !== undefined
    ) {
      const request = {
        mes: encerramento.mesCompetencia,
        ano: encerramento.anoCompetencia,
        cpbsId: cpbsId,
        PrefeituraId: prefeitura,
        Modalidade: 0,
        SemMovimento: true,
      };

      try {
        const response = await api.post(
          "/escrituracao/encerramento_preview",
          request
        );
        if (response.status === 200) {
          setPreview(response.data);
          setShowPopup(true);
        }
      } catch (err) {
        console.log(err);
        notify(err, "error", 2000);
      }
    }
  }

  async function confirmPopup() {
    try {
      const response = await api.post(
        "/escrituracao/encerramento_confirmacao",
        preview
      );
      if (response.status === 200) {
        notify("Escrituração encerrada com sucesso!", "success", 2000);
        setEncerramento({});
        setShowPopup(false);
      } else {
        notify("Não foi possível encerrar a escrituração!", "error", 2000);
      }
    } catch (err) {
      console.log(err);
      notify(err, "error", 2000);
    }
  }

  const buttonOptions = {
    text: "Encerrar",
    type: "default",
    onClick: showPreview,
  };

  return (
    <div className="encerrar-escrituracao-container">
      <div className={"content-block"}>
        <h2>
          Encerrar Escrituação do {modalidadeEMovimento.tela} Sem Movimento
        </h2>
      </div>

      <div className={"content-block dx-card responsive-paddings"}>
        <Form
          id="encerrar-form"
          formData={encerramento}
          colCount={3}
          labelLocation="top"
        >
          <GroupItem colCount={3} colSpan={3}>
            <SimpleItem
              dataField="mesCompetencia"
              editorType="dxSelectBox"
              editorOptions={mesOptions}
            >
              <Label text="Mês Competência" />
            </SimpleItem>
            <SimpleItem
              dataField="anoCompetencia"
              editorType="dxSelectBox"
              editorOptions={anoOptions}
            >
              <Label text="Ano Competência" />
            </SimpleItem>
            <ButtonItem
              horizontalAlignment="left"
              verticalAlignment="bottom"
              buttonOptions={buttonOptions}
            />
          </GroupItem>
        </Form>
        <Popup
          visible={showPopup}
          onHiding={() => setShowPopup(false)}
          dragEnabled={false}
          closeOnOutsideClick={false}
          showCloseButton={false}
          showTitle={true}
          width={"60%"}
          height={"40%"}
          title="Confirmação dos dados"
        >
          <ScrollView showScrollbar={"onScroll"}>
            <form>
              <Form
                formData={preview}
                colCount={2}
                labelLocation={"top"}
                readOnly={true}
              ></Form>
            </form>
          </ScrollView>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Confirmar",
              type: "default",
              width: 150,
              onClick: () => confirmPopup(),
            }}
          ></ToolbarItem>
          <ToolbarItem
            toolbar={"bottom"}
            widget="dxButton"
            location="center"
            options={{
              text: "Cancelar",
              type: "danger",
              width: 150,
              onClick: () => setShowPopup(false),
            }}
          ></ToolbarItem>
        </Popup>
        {/* <div className="botao">
                    <Button className="encerrar-button"
                        text="Encerrar"
                        type="default"
                        onClick={encerrar} />
                </div> */}
      </div>
    </div>
  );
};
